<template>
  <div class="message is-success">
    <div class="message-body">
      Enjoy your
      <b>FREE SHIPPING</b> during 6:00p.m - 7:00p.m
      <br />Orders placed before 4:00p.m will be deliver on the same day.
      <!-- Enjoy your <b>FREE SHIPPING</b> during 1:00p.m - 2:00p.m and 6:00p.m -
      7:00p.m-->
    </div>
  </div>
</template>
