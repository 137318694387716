<template>
  <div class="modal" :class="{ 'is-active': show }">
    <div class="modal-background"></div>

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Enquiry</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <p>If you have any questions / issues, please contact us at:</p>
        <p>
          <strong>Email: </strong>
          <a :href="'mailto:' + SUPPORT_EMAIL">{{ SUPPORT_EMAIL }}</a>
        </p>
        <p>
          <strong>Telephone: </strong>
          <a :href="'tel:' + SUPPORT_CONTACT">{{ SUPPORT_CONTACT }}</a>
        </p>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button class="button" @click="close">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { toRefs, defineProps, defineEmits } from "vue";

import { SUPPORT_EMAIL, SUPPORT_CONTACT } from "@/utils";

const props = defineProps({
  show: { type: Boolean, default: false },
});
const { show } = toRefs(props);
const emits = defineEmits(["close"]);
const close = () => {
  emits("close");
};
</script>
