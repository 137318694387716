<template>
  <div class="page-my-account">
    <section class="hero is-small is-info">
      <div class="hero-body has-text-centered">
        <div class="column is-12 has-text-centered">
          <img
            class="profile-picture"
            src="../../images/profile_white.png"
            alt="profile-picture"
          />
          <div class="column header-text is-4 is-offset-4">
            <p class="title has-text-centered has-text-white">My Account</p>
            <p class="subtitle has-text-centered has-text-white">
              Username: {{ username }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section" id="section-address">
      <div class="columns is-multiline">
        <div class="column is-12 is-flex is-justify-content-space-between">
          <h1 class="title is-size-4">My Addresses</h1>
          <router-link
            :to="{ name: 'AddClient' }"
            class="button is-outlined is-info"
          >
            <span class="icon-text">
              <span class="icon">
                <i class="fa fa-plus"></i>
              </span>
              <span>Add a new address</span>
            </span>
          </router-link>
        </div>

        <div
          class="column is-6-tablet is-4-desktop"
          v-for="client in clients"
          v-bind:key="client.id"
        >
          <div
            class="box is-flex is-flex-direction-column"
            style="height: 100%"
          >
            <strong class="is-size-5">{{ client.name }}</strong>
            <hr />
            <div class="columns is-flex-grow-1">
              <div class="column is-12-mobile is-6-tablet">
                <p class="long-text">
                  <strong>Contact Number:</strong><br />
                  {{ client.country_code }}{{ client.contact_num }}
                </p>
                <p class="long-text" :title="client.email">
                  <strong>Email:</strong><br />
                  {{ client.email }}
                </p>
              </div>
              <p class="mb-4 column is-12-mobile is-6-tablet">
                <strong>Address:</strong><br />
                {{ addTrailingComma(client.address1) }} <br />
                {{ addTrailingComma(client.address2) }}
                <br v-if="client.address2" />
                {{ client.zipcode }}
                {{ addTrailingComma(client.city) }} <br />
                {{ addTrailingComma(client.state) }}
                {{ client.country }}
              </p>
            </div>
            <router-link
              :to="{ name: 'Client', params: { id: client.id } }"
              class="button is-outlined is-info"
            >
              Details
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- <hr />

    <section class="section" id="section-invoice">
      <div class="column is-12">
        <h1 class="title is-size-4">My Invoices</h1>
      </div>

      <div class="column is-12">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Amount</th>
              <th>Is paid</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice in invoices" :key="invoice.id">
              <td>{{ invoice.invoice_number }}</td>
              <td>{{ invoice.client_name }}</td>
              <td>{{ invoice.gross_amount }}</td>
              <td>{{ invoice.is_paid }}</td>
              <td>
                <router-link
                  :to="{ name: 'Invoice', params: { id: invoice.id } }"
                >
                  Details
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section> -->
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

import { addTrailingComma, api_call } from "@/utils";

const store = useStore();

const clients = ref([]);
const username = computed(() => {
  const user = store.getters.user;
  return user && user.username;
});

onMounted(() => {
  api_call("/api/v1/clients", {
    success(res) {
      clients.value = res.data.results;
    },
  });
});

// getInvoices() {
//   axios
//     .get("/api/v1/invoices/")
//     .then((response) => {
//       for (let i = 0; i < response.data.length; i++) {
//         this.invoices.push(response.data[i]);
//       }
//     })
//     .catch((error) => {
//       console.log(JSON.stringify(error));
//     });
// },
</script>

<style lang="sass" scoped>
.long-text
  text-overflow: ellipsis
  overflow: hidden
</style>
