<template>
  <div class="container">
    <section class="section">
      <BackButton />
      <p class="title has-text-info has-text-centered is-size-3">
        Order Details
      </p>
      <order-status-summary :status="cart.status" class="mx-auto" />
    </section>

    <section class="section">
      <order-summary :cart="cart" />
    </section>

    <!-- <section class="section">
      <p class="has-text-link is-size-4">Customer Remark:</p>
      <div class="control">
        <textarea class="textarea" readonly :value="cart.remark"></textarea>
      </div>
    </section> -->

    <section class="section has-text-centered">
      <div class="buttons is-justify-content-center mb-0">
        <button
          class="button is-info"
          @click="acceptOrder"
          v-if="!cart.delivery"
        >
          <strong>Accept</strong>
        </button>
        <button
          class="button is-info"
          @click="showModal = true"
          v-if="cart.status === 'DE'"
        >
          <strong>Mark as Received</strong>
        </button>
      </div>
      <p class="help pt-0" v-if="cart.status === 'DE'">
        Pending Payment Amount:
        {{ formatMoney(pendingPayment) }}
      </p>
    </section>
  </div>

  <div class="modal is-active" v-if="showModal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Client Received Confirmation</p>
        <button
          class="delete"
          aria-label="close"
          @click="showModal = false"
        ></button>
      </header>
      <section class="modal-card-body p-5">
        <div class="columns is-multiline">
          <!-- <label class="column is-3 is-size-5 has-text-right">Photo:</label>
          <div class="column is-9">
            <div class="file is-info has-name is-fullwidth">
              <label class="file-label">
                <input
                  class="file-input"
                  type="file"
                  accept="image/*"
                  @change="photoSelected"
                />
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label"> Choose a file... </span>
                </span>
                <span class="file-name">
                  {{ photo ? photo.name : "example_client_photo.png ..." }}
                </span>
              </label>
            </div>
            <figure class="image mt-3">
              <img
                :src="previewImg"
                height="300px"
                :alt="photo.name"
                v-if="photo"
              />
            </figure>
          </div> -->
          <label class="column is-3 is-size-5 has-text-right">
            Paid Amount:
          </label>
          <input
            type="text"
            class="column is-9 is-size-5 input is-static"
            readonly
            :value="formatMoney(pendingPayment)"
          />
          <label class="column is-3 is-size-5 has-text-right">
            Received By:
          </label>
          <input
            type="text"
            class="column is-9 is-size-5 input"
            v-model="receivedBy"
          />
          <label class="column is-full checkbox">
            <input type="checkbox" v-model="acknowledgement" />
            I acknowledge that the customer has received the items and paid the
            remaining amount.
          </label>
          <div class="column is-full">
            <p class="has-text-danger" v-for="error in errors" :key="error">
              {{ error }}
            </p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-link is-fullwidth" @click="clientReceived">
          Confirm
        </button>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import BackButton from "@/components/BackButton";
import OrderStatusSummary from "@/components/OrderStatusSummary";
import OrderSummary from "@/components/OrderSummary";
import { api_call, getPendingPayment, formatMoney } from "@/utils";

const store = useStore();
const route = useRoute();
const router = useRouter();

const cart = ref({});
const getCartDetail = (id) => {
  api_call(`/api/v1/carts/${id}/`, {
    success: (res) => {
      cart.value = res.data;
    },
  });
};
onMounted(() => {
  getCartDetail(route.params.id);
});
watch(
  () => route.params.id,
  (newId) => {
    newId && getCartDetail(newId);
  }
);

const pendingPayment = computed(() => {
  return getPendingPayment(cart.value);
});

const acceptOrder = () => {
  api_call(`/api/v1/carts/${cart.value.id}/rider_accept/`, {
    method: "post",
    success: () => {
      router.push({ name: "DeliverySelectedOrders" });
    },
  });
};

const showModal = ref(false);
// const photo = ref(null);
// const previewImg = ref("");
const acknowledgement = ref(false);
const receivedBy = ref("");
const errors = ref([]);
// const photoSelected = (e) => {
//   errors.value = [];
//   const file = e.target.files[0];
//   if (!file || file.type.indexOf("image") === -1) {
//     errors.value.push("Please select an image file");
//     return;
//   }
//   photo.value = file;
//   previewImg.value = URL.createObjectURL(file);
// };
const clientReceived = () => {
  errors.value = [];
  // if (!photo.value) {
  //   errors.value.push("Please select a photo");
  // }
  if (!acknowledgement.value) {
    errors.value.push("Please tick the acknowledgement");
  }
  if (!receivedBy.value) {
    errors.value.push("Please enter the received by");
  }
  if (errors.value.length) {
    return;
  }
  const formData = new FormData();
  // formData.append("photo", photo.value);
  formData.append("acknowledgement", acknowledgement.value);
  formData.append("amount", pendingPayment.value);
  formData.append("receivedBy", receivedBy.value);
  const headers = { "Content-Type": "undefined" };

  api_call(`/api/v1/carts/${cart.value.id}/client_received/`, {
    method: "post",
    headers,
    data: formData,
    success: (res) => {
      cart.value = res.data;
      showModal.value = false;
      store.dispatch("flashMessage", {
        type: "is-success",
        content: "Client received successfully",
      });
    },
    failed: () => {
      showModal.value = false;
    },
  });
};
</script>
