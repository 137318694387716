<template>
  <section class="section">
    <div class="box">
      <div class="columns is-multiline">
        <div class="column is-full is-flex is-align-items-baseline">
          <BackButton />
          <p class="title has-text-link is-size-3">Order ID # {{ cart.id }}</p>
        </div>
        <div class="column is-2">Merchant Name:</div>
        <div class="column is-10">
          <MerchantDetailPopup :merchant="merchant" />
        </div>
        <div class="column is-2">Merchant Address:</div>
        <div class="column is-10">
          {{ addTrailingComma(merchant.address1) }}
          {{ addTrailingComma(merchant.address2) }}
          {{ merchant.zipcode }}
          {{ addTrailingComma(merchant.city) }}
          {{ addTrailingComma(merchant.state) }}
          {{ merchant.country }}
        </div>
        <div class="column is-2">Receiver Name:</div>
        <div class="column is-10">
          <ClientDetailPopup :client="client" />
        </div>
        <div class="column is-2">Receiver Address:</div>
        <div class="column is-10">
          {{ addTrailingComma(client.address1) }}
          {{ addTrailingComma(client.address2) }}
          {{ client.zipcode }}
          {{ addTrailingComma(client.city) }}
          {{ addTrailingComma(client.state) }}
          {{ client.country }}
        </div>
        <div class="column is-2">Order Time:</div>
        <div class="column is-10">{{ cart.createdAt }}</div>
        <div class="column is-2">Delivery Time:</div>
        <div class="column is-10">{{ cart.deliveryTime }}</div>
        <div class="column is-2">Items:</div>
        <div class="column is-10">
          <div
            class="columns is-multiline"
            v-for="file in items"
            :key="file.id"
          >
            <div class="column is-full">{{ file.filename }}</div>
          </div>
        </div>

        <div class="column is-2">
          <p>Total Amount:</p>
        </div>
        <div class="column is-10" v-if="cart.isReceived">
          {{ formatMoney(cart.totalPrice) }}
        </div>
        <div class="column is-10" v-else>Pending</div>
        <div class="column is-2">
          <p>Pending Payment Amount:</p>
        </div>
        <div class="column is-10" v-if="cart.isReceived">
          {{ formatMoney(pendingPayment) }}
        </div>
      </div>
      <div
        class="columns is-multiline is-dekstop is-tablet is-mobile is-centered"
      >
        <div class="column is-3-desktop is-half-tablet is-full-mobile">
          <button
            class="button is-info is-fullwidth"
            v-if="cart.isDelivered"
            disabled
          >
            <strong>Delivered</strong>
          </button>
          <button
            class="button is-info is-fullwidth"
            @click="showModal = true"
            v-else-if="cart.isDelivering"
          >
            <strong>Delivered</strong>
          </button>
          <button
            class="button is-info is-fullwidth"
            @click="delivering"
            v-else
          >
            <strong>Deliver now</strong>
          </button>
        </div>
      </div>
      <div class="modal is-active" v-if="showModal">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Confirm Order</p>
            <button
              class="delete"
              aria-label="close"
              @click="showModal = false"
            ></button>
          </header>
          <section class="modal-card-body p-5">
            <div class="columns is-multiline">
              <label class="column is-4 is-size-5">Receive By :</label>
              <input
                type="text"
                class="column is-6 input"
                v-model="recipient"
              />
              <div class="column is-12" v-if="pendingPayment > 0">
                <div class="columns is-multiline">
                  <label class="column is-4 is-size-5">Payment Amount :</label>
                  <p class="column is-6 is-size-5">
                    {{ formatMoney(pendingPayment) }}
                  </p>
                  <!-- <input
                    type="number"
                    step="0.05"
                    class="column is-6 input"
                    v-model="paymentAmount"
                  /> -->
                  <!-- <p class="column pt-0 mt-0 is-10 help has-text-right">
                    Pending Amount: {{ formatMoney(pendingPayment) }}
                  </p> -->
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-link is-fullwidth" @click="delivered">
              Update Order
            </button>
          </footer>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";

import BackButton from "@/components/BackButton";
import MerchantDetailPopup from "@/components/MerchantDetailPopup";
import ClientDetailPopup from "@/components/ClientDetailPopup";
import {
  addTrailingComma,
  api_call,
  formatMoney,
  getPendingPayment,
} from "@/utils";

const route = useRoute();
const router = useRouter();

const orderIndex = ref(0);
const cart = ref({});
const merchant = ref({});
const client = ref({});
const items = ref([]);
const delivery = ref({});
onMounted(() => {
  orderIndex.value = route.params.id;

  api_call(`/api/v1/carts/${orderIndex.value}/`, {
    success: (res) => {
      cart.value = res.data;
      merchant.value = res.data.merchant;
      client.value = res.data.client;
      items.value = res.data.files;
    },
  });
  api_call(`/api/v1/delivery/me/`, {
    success: (res) => {
      delivery.value = res.data;
    },
  });
});

const pendingPayment = computed(() => {
  return getPendingPayment(cart.value);
});

const showModal = ref(false);
const delivering = () => {
  const orderID = cart.value.id;
  let cartData = new FormData();
  cartData.append("id", orderID);
  cartData.append("merchant", merchant.value.id);
  cartData.append("delivery", delivery.value.id);
  cartData.append("isPicked", cart.value.isPicked);
  cartData.append("isDone", cart.value.isDone);
  cartData.append("isDelivering", true);
  cartData.append("isDelivered", cart.value.isDelivered);
  cartData.append("clientReceived", cart.value.clientReceived);

  api_call(`/api/v1/carts/${orderID}/`, {
    data: cartData,
    method: "patch",
    success: () => {
      router.push({ name: "DeliverySelectedOrders" });
    },
  });
};

const recipient = ref("");
const delivered = async () => {
  const orderID = cart.value.id;
  let cartData = new FormData();
  cartData.append("id", orderID);
  cartData.append("isDone", cart.value.isDone);
  cartData.append("merchant", merchant.value.id);
  cartData.append("delivery", delivery.value.id);
  cartData.append("isPicked", cart.value.isPicked);
  cartData.append("isDelivering", cart.value.isDelivering);
  cartData.append("isDelivered", true);
  cartData.append("received_by", recipient.value);
  cartData.append("clientReceived", cart.value.clientReceived);

  await api_call(`/api/v1/carts/${orderID}/`, {
    data: cartData,
    method: "patch",
  });
  api_call(`/api/v1/carts/${orderID}/make_payment/`, {
    method: "post",
    data: { paymentMethod: "COD" },
    success: () => {
      router.push({ name: "DeliveryOrderHistory" });
    },
  });
};
</script>
