<template>
  <div class="dropdown" :class="{ 'is-active': show }">
    <div class="dropdown-trigger">
      <p
        class="has-text-link is-clickable"
        @click="trigger"
        aria-haspopup="true"
        aria-controls="dropdown-menu2"
      >
        <span>{{ wallet.id }}</span>
        <span class="icon is-small ml-2">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="dropdown-menu" id="dropdown-menu2" role="menu">
      <div class="dropdown-content" :class="{ 'position-fixed': !relative }">
        <div class="dropdown-item" v-if="wallet.user">
          <p class="is-size-5">User</p>
          <p><strong>Username: </strong>{{ wallet.user.username }}</p>
          <p><strong>Email: </strong>{{ wallet.user.email }}</p>
        </div>
        <hr class="dropdown-divider" v-if="wallet.merchant" />
        <div class="dropdown-item" v-if="wallet.merchant">
          <p class="is-size-5">Merchant</p>
          <p><strong>Name:</strong>{{ wallet.merchant.name }}</p>
          <p>
            <strong>Contact Number: </strong>{{ wallet.merchant.contact_num }}
          </p>
          <p><strong>Email: </strong>{{ wallet.merchant.email }}</p>
        </div>
        <hr class="dropdown-divider" />
        <div class="dropdown-item">
          <p>
            <strong>Balance: </strong>
          </p>
          <p class="has-text-link is-size-3">
            <span class="is-size-3">{{ formatMoney(wallet.balance) }}</span>
          </p>
          <!-- <p>
            <strong>Freezed: </strong>
            <span
              :class="{
                'has-text-success': !wallet.freezed,
                'has-text-danger': wallet.freezed,
              }"
              >{{ wallet.freezed }}</span
            >
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref } from "vue";

import { formatMoney } from "@/utils";

const show = ref(false);
const props = defineProps({
  wallet: {
    type: Object,
    required: true,
  },
  relative: {
    type: Boolean,
    default: false,
  },
});
const { wallet, relative } = toRefs(props);

const trigger = () => {
  show.value = !show.value;
};
</script>
