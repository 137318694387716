<template>
  <div class="">
    <section class="section pb-0">
      <div class="level">
        <div class="level-right">
          <div class="level-item">
            <back-button />
            <p class="title is-size-4 has-text-info">Transactions</p>
          </div>
        </div>
      </div>

      <div class="is-flex is-flex-wrap-wrap">
        <div class="field mr-5">
          <label for="transactionType" class="label">Transaction Type</label>
          <div class="control">
            <div class="select is-small is-expanded">
              <select name="transactionType" v-model="filters.transactionType">
                <option value="">All</option>
                <option
                  v-for="key in Object.keys(TRANSACTION_TYPE)"
                  :value="key"
                  :key="key"
                >
                  {{ TRANSACTION_TYPE[key] }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="field mr-5">
          <label for="createdAfter" class="label">Transaction Date After</label>
          <div class="control">
            <input
              type="date"
              name="createdAfter"
              v-model="filters.createdAfter"
              class="input is-small"
            />
          </div>
        </div>

        <div class="field mr-5">
          <label for="createdBefore" class="label"
            >Transaction Date Before</label
          >
          <div class="control">
            <input
              type="date"
              name="createdBefore"
              v-model="filters.createdBefore"
              class="input is-small"
            />
          </div>
        </div>

        <div class="field mr-5">
          <label for="fromAccount" class="label">From Account</label>
          <div class="control">
            <input
              type="text"
              name="fromAccount"
              v-model="filters.fromAccount"
              class="input is-small"
              placeholder="Merchant"
            />
          </div>
        </div>

        <div class="field mr-5">
          <label for="toAccount" class="label">To Account</label>
          <div class="control">
            <input
              type="text"
              name="toAccount"
              v-model="filters.toAccount"
              class="input is-small"
              placeholder="Delivery"
            />
          </div>
        </div>

        <div class="field mr-5">
          <label for="amount_gte" class="label">Amount Greater Than</label>
          <div class="control">
            <input
              type="number"
              name="amount_gte"
              v-model="filters.amount_gte"
              class="input is-small"
              placeholder="Client"
            />
          </div>
        </div>

        <div class="field mr-5">
          <label for="amount_lte" class="label">Amount Less Than</label>
          <div class="control">
            <input
              type="number"
              name="amount_lte"
              v-model="filters.amount_lte"
              class="input is-small"
              placeholder="Client"
            />
          </div>
        </div>

        <div class="field ml-auto mr-5 is-align-self-center">
          <div class="control">
            <button class="button is-link" @click="searchTransactions()">
              Search
            </button>
          </div>
        </div>
      </div>

      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in errors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </section>

    <section class="section">
      <div class="table-container">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>
                <TableHeaderOrder
                  @on-click="orderBy('id')"
                  label="Transaction ID"
                  field="id"
                  :currOrdering="filters.ordering"
                  :ascending="ascending"
                />
              </th>
              <th>
                <TableHeaderOrder
                  @on-click="orderBy('fromAccount')"
                  label="From Account"
                  field="fromAccount"
                  :currOrdering="filters.ordering"
                  :ascending="ascending"
                />
              </th>
              <th>
                <TableHeaderOrder
                  @on-click="orderBy('toAccount')"
                  label="To Account"
                  field="toAccount"
                  :currOrdering="filters.ordering"
                  :ascending="ascending"
                />
              </th>
              <th>
                <TableHeaderOrder
                  @on-click="orderBy('transactionType')"
                  label="Transaction Type"
                  field="transactionType"
                  :currOrdering="filters.ordering"
                  :ascending="ascending"
                />
              </th>
              <th>Details</th>
              <th>
                <TableHeaderOrder
                  @on-click="orderBy('amount')"
                  label="Amount"
                  field="amount"
                  :currOrdering="filters.ordering"
                  :ascending="ascending"
                />
              </th>
              <th>
                <TableHeaderOrder
                  @on-click="orderBy('createdAt')"
                  label="Transaction Time"
                  field="createdAt"
                  :currOrdering="filters.ordering"
                  :ascending="ascending"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="txn in transactions" :key="txn.id">
              <td>{{ txn.id }}</td>
              <td>
                <e-wallet-detail-popup
                  :wallet="txn.fromAccount"
                  v-if="txn.fromAccount"
                />
              </td>
              <td>
                <e-wallet-detail-popup
                  :wallet="txn.toAccount"
                  v-if="txn.toAccount"
                />
              </td>
              <td>{{ getTransactionType(txn.transactionType) }}</td>
              <td>{{ txn.details }}</td>
              <td>{{ formatMoney(txn.amount) }}</td>
              <td>{{ formatDateTime(txn.createdAt) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <load-more-button
        :fetchedCount="transactions.length"
        :totalCount="count"
        :next="next"
        @load-more="loadMore"
      />
    </section>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";

import BackButton from "@/components/BackButton";
import TableHeaderOrder from "@/components/TableHeaderOrder";
import EWalletDetailPopup from "@/components/EWalletDetailPopup";
import LoadMoreButton from "@/components/LoadMoreButton";
import {
  api_call,
  getTransactionType,
  formatMoney,
  formatDateTime,
  TRANSACTION_TYPE,
} from "@/utils";

const transactions = ref([]);
const count = ref(0);
const next = ref(null);
const previous = ref(null);
const filters = reactive({
  id: "",
  fromAccount: "",
  toAccount: "",
  transactionType: "",
  amount_gte: "",
  amount_lte: "",
  createdAfter: "",
  createdBefore: "",
  ordering: "-createdAt",
});
const errors = ref([]);
const ascending = ref(false);
const searchTransactions = (url = null, clean = false) => {
  if (clean) {
    transactions.value = [];
  }
  let query = "";
  let filter_list = [];
  for (let key in filters) {
    let value = filters[key];
    if (value) {
      if (key == "id") {
        let temp = parseInt(value.slice(value.indexOf("#") + 1));
        filter_list.push(`${key}=${temp}`);
      } else {
        filter_list.push(`${key}=${value}`);
      }
    }
  }

  if (filter_list.length > 0) {
    query = "?" + filter_list.join("&");
  }

  url = url && url.substr(url.indexOf("/api"));
  api_call(url || `/api/v1/transactions/${query}`, {
    success: (response) => {
      transactions.value = response.data.results;
      count.value = response.data.count;
      next.value = response.data.next;
      previous.value = response.data.previous;
    },
  });
};

const loadMore = () => {
  searchTransactions(next.value, false);
};

const orderBy = (field) => {
  ascending.value = !ascending.value;
  filters.ordering = ascending.value ? field : "-" + field;
  searchTransactions();
};

onMounted(() => {
  searchTransactions(null, true);
});
</script>
