<template>
  <div class="container">
    <section class="section">
      <BackButton />
      <p class="title has-text-info has-text-centered is-size-3">
        Order Details
      </p>
      <order-status-summary :status="cart.status" class="mx-auto" />
    </section>

    <section class="section">
      <order-summary :cart="cart" />
    </section>

    <section class="section">
      <div class="level">
        <p class="has-text-link is-size-4 level-left">Files</p>

        <div class="level level-right">
          <download-all-button :items="items"></download-all-button>
          <div class="dropdown is-active">
            <div class="dropdown-trigger">
              <button
                class="button"
                aria-haspopup="true"
                aria-controls="file-filter"
                @click="() => (showFileFilter = !showFileFilter)"
              >
                <span>Show {{ filterBy }}</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div
              class="dropdown-menu"
              id="file-filter"
              role="menu"
              v-if="showFileFilter"
            >
              <div class="dropdown-content">
                <a class="dropdown-item" @click="() => onHandleFilter('All')">
                  All
                </a>
                <a class="dropdown-item" @click="() => onHandleFilter('.doc')">
                  .doc
                </a>
                <a class="dropdown-item" @click="() => onHandleFilter('.pdf')">
                  .pdf
                </a>
                <a class="dropdown-item" @click="() => onHandleFilter('.ppt')">
                  .ppt
                </a>
                <a
                  class="dropdown-item"
                  @click="() => onHandleFilter('Others')"
                >
                  Others
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <file-item
        v-for="item in filteredItems"
        :key="item.id"
        :file="item"
        :allowDownload="cart.status !== 'CO'"
      />
    </section>

    <!-- <section class="section">
      <p class="has-text-link is-size-4">Customer Remark:</p>
      <div class="control">
        <textarea class="textarea" readonly :value="cart.remark"></textarea>
      </div>
    </section> -->

    <section class="section has-text-centered">
      <button
        class="button is-info"
        @click="approveOrder"
        v-if="cart.status === 'PE'"
      >
        <strong>Approve</strong>
      </button>
      <button
        class="button is-info"
        @click="onInputPrice"
        v-if="cart.status === 'PR'"
      >
        <strong>Ready to be picked up</strong>
      </button>
    </section>

    <div class="modal is-active" v-if="showModal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Order</p>
          <button
            class="delete"
            aria-label="close"
            @click="showModal = false"
          ></button>
        </header>
        <section class="modal-card-body p-5">
          <div class="columns">
            <label class="column is-4 is-size-5">Total Papers :</label>
            <input
              type="number"
              class="column is-6 input"
              v-model="totalPapers"
            />
          </div>
          <div class="columns">
            <label class="column is-3 is-size-5">Net Price :</label>
            <p class="column is-1 is-size-5">RM</p>
            <input type="number" class="column is-6 input" v-model="netPrice" />
          </div>
          <div class="columns is-multiline" v-if="!selectedReward">
            <div class="column is-full pb-0">
              <div
                class="level pb-2 is-clickable"
                @click="showRewards = !showRewards"
                style="border-bottom: 1px solid grey"
              >
                <div class="level-left">
                  <div class="level-item">
                    <b>Available Referral Rewards ({{ rewards.length }}):</b>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <span class="icon" v-show="showRewards">
                      <i class="fa-solid fa-angle-up"></i>
                    </span>
                    <span class="icon" v-show="!showRewards">
                      <i class="fa-solid fa-angle-down"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-full" v-if="showRewards">
              <referral-reward-item
                v-for="r in rewards"
                :key="r.referral_code"
                :reward="r"
                @onApply="selectedReward = r"
              />
            </div>
          </div>
          <referral-reward-item
            :reward="selectedReward"
            :selected="true"
            @onCancel="selectedReward = null"
            v-else
          />
        </section>
        <footer class="modal-card-foot">
          <button class="button is-link is-fullwidth" @click="done">
            Update Order
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import BackButton from "@/components/BackButton.vue";
import OrderStatusSummary from "@/components/OrderStatusSummary";
import OrderSummary from "@/components/OrderSummary";
import FileItem from "@/components/FileItem";
import DownloadAllButton from "@/components/DownloadAllButton";
import ReferralRewardItem from "@/components/merchant/ReferralRewardItem";
import { api_call } from "@/utils";

const router = useRouter();
const route = useRoute();

const items = ref([]);
const cart = ref({});
const showModal = ref(false);
const totalPapers = ref(0);
const netPrice = ref(0);
const filterBy = ref("All");
const showFileFilter = ref(false);
const getCartDetail = (id) => {
  api_call(`/api/v1/carts/${id}/`, {
    success: (res) => {
      cart.value = res.data;
      items.value = res.data.files;
    },
  });
};
onMounted(() => {
  getCartDetail(route.params.id);
});
watch(
  () => route.params.id,
  (newId) => {
    newId && getCartDetail(newId);
  }
);

function onHandleFilter(ext) {
  filterBy.value = ext;
  showFileFilter.value = false;
}
const FILTER_BY = [".pdf", ".doc", ".docx", "ppt", ".pptx"];
const filteredItems = computed(() => {
  return items.value.filter((item) => {
    if (filterBy.value === "All") {
      return true;
    } else if (filterBy.value === "Others") {
      let testcase = "^(?!(";
      FILTER_BY.forEach((str) => {
        testcase += ".+\\" + str + "$|";
      });
      testcase = testcase.slice(0, -1);
      testcase += "))";
      const regex = new RegExp(testcase);
      return regex.test(item.filename);
    } else {
      const regex = new RegExp(`${filterBy.value}(.*x)?$`);
      return regex.test(item.filename);
    }
  });
});

const showRewards = ref(false);
const rewards = ref([]);
const selectedReward = ref(null);
const onInputPrice = async () => {
  const response = await api_call(
    `/api/v1/carts/${cart.value.id}/get_available_rewards/`
  );
  rewards.value = response.data;
  showModal.value = true;
};

const approveOrder = async () => {
  await api_call(`/api/v1/carts/${cart.value.id}/approve/`, {
    method: "post",
  });
  router.push({ name: "MerchantSelectedOrders" });
};

const done = async () => {
  let cartData = new FormData();
  cartData.append("totalPapers", totalPapers.value);
  cartData.append("netPrice", netPrice.value);
  if (selectedReward.value)
    cartData.append("referralReward", selectedReward.value.user);

  await api_call(`/api/v1/carts/${cart.value.id}/done/`, {
    method: "post",
    data: cartData,
  });
  router.push({ name: "MerchantSelectedOrders" });
};
</script>
