<template>
  <div class="columns is-mobile is-desktop is-multiline">
    <div class="column is-full">
      <p class="is-size-4 has-text-link">Printing Details:</p>
    </div>
    <!-- adjust settings -->
    <edit-file-form-field label="Copies">
      <div class="control">
        <input
          class="input"
          type="number"
          v-model="copies"
          min="1"
          step="1"
          @change="copiesChange($event.target.value)"
        />
      </div>
    </edit-file-form-field>

    <edit-file-form-field label="Paper Size">
      <div class="columns is-multiline">
        <div class="column is-full">
          <div class="control">
            <div class="select is-fullwidth">
              <select
                v-model="paperSizeDD"
                @change="sizeChange($event.target.selectedIndex)"
              >
                <option v-for="size in paperSizeList" :key="size">
                  {{ size }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-full" v-if="isOthers">
          <div class="control">
            <div>
              <input
                class="input-line is-size-6"
                type="text"
                v-model="paperSize"
                placeholder="eg. A3"
              />
            </div>
          </div>
        </div>
      </div>
    </edit-file-form-field>

    <edit-file-form-field label="Style">
      <div class="control">
        <div class="select is-fullwidth">
          <select
            v-model="style"
            @change="styleChange($event.target.selectedIndex)"
          >
            <option v-for="style in styleList" :key="style">
              {{ style }}
            </option>
          </select>
        </div>
      </div>
    </edit-file-form-field>

    <edit-file-form-field label="Slides per Sheet">
      <div class="control">
        <div class="select is-fullwidth">
          <select
            v-model="pagesPerSheet"
            @change="sheetChange($event.target.selectedIndex)"
          >
            <option v-for="num in numList" :key="num">
              {{ num }}
            </option>
          </select>
        </div>
      </div>
    </edit-file-form-field>

    <edit-file-form-field label="Layout">
      <div class="control">
        <label class="radio my-2">
          <input
            type="radio"
            value="Potrait"
            v-model="layout"
            name="layoutBtn"
          />
          Potrait
        </label>
        <br />
        <label class="radio">
          <input
            type="radio"
            value="Landscape"
            v-model="layout"
            name="layoutBtn"
          />
          Landscape
        </label>
      </div>
    </edit-file-form-field>

    <edit-file-form-field label="Scale">
      <div class="control">
        <template
          v-for="item in ['Fit', 'Shrink Oversized Pages', 'Actual Size']"
          :key="item"
        >
          <label class="radio my-2">
            <input
              type="radio"
              name="scalebtn"
              v-model="scale"
              :value="item"
              @click="isScale = false"
            />
            {{ item }}
          </label>
          <br />
        </template>

        <div class="columns is-multiline">
          <div class="column is-full">
            <label class="radio my-2">
              <input type="radio" name="scalebtn" @click="scaleChange" />
              Custom Scale

              <div class="column is-full" v-if="isScale">
                <div class="control">
                  <input
                    class="input-line is-size-6"
                    type="text"
                    v-model="scale"
                    placeholder="eg. 80%"
                  />
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </edit-file-form-field>

    <edit-file-form-field label="Color">
      <div class="control">
        <div class="select is-fullwidth">
          <select v-model="color">
            <option>Black and White</option>
            <option>Color</option>
          </select>
        </div>
      </div>
    </edit-file-form-field>

    <edit-file-form-field label="Flip On">
      <div class="field has-addons">
        <div class="control is-expanded">
          <div class="select is-fullwidth">
            <select v-if="isDouble" v-model="flipOn">
              <option>Long edge</option>
              <option>Short edge</option>
            </select>
            <select v-else disabled></select>
          </div>
        </div>
        <div class="control">
          <img
            :src="flipImage[1]"
            :alt="flipImage[0]"
            class="image mx-2"
            style="height: 100px"
          />
        </div>
      </div>
    </edit-file-form-field>

    <edit-file-form-field label="Pages">
      <div class="control">
        <label class="radio my-2">
          <input type="radio" value="All" v-model="pages" name="pagesBtn" />
          All
        </label>
        <br />
        <div class="columns is-multiline">
          <div class="column is-full">
            <label class="radio">
              <input
                type="radio"
                :checked="isCustom"
                name="pagesBtn"
                @click="pagesChange"
              />
              Custom page ranges
            </label>

            <div class="column is-full" v-if="isCustom">
              <div class="control">
                <div>
                  <input
                    class="input-line is-size-6"
                    type="text"
                    v-model="pages"
                    placeholder="eg. 1-10, 15-20"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </edit-file-form-field>

    <edit-file-form-field label="Page Order">
      <div class="columns is-desktop is-mobile is-tablet is-multiline">
        <div class="column is-half-tablet is-half-desktop is-full-mobile">
          <input
            type="radio"
            value="Horizontal Order"
            v-model="pageOrder"
            name="orderBtn"
            :disabled="!isOrder"
          />
          <figure class="image is-64x64">
            <img src="@/images/order_horizontal.png" alt="horizontal" />
          </figure>
        </div>

        <div class="column is-half-tablet is-half-desktop is-full-mobile">
          <input
            type="radio"
            value="Vertical Order"
            v-model="pageOrder"
            name="orderBtn"
            :disabled="!isOrder"
          />
          <figure class="image is-64x64">
            <img src="@/images/order_vertical.png" alt="vertical" />
          </figure>
        </div>
      </div>
    </edit-file-form-field>

    <edit-file-form-field label="Comb Binding">
      <div class="control">
        <label class="checkbox">
          <input type="checkbox" v-model="binding" />
          Required
        </label>
      </div>
    </edit-file-form-field>

    <div class="column">
      <button
        class="button is-light has-text-link is-pulled-right"
        @click="applyDefault"
      >
        <strong> Apply default settings</strong>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, toRefs, defineProps, defineEmits, watch } from "vue";

import EditFileFormField from "@/components/client/EditFileFormField";

// Option List
const paperSizeList = ["A3", "A4", "A5", "Others"];
const styleList = ["Print on single side", "Print on double side"];
const numList = ["1", "2", "4", "6", "9", "16"];

// Printing Settings
const copies = ref(1);
const paperSize = ref("A4");
const paperSizeDD = ref("A4");
const layout = ref("Potrait");
const scale = ref("Fit");
const pages = ref("All");
const pagesPerSheet = ref(1);
const color = ref("Color");
const pageOrder = ref("-");
const style = ref("Print on single side");
const flipOn = ref("-");
const binding = ref(false);

const props = defineProps(["file"]);
const { file } = toRefs(props);
function setForm() {
  // console.log(file.value);
  if (file.value) {
    copies.value = file.value.copies;
    paperSize.value = file.value.paperSize;
    layout.value = file.value.layout;
    scale.value = file.value.scale;
    pages.value = file.value.pages;
    pagesPerSheet.value = file.value.pagesPerSheet;
    color.value = file.value.color;
    pageOrder.value = file.value.pageOrder;
    style.value = file.value.style;
    flipOn.value = file.value.flipOn;
    binding.value = file.value.binding;
  }
}
setForm();
watch(file, setForm);

function applyDefault() {
  copies.value = 1;
  paperSize.value = "A4";
  paperSizeDD.value = "A4";
  layout.value = "Potrait";
  scale.value = "Fit";
  pages.value = "All";
  pagesPerSheet.value = 1;
  color.value = "Color";
  pageOrder.value = "-";
  style.value = "Print on single side";
  flipOn.value = "-";
  binding.value = false;
}

const flipImage = computed(() => {
  let label = "";
  let image = "";
  if (layout.value === "Potrait") {
    if (flipOn.value === "Short edge") {
      image = require("@/images/potrait_short_edge.gif");
      label = "Potrait Short Edge";
    } else if (flipOn.value === "Long edge") {
      image = require("@/images/potrait_long_edge.gif");
      label = "Potrait Long Edge";
    }
  } else if (layout.value === "Landscape") {
    if (flipOn.value === "Short edge") {
      image = require("@/images/landscape_short_edge.gif");
      label = "Landscape Short Edge";
    } else if (flipOn.value === "Long edge") {
      image = require("@/images/landscape_long_edge.gif");
      label = "Landscape Long Edge";
    }
  }
  return [label, image];
});

function copiesChange(copiesInput) {
  if (copiesInput < 1) {
    copies.value = 1;
  }
  if (copiesInput % 1 !== 0) {
    copies.value = Math.round(copiesInput);
  }
}

function sizeChange() {
  if (paperSizeDD.value === "Others") {
    paperSize.value = "";
  } else {
    paperSize.value = paperSizeDD.value;
  }
}
const isOthers = computed(() => {
  return !paperSize.value;
});

function styleChange(selectedIndex) {
  if (styleList.length - 1 === selectedIndex) {
    // double sides
    // set default setting
    flipOn.value = "Long edge";
  } else {
    flipOn.value = "-";
  }
}
const isDouble = computed(() => {
  return flipOn.value !== "-";
});

function sheetChange(selectedIndex) {
  // merge page
  if (selectedIndex !== 0) {
    // set defalut setting
    pageOrder.value = "Horizontal Order";
  } else {
    pageOrder.value = "-";
  }
}
const isOrder = computed(() => {
  return pageOrder.value !== "-";
});

// customize value
function scaleChange() {
  scale.value = "";
}
const isScale = computed(() => {
  return !scale.value;
});
function pagesChange() {
  pages.value = "";
}
const isCustom = computed(() => {
  return pages.value !== "All";
});

const emit = defineEmits(["change"]);
watch(
  [
    copies,
    paperSize,
    layout,
    scale,
    pages,
    pagesPerSheet,
    color,
    pageOrder,
    style,
    flipOn,
    binding,
  ],
  emitChanges
);
emitChanges();
function emitChanges() {
  const data = {
    copies: copies.value,
    paperSize: paperSize.value,
    layout: layout.value,
    scale: scale.value,
    pages: pages.value,
    pagesPerSheet: pagesPerSheet.value,
    color: color.value,
    pageOrder: pageOrder.value,
    style: style.value,
    flipOn: flipOn.value,
    binding: binding.value,
  };
  emit("change", data);
}
</script>
