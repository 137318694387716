<template>
  <div class="container">
    <section class="section">
      <BackButton />
      <p class="title has-text-info has-text-centered is-size-3">
        Order Details
      </p>
      <order-status-summary :status="cart.status" class="mx-auto" />
    </section>

    <section class="section">
      <order-summary :cart="cart" />
    </section>

    <section class="section">
      <p class="title has-text-link is-size-4">File:</p>
      <div class="columns">
        <div class="column is-half">
          <p class="has-text-weight-bold">File Name</p>
        </div>
        <div class="column is-half">
          <p class="has-text-centered has-text-weight-bold">Copies</p>
        </div>
      </div>
      <div v-for="file in items" :key="file.id">
        <div class="printList-box">
          <div class="columns is-multiline is-dekstop is-tablet is-mobile">
            <div class="column is-half">
              <a :href="file.file" :download="file.filename" target="_blank">{{
                file.filename
              }}</a>
            </div>
            <div class="column is-half">
              <p class="has-text-centered">{{ file.copies }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="section">
      <p class="has-text-link is-size-4">Customer Remark:</p>
      <div class="control">
        <textarea class="textarea" readonly :value="cart.remark"></textarea>
      </div>
    </section> -->

    <section class="section has-text-centered">
      <div class="buttons is-justify-content-center mb-0">
        <button class="button is-danger" @click="cancelOrder" v-if="canCancel">
          <strong>Cancel</strong>
        </button>
        <!-- <button
          class="button is-link"
          @click="makePayment"
          v-if="isPrepared && pendingPayment > 0"
        >
          <strong>Pay by E-Wallet</strong>
        </button> -->
        <button
          v-if="cart.status == 'CO'"
          class="button is-link"
          @click="showEnquiryModal = true"
        >
          Refund
        </button>
      </div>
      <!-- <p class="help pt-0" v-if="isPrepared && pendingPayment > 0">
        Pending Payment Amount:
        {{ formatMoney(pendingPayment) }}
      </p> -->
    </section>
  </div>
  <enquiry-modal :show="showEnquiryModal" @close="showEnquiryModal = false" />
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import moment from "moment";

// import store from "@/store";
import OrderStatusSummary from "@/components/OrderStatusSummary.vue";
import BackButton from "@/components/BackButton.vue";
import OrderSummary from "@/components/OrderSummary";
import EnquiryModal from "@/components/EnquiryModal";
import {
  api_call,
  getMomentObjectFromFormattedDate,
  // formatMoney,
  // getPendingPayment,
  // getStatusOrder,
  // isInsufficientAmount,
} from "@/utils";

const route = useRoute();

const showEnquiryModal = ref(false);

const cart = ref({});
const items = ref([]);
const getCartDetail = (id) => {
  api_call(`/api/v1/carts/${id}/`, {
    success: (res) => {
      cart.value = res.data;
      items.value = res.data.files;
    },
  });
};
onMounted(() => {
  getCartDetail(route.params.id);
});
watch(
  () => route.params.id,
  (newId) => {
    newId && getCartDetail(newId);
  }
);

const forceRecompute = ref(0);
const canCancel = computed(() => {
  return (
    forceRecompute.value >= 0 &&
    cart.value.status === "PE" &&
    moment().isBefore(
      getMomentObjectFromFormattedDate(cart.value.createdAt).add(5, "m")
    )
  );
});
const cancelOrder = () => {
  api_call(`/api/v1/carts/${cart.value.id}/cancel_order/`, {
    method: "post",
    success: (res) => {
      cart.value = res.data;
    },
    failed: (res) => {
      const overtime = res.reduce((acc, curr) => {
        return acc || curr.indexOf("OVERTIME_ERROR") > -1;
      }, false);
      if (overtime) {
        forceRecompute.value++;
      }
    },
  });
};

// const pendingPayment = computed(() => {
//   return getPendingPayment(cart.value);
// });
// const isPrepared = computed(() => {
//   return getStatusOrder(cart.value.status) >= getStatusOrder("RP");
// });

// const makePayment = () => {
//   api_call(`/api/v1/carts/${cart.value.id}/make_payment/`, {
//     method: "post",
//     flash_message: false,
//     data: { paymentMethod: "EW" },
//     success: (res) => {
//       cart.value = res.data;
//     },
//     failed: (res) => {
//       if (isInsufficientAmount(res)) {
//         store.dispatch("flashMessage", {
//           type: "is-danger",
//           content: "Insufficient Amount, please reload before making payment.",
//         });
//       }
//     },
//   });
// };
</script>
