<template>
  <div class="modal" :class="{ 'is-active': show }">
    <div class="modal-background"></div>

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Release Reward</p>
        <button class="delete" aria-label="close" @click="cancel"></button>
      </header>
      <section class="modal-card-body">
        <p v-if="awaitingConfirmation">
          The amount has been rounded to <strong>RM {{ amount }}</strong
          >. Are you sure you want to release the reward?
        </p>
        <div class="field" v-else>
          <label class="label">Amount</label>
          <div class="control">
            <div class="field has-addons">
              <div class="control"><a class="button is-static">RM</a></div>
              <div class="control is-expanded">
                <input
                  type="number"
                  class="input"
                  placeholder="5.00"
                  step="0.05"
                  v-model="enteredAmount"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button
          class="button is-success"
          @click="emits('release', amount, awaitingConfirmation)"
        >
          {{ confirmButtonText }}
        </button>
        <button class="button" @click="cancel">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  show: { type: Boolean, default: false },
  awaitingConfirmation: { type: Boolean, default: false },
});
const { show, awaitingConfirmation } = toRefs(props);
const enteredAmount = ref(0.0);

const amount = computed(() => {
  // handle NaN situation
  if (isNaN(parseFloat(enteredAmount.value))) {
    // debugger;
    return (0).toFixed(2);
  }
  //   round to nearest 0.05
  return (Math.round(parseFloat(enteredAmount.value) * 20) / 20).toFixed(2);
});
const confirmButtonText = computed(() => {
  return awaitingConfirmation.value ? "Confirm" : "Release";
});

const emits = defineEmits(["cancel", "release"]);
const cancel = () => {
  emits("cancel");
  enteredAmount.value = 0;
};
</script>
