<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <BackButton />
        <p class="title is-size-4 has-text-info">Merchant List</p>
      </div>
      <div class="level-right">
        <router-link class="button is-info" :to="{ name: 'AddMerchant' }"
          >Add Merchant</router-link
        >
      </div>
    </div>
    <hr />

    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-full">
        <div class="is-flex is-flex-wrap-wrap">
          <div class="field mr-5">
            <label for="name" class="label">Company Name</label>
            <div class="control">
              <input
                type="text"
                name="name"
                v-model="filters.name"
                class="input is-small"
                placeholder="Company Name"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="registrationNo" class="label"
              >Company Registration No.</label
            >
            <div class="control">
              <input
                type="text"
                name="registrationNo"
                v-model="filters.registrationNo"
                class="input is-small"
                placeholder="Company Registration No."
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="email" class="label">Email Address</label>
            <div class="control">
              <input
                type="text"
                name="email"
                v-model="filters.email"
                class="input is-small"
                placeholder="Email Address"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="contact_num" class="label">Contact Number</label>
            <div class="control">
              <input
                type="text"
                name="contact_num"
                v-model="filters.contact_num"
                class="input is-small"
                placeholder="Contact Number"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="city" class="label">City</label>
            <div class="control">
              <input
                type="text"
                name="city"
                v-model="filters.city"
                class="input is-small"
                placeholder="City"
              />
            </div>
          </div>

          <div class="field ml-auto mr-5 is-align-self-center">
            <div class="control">
              <button
                class="button is-link"
                @click="searchMerchant(null, true)"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="column">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in errors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('id')"
                label="ID"
                field="id"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('name')"
                label="Company Name"
                field="name"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('registrationNo')"
                label="Company Registration No."
                field="registrationNo"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('email')"
                label="Email Address"
                field="email"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('contact_num')"
                label="Contact Number"
                field="contact_num"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('city')"
                label="City"
                field="city"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="merchant in merchants" :key="merchant.id">
            <th># {{ merchant.id }}</th>
            <td>{{ merchant.name }}</td>
            <td>{{ merchant.registrationNo }}</td>
            <td>{{ merchant.email }}</td>
            <td>{{ merchant.contact_num }}</td>
            <td>{{ merchant.city }}</td>
            <td>
              <span class="tag is-success" v-if="isActive(merchant)">
                ACTIVE
              </span>
              <span class="tag is-danger" v-else> INACTIVE </span>
            </td>
            <td>
              <div class="buttons">
                <button
                  class="button is-small is-danger is-outlined"
                  v-if="isActive(merchant)"
                  @click="changeStatus(merchant, false)"
                >
                  Deactivate
                </button>
                <button
                  class="button is-small is-link is-outlined"
                  v-else
                  @click="changeStatus(merchant, true)"
                >
                  Activate
                </button>
                <!-- <router-link
                  class="button is-small has-text-link"
                  :to="{ name: 'EditMerchant', params: { id: merchant.id } }"
                >
                  <span class="icon is-small">
                    <i class="far fa-pen-to-square"></i>
                  </span>
                  <span>Edit</span>
                </router-link> -->
                <router-link
                  class="button is-small has-text-link"
                  :to="{ name: 'ViewMerchant', params: { id: merchant.id } }"
                >
                  <span class="icon is-small">
                    <i class="far fa-folder-open"></i>
                  </span>
                  <span>Detail</span>
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <load-more-button
      :fetched-count="merchants.length"
      :total-count="count"
      :next="next"
      @load-more="loadMore"
    />
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";

import TableHeaderOrder from "@/components/TableHeaderOrder";
import BackButton from "@/components/BackButton";
import LoadMoreButton from "@/components/LoadMoreButton";
import { api_call } from "@/utils";

const store = useStore();

const merchants = ref([]);
const count = ref(0);
const next = ref(null);
const previous = ref(null);
const filters = reactive({
  name: "",
  registrationNo: "",
  email: "",
  contact_num: "",
  city: "",
  ordering: "id",
});
const errors = ref([]);
const ascending = ref(true);

const searchMerchant = async (url = undefined, clean = true) => {
  errors.value = [];
  if (clean) {
    merchants.value = [];
  }
  var query = "";
  var filter_list = [];
  for (var key in filters) {
    var value = filters[key];
    if (value) {
      filter_list.push(`${key}=${value}`);
    }
  }

  if (filter_list.length > 0) {
    query = "?" + filter_list.join("&");
  }

  url = url && url.substr(url.indexOf("/api"));
  try {
    const response = await api_call(url || `/api/v1/merchants/${query}`);
    merchants.value = merchants.value.concat(response.data.results);
    count.value = response.data.count;
    next.value = response.data.next;
    previous.value = response.data.previous;
  } catch (error) {
    errors.value = error;
  }
};
const loadMore = () => {
  searchMerchant(next.value, false);
};
const orderBy = (field) => {
  ascending.value = !ascending.value;
  filters.ordering = ascending.value ? field : "-" + field;
  searchMerchant();
};

onMounted(() => {
  searchMerchant();
});

const isActive = (merchant) => {
  return merchant.user && merchant.user.is_active;
};

const changeStatus = async (merchant, status) => {
  const id = merchant.user && merchant.user.id;

  if (!id) {
    store.dispatch("flashMessage", {
      type: "is-danger",
      content: "The merchant has no user associated with it.",
    });
  }

  const action = status ? "activate" : "deactivate";
  await api_call(`/api/v1/users/${id}/${action}/`, {
    method: "post",
    success() {
      store.dispatch("flashMessage", {
        type: "is-success",
        content: "Status changed successfully",
      });
    },
  });
  searchMerchant();
};
</script>
