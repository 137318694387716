<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <BackButton />
        <p class="title is-size-4 has-text-info">Order List</p>
      </div>
    </div>
    <hr />

    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-full">
        <div class="is-flex is-flex-wrap-wrap">
          <div class="field mr-5">
            <label for="id" class="label">Order ID</label>
            <div class="control">
              <input
                type="text"
                name="id"
                v-model="filters.id"
                class="input is-small"
                placeholder="Order ID without #"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="status" class="label">Status</label>
            <div class="control">
              <div class="select is-small">
                <select name="status" v-model="filters.status">
                  <option value="ALL">All</option>
                  <option
                    v-for="key in Object.keys(ORDER_STATUS)"
                    :value="key"
                    :key="key"
                  >
                    {{ ORDER_STATUS[key].text }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field mr-5">
            <label for="order_start" class="label">Order Date After</label>
            <div class="control">
              <input
                type="date"
                name="order_start"
                v-model="filters.order_start"
                class="input is-small"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="order_end" class="label">Order Date Before</label>
            <div class="control">
              <input
                type="date"
                name="order_end"
                v-model="filters.order_end"
                class="input is-small"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="merchant_name" class="label">Merchant</label>
            <div class="control">
              <input
                type="text"
                name="merchant_name"
                v-model="filters.merchant_name"
                class="input is-small"
                placeholder="Merchant"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="delivery_name" class="label">Delivered by</label>
            <div class="control">
              <input
                type="text"
                name="delivery_name"
                v-model="filters.delivery_name"
                class="input is-small"
                placeholder="Delivery"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="client_name" class="label">Client</label>
            <div class="control">
              <input
                type="text"
                name="client_name"
                v-model="filters.client_name"
                class="input is-small"
                placeholder="Client"
              />
            </div>
          </div>

          <div class="field ml-auto mr-5 is-align-self-center">
            <div class="control">
              <button class="button is-link" @click="searchOrders()">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="column" v-if="errors.length > 0">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in errors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('id')"
                label="Order ID"
                field="id"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Status</th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('createdAt')"
                label="Order Time"
                field="createdAt"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('deliveryTime')"
                label="Delivery TIme"
                field="deliveryTime"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('merchant__name')"
                label="Merchant"
                field="merchant__name"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('delivery__name')"
                label="Delivered by"
                field="delivery__name"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('client__name')"
                label="Client"
                field="client__name"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('totalPrice')"
                label="Price"
                field="totalPrice"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders" :key="order.id">
            <th
              :class="{
                'has-background-warning': isOutsideKampar(order.client),
              }"
            >
              # {{ order.id }}
            </th>
            <td>
              <span class="tag" :class="getStatusColor(order.status)">
                {{ getStatusText(order.status) }}
              </span>
            </td>
            <td>{{ formatDateTime(order.createdAt) }}</td>
            <td>{{ formatDateTime(order.deliveryTime) }}</td>
            <td>
              <MerchantDetailPopup
                :merchant="order.merchant"
                v-if="order.merchant"
              />
            </td>
            <td>
              <RiderDetailPopup :rider="order.delivery" v-if="order.delivery" />
            </td>
            <td>
              <ClientDetailPopup :client="order.client" />
            </td>
            <td style="white-space: nowrap">
              <span v-if="order.totalPrice">RM {{ order.totalPrice }}</span>
              <span v-else>TBD</span>
            </td>
            <td>
              <div class="buttons">
                <router-link
                  class="button is-small has-text-link"
                  :to="{
                    name: 'AdminOrderDetails',
                    params: { id: order.id },
                  }"
                >
                  <span class="icon is-small">
                    <i class="far fa-folder-open"></i>
                  </span>
                  <span>View</span>
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="is-flex is-justify-content-space-between is-align-items-flex-end"
    >
      <p class="is-flex-grow-1 has-text-right has-text-weight-light mr-5">
        Showing {{ orders.length }} of {{ count }} results
      </p>
      <div>
        <button class="button is-link is-small" @click="loadMore" v-if="next">
          Load More
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import TableHeaderOrder from "@/components/TableHeaderOrder";
import BackButton from "@/components/BackButton";
import MerchantDetailPopup from "@/components/MerchantDetailPopup";
import RiderDetailPopup from "@/components/RiderDetailPopup";
import ClientDetailPopup from "@/components/ClientDetailPopup";
import {
  getStatusText,
  getStatusColor,
  formatDateTime,
  api_call,
  ORDER_STATUS,
  isOutsideKampar,
} from "@/utils";

import { ref, reactive, onMounted } from "vue";

const orders = ref([]);
const count = ref(0);
const next = ref(null);
const previous = ref(null);
const filters = reactive({
  id: "",
  status: "ALL",
  order_start: "",
  order_end: "",
  merchant_name: "",
  delivery_name: "",
  client_name: "",
  ordering: "-createdAt",
});
const errors = ref([]);
const ascending = ref(false);

const searchOrders = async (url = undefined, clean = true) => {
  errors.value = [];
  if (clean) {
    orders.value = [];
  }
  let query = "";
  let filter_list = [];
  for (let key in filters) {
    let value = filters[key];
    if (value) {
      if (key == "id") {
        let temp = parseInt(value.slice(value.indexOf("#") + 1));
        filter_list.push(`${key}=${temp}`);
      } else if (key == "status") {
        if (value == "ALL") {
          value = "PE,PR,RP,DE,CO,CA";
        }
        filter_list.push(`${key}=${value}`);
      } else {
        filter_list.push(`${key}=${value}`);
      }
    }
  }

  if (filter_list.length > 0) {
    query = "?" + filter_list.join("&");
  }

  url = url && url.substr(url.indexOf("/api"));
  api_call(url || `/api/v1/carts/${query}`, {
    failed: (error) => {
      errors.value = error;
    },
    success: (response) => {
      orders.value = orders.value.concat(response.data.results);
      count.value = response.data.count;
      next.value = response.data.next;
      previous.value = response.data.previous;
    },
  });
};

const loadMore = () => {
  searchOrders(next.value, false);
};

const orderBy = (field) => {
  ascending.value = !ascending.value;
  filters.ordering = ascending.value ? field : "-" + field;
  searchOrders();
};

// const fileDownload = require("js-file-download");
// const merchantID = ref(null);
// const downloadInvoice = async () => {
//   const response = api_call(
//     `/api/v1/merchant/${merchantID.value}/monthly/commission/`,
//     {
//       data: { responseType: "blob" },
//       failed: (error) => {
//         errors.value = error;
//       },
//     }
//   );
//   fileDownload(response.data, `monthlyCommission_${merchantID.value}.pdf`);
// };

onMounted(() => {
  searchOrders();
});
</script>
