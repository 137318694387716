<template>
  <section class="section">
    <div class="columns is-multiline">
      <div class="column is-full pb-0">
        <BackButton />
      </div>
      <div class="column is-full">
        <p class="title has-text-info has-text-centered is-size-3">
          Delivery Status
        </p>
      </div>
    </div>
    <div class="columns is-multiline my-5">
      <div class="column is-2">Order ID:</div>
      <div class="column is-10"># {{ cart.id }}</div>
      <div class="column is-2">Customer Name:</div>
      <div class="column is-10">{{ client.name }}</div>

      <div class="column is-2">Customer Phone:</div>
      <div class="column is-10">{{ client.contact_num }}</div>
      <div class="column is-2">Customer Address:</div>
      <div class="column is-10">
        {{ addTrailingComma(client.address1) }}
        {{ addTrailingComma(client.address2) }}
        {{ client.zipcode }}
        {{ addTrailingComma(client.city) }}
        {{ addTrailingComma(client.state) }}
        {{ client.country }}
      </div>
      <div class="column is-2">Deliver By:</div>
      <div class="column is-10">
        <RiderDetailPopup :rider="delivery" v-if="delivery" />
      </div>
    </div>
    <div class="root">
      <div class="container">
        <ul class="progressbar">
          <li class="active has-text-link">Order Received</li>
          <li class="active has-text-link" v-if="cart.isDone">
            Ready to pick up
          </li>
          <li class="not-active" v-else>Preparing</li>
          <li class="active has-text-link" v-if="cart.isDelivering">
            Out for delivery
          </li>
          <li class="not-active" v-else>Waiting to pick up</li>
          <li class="active has-text-link" v-if="cart.isDelivered">
            Order Completed
          </li>
          <li class="not-active" v-else>Delivering</li>
        </ul>
      </div>
    </div>
    <div class="columns is-multiline pt-6" v-if="cart.clientReceived">
      <div class="column is-full">
        <p class="has-text-link is-size-4">Delivered:</p>
      </div>
      <div class="column is-2">Received By:</div>
      <div class="column is-10">{{ cart.received_by }}</div>
      <div class="column is-2">Received Time:</div>
      <div class="column is-10">{{ cart.deliveredAt }}</div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

import BackButton from "@/components/BackButton";
import RiderDetailPopup from "@/components/RiderDetailPopup";
import { addTrailingComma, api_call } from "@/utils";

const route = useRoute();

const orderIndex = ref(0);
const client = ref({});
const cart = ref({});
const delivery = ref(null);

onMounted(() => {
  orderIndex.value = route.params.id;
  api_call(`/api/v1/carts/${orderIndex.value}`, {
    success(res) {
      cart.value = res.data;
      client.value = res.data.client;
      delivery.value = res.data.delivery;
    },
  });
});
</script>
