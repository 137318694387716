<template>
  <div class="field has-addons">
    <p class="control">
      <input
        :type="inputType"
        class="input"
        :value="modelValue"
        @input="onInput"
        size="50"
      />
    </p>
    <p class="control">
      <a class="icon is-large is-right" @click="emits('toggleShow')">
        <i :class="'fa ' + icon"></i>
      </a>
    </p>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  showPassword: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: "",
  },
});
const emits = defineEmits(["toggleShow", "update:modelValue"]);

const inputType = computed(() => {
  return props.showPassword ? "text" : "password";
});
const icon = computed(() => {
  return props.showPassword ? "fa-eye" : "fa-eye-slash";
});

const onInput = (e) => {
  emits("update:modelValue", e.target.value);
};
</script>
