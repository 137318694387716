import { createStore } from "vuex";
import axios from "axios";

import router from "@/router";
import cart from "./modules/cart";
import notification from "./modules/notification";
import { parseToken, get_role_name } from "@/utils";

export default createStore({
  modules: { cart, notification },
  state: {
    user: null,
    token: null,
    expireAt: null,
    loading: 0,
    messages: [],
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setExpireAt(state, payload) {
      state.expireAt = payload;
    },
    setUser(state, user) {
      state.user = user;
    },
    addLoading(state) {
      state.loading++;
    },
    decreaseLoading(state) {
      state.loading = state.loading - 1 > 0 ? state.loading - 1 : 0;
    },
    setRole(state, role) {
      if (state.user) {
        state.user.role == role;
      }
    },
    setMessages(state, payload) {
      state.messages = payload;
    },
  },
  actions: {
    setRole(context, payload) {
      context.commit("setRole", payload);
    },
    setToken(context, payload) {
      localStorage.setItem("at", payload.access);
      localStorage.setItem("rt", payload.refresh);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + payload.access;
      context.commit("setToken", payload);

      const expireAt = parseToken(payload.access).exp * 1000;
      context.commit("setExpireAt", expireAt);
    },
    setUser(context, { username, id, email, role }) {
      const user = { username, email, role, id };
      context.commit("setUser", user);
    },
    addLoading(context) {
      context.commit("addLoading");
    },
    decreaseLoading(context) {
      context.commit("decreaseLoading");
    },
    invalidateToken(context) {
      localStorage.removeItem("at");
      localStorage.removeItem("rt");
      axios.defaults.headers.common["Authorization"] = "";

      context.commit("setUser", null);
      context.commit("setToken", null);
    },
    async tryAutoLogin(context) {
      const access = localStorage.getItem("at");
      const refresh = localStorage.getItem("rt");
      if (!access || !refresh) {
        context.dispatch("invalidateToken");
        router.replace("/");
        return;
      }
      try {
        const token_payload = { access, refresh };
        context.dispatch("setToken", token_payload);
        await context.dispatch("refreshToken", { refresh });
        await context.dispatch("loadUser");

        if (context.getters.isClient) {
          router.push({ name: "Dashboard" });
        } else {
          const role = context.getters.role;
          router.push({
            name: `${role[0].toUpperCase() + role.substr(1)}Dashboard`,
          });
        }
      } catch (error) {
        context.dispatch("flashMessage", {
          type: "is-danger",
          content: "<b>Login Expired</b>. Please signin again.",
        });
        console.error(error);
        context.dispatch("invalidateToken");
        router.replace("/");
      }
    },
    async refreshToken(context, payload) {
      const force = payload && payload.force;
      const refresh_token = payload && payload.refresh;
      const isTokenExpired = new Date(context.getters.expireAt - 60000) < new Date()
      if (force || isTokenExpired) {
        try {
          const refresh = refresh_token || context.getters.token.refresh;
          const response = await axios.post("/api/v1/auth/jwt/refresh/", {
            refresh,
          });
          const access = response.data.access;
          const token_payload = { access, refresh };
          context.dispatch("setToken", token_payload);
        } catch (error) {
          context.dispatch("flashMessage", {
            type: "is-danger",
            content: "<b>Authentication Expired</b>. Please signin again.",
          });
          context.dispatch("invalidateToken");
          router.replace("/");
        }
      }
    },
    async loadUser(context) {
      const response = await axios.get("/api/v1/users/me");
      const user = {
        username: response.data.username,
        id: response.data.id,
        email: response.data.email,
        role: get_role_name(response.data.role),
      };

      context.commit("setUser", user);
    },
    async login(context, payload) {
      const response = await axios.post("/api/v1/auth/jwt/create/", payload);
      context.dispatch("setToken", response.data);
    },
    pushMessage(context, payload) {
      const messages = context.getters.messages;
      const newMsg = {
        ...payload,
        id: messages.length,
      };
      context.commit("setMessages", [newMsg].concat(messages));
      return newMsg;
    },
    removeMessage(context, payload) {
      const messages = context.getters.messages;
      context.commit(
        "setMessages",
        messages.filter((msg) => msg.id !== payload)
      );
    },
    async flashMessage(context, payload) {
      const msg = await context.dispatch("pushMessage", payload);

      setTimeout(() => {
        context.dispatch("removeMessage", msg.id);
      }, 4000);
    },
  },
  getters: {
    isLoading(state) {
      return state.loading > 0;
    },
    token(state) {
      return state.token;
    },
    role(state) {
      return state.user && state.user.role;
    },
    isAuthenticated(state) {
      return !!state.token && !!state.user;
    },
    isClient(state) {
      return state.user && state.user.role === "client";
    },
    userId(state) {
      return state.user && state.user.id;
    },
    user(state) {
      return state.user;
    },
    messages(state) {
      return state.messages;
    },
    expireAt(state) {
      return state.expireAt;
    },
  },
});
