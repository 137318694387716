<template>
  <div class="page-carts">
    <section class="section is-small">
      <p class="title has-text-info is-size-3 has-text-centered">
        Shopping Cart
      </p>
      <free-shipping-banner />

      <div class="column" v-if="errors.length > 0">
        <div
          class="message mb-1 is-danger"
          v-for="(error, index) in errors"
          :key="index"
        >
          <div class="message-body">
            {{ error }}
          </div>
        </div>
      </div>

      <div class="box">
        <div class="columns is-mobile is-desktop is-tablet is-multiline">
          <div class="column is-10-desktop is-9-tablet is-12-mobile">
            <h3 class="is-size-4 has-text-link">Printing List</h3>
          </div>

          <div class="column is-2-desktop is-3-tablet is-12-mobile">
            <router-link to="/dashboard/cart/add" class="button is-info"
              ><strong>Add More Item</strong>
            </router-link>
          </div>

          <div class="column is-full">
            <div class="columns">
              <div class="column is-2">
                <label class="has-text-link is-size-5 mr-5">
                  Delivery Time:
                </label>
              </div>
              <div class="column is-3">
                <!-- <Datepicker v-model="deliveryTime"></Datepicker> -->
                <input
                  type="datetime-local"
                  v-model="deliveryTime"
                  class="input is-small"
                />
              </div>
            </div>
          </div>

          <div
            class="column is-6-desktop is-full-mobile"
            v-if="!!selectedClient"
          >
            <label class="has-text-link is-size-5">Delivery Information:</label>

            <p><strong>Name: </strong> {{ selectedClient.name }}</p>
            <p>
              <strong>Contact Number: </strong>
              {{ selectedClient.country_code }}{{ selectedClient.contact_num }}
            </p>

            <p>
              <strong>Address: </strong><br />
              {{ addTrailingComma(selectedClient.address1) }} <br />
              {{ addTrailingComma(selectedClient.address2) }}
              <br v-if="selectedClient.address2" />
              {{ selectedClient.zipcode }}
              {{ addTrailingComma(selectedClient.city) }} <br />
              {{ addTrailingComma(selectedClient.state) }}
              {{ selectedClient.country }}
            </p>
          </div>
          <div class="column is-6-desktop is-full-mobile">
            <p class="mb-2" v-show="isNotKampar">
              <label class="checkbox">
                <input
                  type="checkbox"
                  value="deliveryByCourier"
                  checked
                  disabled
                />
                Delivery by courier.
                <hoverable-tag
                  icon="fa-regular fa-xl fa-circle-question"
                  color="has-text-primary is-white"
                >
                  A delivery fee of RM 5.90 will be added to your order.
                </hoverable-tag>
              </label>
            </p>
            <p class="mb-2">
              <label class="checkbox">
                <input
                  type="checkbox"
                  value="urgentOrder"
                  v-model="cart.urgentOrder"
                />
                Urgent order.
                <hoverable-tag
                  icon="fa-regular fa-xl fa-circle-question"
                  color="has-text-primary is-white"
                >
                  Your order will be prioritized, and a charge of RM 3.00 will
                  be added to your order.
                </hoverable-tag>
              </label>
            </p>
            <p class="is-size-5 has-text-link">Remark:</p>
            <textarea
              class="textarea"
              name="remark"
              id="remark"
              v-model="cart.remark"
              @change="cartRemarkChange"
              placeholder="Write your remark here"
            ></textarea>
          </div>

          <div class="column is-12-mobile is-12-tablet is-12-desktop">
            <div class="columns is-multiline is-mobile is-desktop is-tablet">
              <div class="column is-2-dektop is-2-tablet is-full-mobile">
                <button
                  class="button has-text-link is-light is-fullwidth"
                  @click="otherAddr = !otherAddr"
                >
                  <strong>Other Address</strong>
                </button>
              </div>
              <div class="column is-2-dektop is-2-tablet is-full-mobile">
                <router-link
                  to="/dashboard/clients/add"
                  class="button has-text-link is-light is-fullwidth"
                  ><strong>Add Address</strong></router-link
                >
              </div>
            </div>
          </div>

          <div
            class="column is-4-dektop is-4-tablet is-full-mobile"
            v-show="otherAddr"
          >
            <div class="control">
              <div class="select is-fullwidth">
                <select @change="changeAddr($event.target.selectedIndex)">
                  <option v-for="client in clients" :key="client.id">
                    {{ client.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <hr class="black-line" />
        <p>*Please make sure the files are in correct printing order.</p>
        <div
          class="
            columns
            is-mobile is-desktop is-tablet is-multiline is-centered
            my-5
          "
          v-for="(item, index) in files"
          :key="item.filename"
        >
          <div class="column is-5-desktop is-full-mobile is-full-tablet">
            <h6>
              <strong>{{ item.filename }}</strong>
            </h6>
          </div>

          <div class="column is-7-desktop is-full-mobile is-full-tablet">
            <div class="printList-box">
              <div class="columns is-mobile is-desktop is-multiline">
                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p><strong>Copies :</strong> {{ item.copies }}</p>
                </div>
                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p>
                    <strong>Paper Size :</strong>
                    {{ item.paperSize }}
                  </p>
                </div>

                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p><strong>Style :</strong> {{ item.style }}</p>
                </div>

                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p>
                    <strong>Slides per Sheet :</strong>
                    {{ item.pagesPerSheet }}
                  </p>
                </div>

                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p><strong>Layout :</strong> {{ item.layout }}</p>
                </div>

                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p><strong>Scale :</strong> {{ item.scale }}</p>
                </div>

                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p><strong>Color :</strong> {{ item.color }}</p>
                </div>

                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p>
                    <strong>Flip On :</strong>
                    {{ item.flipOn }}
                  </p>
                </div>
                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p><strong>Pages :</strong> {{ item.pages }}</p>
                </div>
                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p>
                    <strong>Page Order :</strong>
                    {{ item.pageOrder }}
                  </p>
                </div>
                <div class="column is-6-desktop is-full-mobile is-6-tablet">
                  <p>
                    <strong>Comb Binding :</strong>
                    {{ item.binding ? "Yes" : "No" }}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div class="buttons is-centered">
              <button
                class="button is-light has-text-link action-button mx-5"
                @click="editItem(index)"
              >
                <strong> Edit </strong>
              </button>
              <button
                class="button is-link action-button mx-5"
                @click="deleteItem(index)"
              >
                <strong> Delete </strong>
              </button>
            </div>
          </div>
        </div>
        <div class="columns is-desktop is-mobile is-multiline">
          <div class="column is-7-desktop is-full-mobile columns">
            <div class="column is-3-widescreen is-half-desktop is-full-mobile">
              <p class="no-wrap"><strong>Payment Option:</strong></p>
              <div class="control">
                <label class="radio">
                  <input
                    type="radio"
                    name="paymentMethod"
                    v-model="paymentMethod"
                    checked
                    value="COD"
                  />
                  Cash
                </label>
                <br />
                <label class="radio">
                  <input
                    type="radio"
                    name="paymentMethod"
                    v-model="paymentMethod"
                    value="TNG"
                  />
                  TnG
                </label>
                <!-- <br />
                <label class="radio">
                  <input
                    type="radio"
                    name="paymentMethod"
                    v-model="paymentMethod"
                    value="EW"
                  />
                  E-Wallet
                </label> -->
              </div>
            </div>
            <div
              class="flex-1 px-2 columns column is-full-mobile is-multiline"
              v-if="paymentMethod === 'TNG'"
              style="cursor: zoom-in"
              @click="zoomImage = true"
            >
              <p
                class="
                  image
                  column
                  is-3-widescreen is-full-tablet is-full-mobile
                "
              >
                <img
                  style="height: 150px; width: auto"
                  src="@/images/tng_qrcode.jpg"
                  height="150px"
                  class="mx-2"
                  width="auto"
                  alt="Touch 'n Go QR code"
                />
              </p>
              <p class="help column">
                Remark: If you paid with TnG, please show the transaction
                history to deliver
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons is-centered">
        <div class="column is-3">
          <button class="button is-info is-fullwidth" @click="checkout">
            <strong> Checkout </strong>
          </button>
        </div>
      </div>
    </section>
  </div>
  <ImageZoomModal :show="zoomImage" @close="zoomImage = false">
    <img src="@/images/tng_qrcode.jpg" alt="Touch 'n Go QR Code" />
  </ImageZoomModal>

  <UploadProgressModal
    :files="files"
    :show="uploading"
    @close="uploading = false"
  >
  </UploadProgressModal>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";

import FreeShippingBanner from "@/components/client/FreeShippingBanner";
import ImageZoomModal from "@/components/ImageZoomModal";
import UploadProgressModal from "@/components/client/UploadProgressModal";
import HoverableTag from "@/components/HoverableTag";
import { addTrailingComma, api_call, isOutsideKampar } from "@/utils";

const store = useStore();
const router = useRouter();

const zoomImage = ref(false);
const uploading = ref(false);

function get_file_payload(file, cartId) {
  let formData = new FormData();
  // formData.append("file", file.file);
  formData.append("fileUrl", file.fileUrl);
  formData.append("cart", cartId);
  formData.append("filename", file.filename);
  formData.append("copies", file.copies);
  formData.append("layout", file.layout);
  formData.append("pages", file.pages);
  formData.append("color", file.color);
  formData.append("style", file.style);
  formData.append("flipOn", file.flipOn);
  formData.append("binding", file.binding);
  formData.append("paperSize", file.paperSize);
  formData.append("scale", file.scale);
  formData.append("pagesPerSheet", file.pagesPerSheet);
  formData.append("pageOrder", file.pageOrder);
  formData.append("index", parseInt(file.index));

  return formData;
}

const otherAddr = ref(false);
const clients = ref([]);
const selectedClient = ref({});
const files = ref([]);
const cart = reactive({
  client: 0,
  remark: "",
  deliveryByCourier: false,
  urgentOrder: false,
});
const cartId = ref(0);
const deliveryTime = ref(moment().add(1, "hours").format("YYYY-MM-DDTHH:mm"));
const paymentMethod = ref("COD");
const errors = ref([]);

onMounted(() => {
  files.value = store.getters["cart/files"];
  cart.remark = store.getters["cart/remark"];
  api_call("/api/v1/clients", {
    success(res) {
      clients.value = res.data.results;
      const storedClient = store.getters["cart/client"];
      if (storedClient !== 0) {
        selectedClient.value = res.data.results.find(
          (client) => client.id === storedClient
        );
        cart.client = storedClient;
      } else {
        selectedClient.value = res.data.results[0];
        cart.client = selectedClient.value && selectedClient.value.id;
      }
    },
  });

  files.value.map((item) => (item.uploadProgress = 0));
});
function cartRemarkChange() {
  store.dispatch("cart/setRemark", cart.remark);
}

function changeAddr(selectedIndex) {
  selectedClient.value = {};
  cart.client = 0;
  for (let i = 0; i < clients.value.length; i++) {
    if (i == selectedIndex) {
      cart.client = clients.value[i].id;
      store.dispatch("cart/setClient", clients.value[i].id);
      selectedClient.value = clients.value[i];
    }
  }
}
const isNotKampar = computed(() => {
  return isOutsideKampar(selectedClient.value);
});
function deleteItem(index) {
  store.dispatch("cart/removeFile", index);
}

function editItem(index) {
  router.push(`/dashboard/carts/${index}/edit`);
}

async function checkout() {
  errors.value = [];

  const validTime = moment().add(1, "hours");
  if (moment(deliveryTime.value).isBefore(validTime, "minutes")) {
    errors.value.push(
      "Please allow at least one hour of preparation for Delivery Time. Thank you."
    );
  }
  if (!cart.client) {
    errors.value.push("Please add delivery address. Thank you.");
  }
  if (!files.value || files.value.length === 0) {
    errors.value.push(
      "Please add the file(s) before you place the order. Thank you."
    );
  } else if (files.value.some((item) => item.copies < 1)) {
    errors.value.push("Copies for each files must be at least 1");
  }
  if (errors.value.length > 0) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return;
  }
  uploading.value = true;
  await Promise.all(await uploadFileToS3());

  let cartData = new FormData();
  cartData.append("client", cart.client);
  cartData.append("remark", cart.remark);
  cartData.append("deliveryByCourier", isNotKampar.value);
  cartData.append("urgentOrder", cart.urgentOrder);
  cartData.append("deliveryTime", deliveryTime.value);
  cartData.append("paymentMethod", paymentMethod.value);

  const response = await api_call("/api/v1/carts/", {
    method: "post",
    data: cartData,
  });
  cartId.value = response.data.id;

  const headers = { "Content-Type": "undefined" };
  var file_upload_task = files.value.map((file) => {
    let formData = get_file_payload(file, cartId.value);
    return api_call("/api/v1/files/", {
      method: "post",
      data: formData,
      headers: headers,
      failed: (err) => {
        errors.value.push(file.filename + " " + err);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        uploading.value = false;
      },
    });
  });
  await Promise.all(file_upload_task);
  uploading.value = false;
  store.dispatch("cart/removeFiles");
  router.replace({
    name: "OrderDetails",
    params: { id: cartId.value },
  });
}

async function uploadFileToS3() {
  const fileArgs = (await getUploadUrl()).data;
  var file_upload_task = fileArgs.map(({ url, idx }) => {
    const curFile = files.value[idx];

    return api_call(url, {
      method: "put",
      data: curFile.file,
      headers: {
        "Content-Type": curFile.file.type,
      },
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        return data;
      },
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        curFile.uploadProgress = percentCompleted;
      },
      success: () => {
        curFile.fileUrl = `https://esprint.s3.ap-southeast-1.amazonaws.com/${curFile.key}`;
      },
      failed: (err) => {
        errors.value.push(
          curFile.filename +
            ": failed to upload this file, please try again later."
        );
        console.error("Error uploading file:", err);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        uploading.value = false;
        if (err.response) {
          console.error("Response Data:", err.response.data);
        }
      },
    });
  });

  return file_upload_task;
}

async function getUploadUrl() {
  const key = store.getters.userId + "/" + new Date().getTime() + "/";
  files.value = files.value.map((item) => {
    item.key = key + item.filename;
    return item;
  });
  const objects = files.value.map((file, idx) => ({
    key: file.key,
    type: file.file.type,
    idx: idx,
  }));
  const formData = {
    files: objects,
  };

  return api_call("/api/v1/files/get_upload_url/", {
    method: "post",
    data: formData,
    failed: (err) => {
      errors.value.push(err);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      uploading.value = false;
    },
  });
}
</script>
