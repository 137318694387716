<template>
  <div class="field">
    <slot name="label">
      <label class="label is-small mb-0">{{ label }}</label>
    </slot>
    <slot name="field">
      <div class="control is-expanded">
        <input class="input is-static pt-0" :value="value" readonly />
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  props: ["label", "value"],
};
</script>
