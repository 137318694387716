<template>
  <div class="box status-box">
    <div class="columns">
      <OrderStatusItem
        class="column"
        icon="fa-solid fa-file-circle-check"
        :active="isActive('PR')"
        activeText="Preparing"
        :inactiveText="status === 'CA'? 'Cancelled': 'Pending'"
        :showText="true"
      />

      <OrderStatusItem
        class="column"
        icon="fa-solid fa-box-open"
        :active="isActive('RP')"
        activeText="Ready to be picked up"
        inactiveText="Preparing the order"
        :showText="status === 'PR'"
      />

      <OrderStatusItem
        class="column"
        icon="fa-solid fa-truck-fast"
        :active="isActive('DE')"
        activeText="Delivery in progress"
        inactiveText="Waiting rider"
        :showText="status === 'RP'"
      />

      <OrderStatusItem
        class="column"
        icon="fa-solid fa-house-circle-check"
        :active="isActive('CO')"
        activeText="Delivered"
        inactiveText="Delivering"
        :showText="status === 'DE'"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from "vue";

import OrderStatusItem from "./OrderStatusItem.vue";
import { getStatusOrder } from "@/utils";

const props = defineProps({
  status: String,
});
const { status } = toRefs(props);

const isActive = (key) => {
  if (status.value === "CA") return false;
  return getStatusOrder(status.value) >= getStatusOrder(key);
};
</script>
