import store from "@/store";
import axios from "axios";
import moment from "moment";

export function make_random_password(length = 10) {
  const string = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numeric = "0123456789";
  const punctuation = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let character = "";
  let password = "";
  while (password.length < length) {
    const entity1 = Math.ceil(string.length * Math.random() * Math.random());
    const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
    const entity3 = Math.ceil(
      punctuation.length * Math.random() * Math.random()
    );
    let hold = string.charAt(entity1);
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
    character += hold;
    character += numeric.charAt(entity2);
    character += punctuation.charAt(entity3);
    password = character;
  }
  password = password
    .split("")
    .sort(() => {
      return 0.5 - Math.random();
    })
    .join("");
  return password.substr(0, length);
}

export function validate_password_complexity(password) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
    password
  );
}

export async function api_call(
  url,
  {
    method = "get",
    data = null,
    loading = true,
    headers = {},
    flash_message = true,
    ignore_token = false,
    success = null,
    failed = null,
    stopLoadingIfFailed = true,
    ...config
  } = {}
) {
  method = method.toLowerCase();
  if (loading) {
    store.dispatch("addLoading");
  }
  try {
    if (!ignore_token) {
      await store.dispatch("refreshToken");
    }
    const response = await axios[method](url, data, { headers, ...config });
    success && success(response);
    if (loading) {
      store.dispatch("decreaseLoading");
    }
    return response;
  } catch (error) {
    const errors = handle_response_error(error, flash_message);
    failed && failed(errors);
    if (loading && stopLoadingIfFailed) {
      store.dispatch("decreaseLoading");
    }
    throw errors;
  }
}

export function handle_response_error(error, flashMessage = false) {
  if (!error) {
    return [];
  }
  let error_list = [];
  console.error(error);
  // handle 404 error
  if (error.response && error.response.status === 404) {
    error_list.push("The requested resource was not found.");
  } else if (
    error.response &&
    error.response.data &&
    typeof error.response.data === "object"
  ) {
    if (Array.isArray(error.response.data)) {
      error_list = error.response.data;
    } else {
      for (let [key, value] of Object.entries(error.response.data)) {
        error_list.push(`${key}: ${value}`);
      }
    }
  }
  if (error_list.length === 0) {
    error_list.push("Oops! Something went wrong please try again later");
  }
  if (flashMessage) {
    store.dispatch("flashMessage", {
      type: "is-danger",
      content: error_list.join("<br/>"),
    });
  }

  return error_list;
}

export function isInsufficientAmount(error) {
  if (Array.isArray(error)) {
    return error.indexOf("amount: Insufficient balance in account") > -1;
  } else if (error instanceof String) {
    return error === "amount: Insufficient balance in account";
  }
  return false;
}

export const COUNTRY_CODE = ["+60"];

export function validate_mobile(mobile) {
  return /^(\+6)?(01[023456789](-)?\d{7}|01[1](-)?\d{8})$/.test(mobile);
}

export function validate_contact(contact) {
  return /^(\d{1,2})(-)?\d{6,8}$$/.test(contact);
  // return /^((\+60)|(0))(\d{1,2})(-)?\d{6,8}$$/.test(contact);
}

export function validate_contact_with_cc(contact) {
  return /^((\+60)|(0))(\d{1,2})(-)?\d{6,8}$$/.test(contact);
}

export function validate_ic(ic) {
  return /^\d{6}-\d{2}-\d{4}$/.test(ic);
}

export function validate_zipcode(zipcode) {
  return /^[0-9]{5}$/.test(zipcode);
}

export function validate_email(email) {
  if (email) {
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
      email.toLowerCase()
    );
  }
  return false;
}

export function addTrailingComma(str) {
  if (str) {
    var len = str.length;
    str += str.charAt(len - 1) != "," ? "," : "";
  }
  return str;
}

export function isOutsideKampar(obj) {
  if (!obj) {
    return true;
  }
  const result = `${addTrailingComma(obj.address1)} <br />
    ${addTrailingComma(obj.address2)}
    ${obj.address2 ? "<br />" : ""}
    ${obj.zipcode}
    ${addTrailingComma(obj.city)} <br />
    ${addTrailingComma(obj.state)}
    ${obj.country}`;

  return result.toLowerCase().indexOf("kampar") === -1;
}

export function formatAddress(obj) {
  if (!obj) {
    return "";
  }
  const result = `${addTrailingComma(obj.address1)} <br />
    ${addTrailingComma(obj.address2)}
    ${obj.address2 ? "<br />" : ""}
    ${obj.zipcode}
    ${addTrailingComma(obj.city)} <br />
    ${addTrailingComma(obj.state)}
    ${obj.country}`;

  if (result.toLowerCase().indexOf("kampar") === -1) {
    return `<mark style='background-color: rgba(247, 104, 166, .3)'>${result}</mark>`;
  }
  return result;
}

export function formatInlineAddress(obj) {
  if (!obj) {
    return "";
  }
  return `${addTrailingComma(obj.address1)}${
    obj.address2 ? " " + obj.address2 : ""
  } ${obj.zipcode} ${addTrailingComma(obj.city)} ${addTrailingComma(
    obj.state
  )} ${obj.country}`;
}

export function formatDateTime(datetime) {
  const date = moment(datetime, ["DD/MM/YYYY, HH:mm"]);
  if (date.isValid()) {
    return date.format("DD/MM/YYYY, HH:mm");
  } else {
    return datetime;
  }
}

export function getMomentObjectFromFormattedDate(datetime) {
  // datetime format - DD/MM/YYYY, HH:mm
  return moment()
    .dayOfYear(datetime.slice(0, 2))
    .month(datetime.slice(3, 5) - 1)
    .year(datetime.slice(6, 10))
    .hour(datetime.slice(12, 14))
    .minute(datetime.slice(15))
    .second(0);
}

export function formatMoney(amount, currency = "RM") {
  if (!amount) {
    console.warn("formatMoney: Invalid amount type");
    amount = "0.00";
  } else if (typeof amount === "number") {
    amount = amount.toFixed(2);
  } else if (typeof amount === "string") {
    amount = parseFloat(amount).toFixed(2);
  } else {
    console.warn("formatMoney: Invalid amount type");
    amount = "0.00";
  }
  // format currency
  if (currency) {
    return `${currency} ${amount}`;
  } else {
    return amount;
  }
}

export function hidMiddlePartOfEmail(email, allow = null) {
  if (!email) {
    return "";
  }
  const emailParts = email.split("@");
  allow = allow || Math.floor(emailParts[0].length / 2);
  const firstPart = emailParts[0];
  const lastPart = emailParts[1];
  const firstPartLength = firstPart.length;
  const lastPartLength = lastPart.length;
  const firstPartHidden =
    firstPart.substring(0, allow) + "".padStart(firstPartLength - allow, "*");
  const lastPartHidden =
    "".padStart(lastPartLength - allow, "*") +
    lastPart.substring(lastPartLength - allow);
  return firstPartHidden + "@" + lastPartHidden;
}

export const ORDER_STATUS = Object.freeze({
  PE: { text: "Pending", color: "is-danger", order: 1 },
  PR: { text: "Preparing", color: "is-warning", order: 2 },
  RP: { text: "Ready to Pick Up", color: "is-success", order: 3 },
  DE: { text: "Delivering", color: "is-info", order: 4 },
  CO: { text: "Completed", color: "is-success is-light", order: 5 },
  CA: { text: "Cancelled", color: "is-danger", order: -1 },
  // RF: { text: "Refunded", color: "is-info", order: -1 },
});

export function getStatus(key) {
  return ORDER_STATUS[key];
}

export function getStatusText(key) {
  const status = getStatus(key);
  return status ? status.text : "";
}

export function getStatusColor(key) {
  const status = getStatus(key);
  return status ? status.color : "";
}

export function getStatusOrder(key) {
  const status = getStatus(key);
  return status ? status.order : "";
}

export function parseStatus(status) {
  const statusKeys = Object.keys(ORDER_STATUS);
  return statusKeys.find((key) => ORDER_STATUS[key].text === status) || "";
}

export function getPendingPayment(order) {
  const { totalPrice, paidAmount } = order;
  const result = (parseFloat(totalPrice) || 0) - (parseFloat(paidAmount) || 0);
  return result;
}

export const PAYMENT_METHOD = Object.freeze({
  COD: "Cash On Delivery",
  EW: "E-Wallet",
  TNG: "Touch 'n Go",
});

export function getPaymentMethod(key) {
  return PAYMENT_METHOD[key] ? PAYMENT_METHOD[key] : "";
}

export const TRANSACTION_TYPE = Object.freeze({
  RR: "Referral Reward",
  PP: "Payment",
  RF: "Refund",
  TP: "Top Up",
});

export function getTransactionType(key) {
  return TRANSACTION_TYPE[key] ? TRANSACTION_TYPE[key] : "";
}

export function parseToken(token) {
  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
}

export const ROLE = Object.freeze({
  1: "admin",
  2: "merchant",
  3: "delivery",
  4: "client",
});

export function get_role_id(role) {
  return Object.keys(ROLE).find((key) => ROLE[key] === role) || 0;
}
export function get_role_name(key) {
  return ROLE[key] ? ROLE[key] : "";
}

export const FILE_SETTINGS = Object.freeze({
  copies: "Copies",
  paperSize: "Paper Size",
  style: "Style",
  pagesPerSheet: "Slides per Sheet",
  layout: "Layout",
  scale: "Scale",
  color: "Color",
  flipOn: "Flip On",
  pages: "Pages",
  pageOrder: "Page Order",
  binding: "Binding",
});

export const SUPPORT_EMAIL = "kpronlineprintingsystem@gmail.com";
export const SUPPORT_CONTACT = "0108942867";
