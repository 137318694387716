<template>
  <div class="page-add-client">
    <div class="section">
      <div class="column is-12">
        <BackButton />
        <h1 class="title has-text-centered is-size-3">Add Address</h1>
      </div>
      <div class="box">
        <div class="columns is-multiline is-desktop is-tablet is-mobile">
          <div class="column is-12">
            <h1 class="is-size-4 has-text-link">Contact Details:</h1>
          </div>

          <div class="column is-2-desktop is-3-tablet is-3-mobile">
            <label>
              Name: <span class="has-text-danger is-size-5">*</span>
            </label>
          </div>
          <div class="column is-4-desktop is-9-tablet is-9-mobile">
            <input
              class="input"
              type="text"
              :class="{
                'is-danger': errors.name.length > 0,
              }"
              v-model="client.name"
              placeholder="eg. Tan Ah Kaw"
            />
            <p
              v-for="(err, index) in errors.name"
              :key="index"
              class="help is-danger"
            >
              {{ err }}
            </p>
          </div>
          <div class="column is-2-desktop is-3-tablet is-3-mobile">
            <label>
              Contact Number: <span class="has-text-danger is-size-5">*</span>
            </label>
          </div>
          <div class="column is-4-desktop is-9-tablet is-9-mobile">
            <div class="field has-addons">
              <p class="control">
                <span class="select">
                  <select v-model="client.country_code">
                    <option v-for="cc in COUNTRY_CODE" :key="cc" :value="cc">
                      {{ cc }}
                    </option>
                  </select>
                </span>
              </p>
              <p class="control is-expanded">
                <input
                  class="input"
                  type="tel"
                  :class="{
                    'is-danger': errors.contact_num.length > 0,
                  }"
                  v-model="client.contact_num"
                  placeholder="eg. 161231234"
                />
              </p>
            </div>
            <p
              v-for="(err, index) in errors.contact_num"
              :key="index"
              class="help is-danger"
            >
              {{ err }}
            </p>
          </div>
          <!-- <div class="column is-2-desktop is-3-tablet is-3-mobile">
            <label>
              Email Address: <span class="has-text-danger is-size-5">*</span>
            </label>
          </div>
          <div class="column is-4-desktop is-9-tablet is-9-mobile">
            <input
              class="input"
              type="email"
              :class="{
                'is-danger': errors.email.length > 0,
              }"
              v-model="client.email"
            />
            <p
              v-for="(err, index) in errors.email"
              :key="index"
              class="help is-danger"
            >
              {{ err }}
            </p>
          </div> -->
          <div class="column is-12">
            <h1 class="is-size-4 has-text-link">Address Details:</h1>
          </div>
          <div class="column is-2-desktop is-3-tablet is-3-mobile">
            <label>
              Address Line 1:
              <span class="has-text-danger is-size-5">*</span></label
            >
          </div>
          <div class="column is-4-desktop is-9-tablet is-9-mobile">
            <input
              type="text"
              name="address1"
              class="input"
              :class="{
                'is-danger': errors.address1.length > 0,
              }"
              v-model="client.address1"
            />
            <p
              v-for="(err, index) in errors.address1"
              :key="index"
              class="help is-danger"
            >
              {{ err }}
            </p>
          </div>

          <div class="column is-2-desktop is-3-tablet is-3-mobile">
            <label>Address Line 2: </label>
          </div>
          <div class="column is-4-desktop is-9-tablet is-9-mobile">
            <input
              type="text"
              name="address2"
              class="input"
              v-model="client.address2"
            />
          </div>

          <div class="column is-2-desktop is-3-tablet is-3-mobile">
            <label>
              Zipcode: <span class="has-text-danger is-size-5">*</span>
            </label>
          </div>
          <div class="column is-4-desktop is-9-tablet is-9-mobile">
            <input
              type="text"
              name="zipcode"
              class="input"
              :class="{
                'is-danger': errors.zipcode.length > 0,
              }"
              v-model="client.zipcode"
            />
            <p
              v-for="(err, index) in errors.zipcode"
              :key="index"
              class="help is-danger"
            >
              {{ err }}
            </p>
          </div>

          <div class="column is-2-desktop is-3-tablet is-3-mobile">
            <label>
              City: <span class="has-text-danger is-size-5">*</span>
            </label>
          </div>
          <div class="column is-4-desktop is-9-tablet is-9-mobile">
            <input
              type="text"
              name="city"
              class="input"
              :class="{
                'is-danger': errors.city.length > 0,
              }"
              v-model="client.city"
            />
            <p
              v-for="(err, index) in errors.city"
              :key="index"
              class="help is-danger"
            >
              {{ err }}
            </p>
          </div>

          <div class="column is-2-desktop is-3-tablet is-3-mobile">
            <label>
              State: <span class="has-text-danger is-size-5">*</span>
            </label>
          </div>
          <div class="column is-4-desktop is-9-tablet is-9-mobile">
            <input
              type="text"
              name="state"
              class="input"
              :class="{
                'is-danger': errors.state.length > 0,
              }"
              v-model="client.state"
            />
            <p
              v-for="(err, index) in errors.state"
              :key="index"
              class="help is-danger"
            >
              {{ err }}
            </p>
          </div>

          <div class="column is-2-desktop is-3-tablet is-3-mobile">
            <label>
              Country: <span class="has-text-danger is-size-5">*</span>
            </label>
          </div>
          <div class="column is-4-desktop is-9-tablet is-9-mobile">
            <input
              type="text"
              name="country"
              class="input"
              :class="{
                'is-danger': errors.country.length > 0,
              }"
              v-model="client.country"
            />
            <p
              v-for="(err, index) in errors.country"
              :key="index"
              class="help is-danger"
            >
              {{ err }}
            </p>
          </div>
        </div>

        <div class="columns is-centered">
          <div class="column is-3">
            <div class="field">
              <div class="control">
                <button class="button is-info is-fullwidth" @click="addAddress">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import BackButton from "@/components/BackButton";
import {
  api_call,
  validate_zipcode,
  validate_contact,
  COUNTRY_CODE,
} from "@/utils";

const router = useRouter();
const store = useStore();

const client = reactive({
  name: "",
  country_code: COUNTRY_CODE[0],
  contact_num: "",
  email: "",
  address1: "",
  address2: "",
  zipcode: "",
  city: "",
  state: "",
  country: "",
});
const errors = reactive({
  name: [],
  contact_num: [],
  address1: [],
  zipcode: [],
  city: [],
  state: [],
  country: [],
});

const addAddress = async () => {
  if (valid()) {
    client.email = store.getters.user.email;
    api_call("/api/v1/clients/", {
      method: "POST",
      data: client,
      success() {
        router.push({ name: "MyAccount" });
      },
    });
  }
};

function valid() {
  errors.name = [];
  errors.contact_num = [];
  errors.address1 = [];
  errors.zipcode = [];
  errors.city = [];
  errors.state = [];
  errors.country = [];

  let valid = true;
  for (var key in errors) {
    if (!client[key]) {
      valid = false;
      errors[key].push(`this field cannot be empty`);
    }
  }
  if (!validate_zipcode(client.zipcode)) {
    errors.zipcode.push("zipcode format invalid");
    valid = false;
  }
  if (!validate_contact(client.contact_num)) {
    errors.contact_num.push("contact number format invalid");
    valid = false;
  }

  return valid;
}
</script>
