<template>
  <div class="page-balance" id="balance">
    <section class="section pb-5">
      <div class="container">
        <hoverable-tag
          :text="walletID"
          icon="fa-lg fa-solid fa-circle-info"
          color="is-grey"
        >
          Wallet ID
        </hoverable-tag>
        <h3 class="subtitle is-size-5">
          Dear {{ username }}, your available balance is
        </h3>
        <h1 class="title has-text-link is-size-2">
          {{ formatMoney(balance) }}
        </h1>
        <!-- <div class="columns is-centered has-text-centered">
          <div class="column">
            <div class="balance-box">
              <div class="columns">
                <div class="column">
                  <router-link to="/dashboard/balance/topup">
                    <div class="box">
                      <figure class="image is-inline-block">
                        <img
                          src="../../images/top_up.png"
                          alt="topup-icon"
                          class="image"
                        />
                      </figure>
                      <p>Top Up</p>
                    </div>
                  </router-link>
                </div>
                <div class="column">
                  <router-link to="/dashboard">
                    <div class="box">
                      <figure class="image is-inline-block">
                        <img
                          src="../../images/transfer.png"
                          alt="transfer-icon"
                          class="image"
                        />
                      </figure>
                      <p>Transfer</p>
                    </div>
                  </router-link>
                </div>
                <div class="column">
                  <router-link to="/dashboard">
                    <div class="box">
                      <figure class="image is-inline-block">
                        <img
                          src="../../images/payment_history.png"
                          alt="paymentHistory-icon"
                          class="image"
                        />
                      </figure>
                      <p>Payment History</p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </section>

    <section class="section pt-0">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p class="is-size-3 has-text-info mr-5">E-Wallet Transactions</p>
            </div>
          </div>
          <!-- <div class="level-right">
            <div class="level-item">
              <hoverable-tag
                text="Top Up"
                icon="fa-lg fa-solid fa-circle-info"
                color="is-info"
              >
                Please look for the nearest merchant to top up your e-wallet.
              </hoverable-tag>
            </div>
          </div> -->
          <!-- <div class="level-right">
            <div class="level-item">
              <router-link :to="{ name: 'TopUp' }" class="button is-link"
                >Top Up</router-link
              >
            </div>
          </div> -->
        </div>
        <hr class="black-line" />
        <errors-list :errors="errors" />
        <div class="message is-warning" v-if="transactions.length === 0">
          <div class="message-header">
            <p>No E-Wallet Transactions Found</p>
          </div>
          <div class="message-body">
            <p>You do not have any transaction yet</p>
          </div>
        </div>
        <transaction-item
          v-for="txn in transactions"
          :key="txn.id"
          :transaction="txn"
          :isPositive="txn.toAccount.id === walletID"
          :isNeutral="!txn.toAccount.id && !txn.fromAccount.id"
        />
        <div class="level level-right" v-if="transactions.length >= 30">
          <div class="level-item has-text-right">
            <p class="has-text-weight-light">
              only showing latest 30 transactions
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import ErrorsList from "@/components/ErrorsList";
import TransactionItem from "@/components/TransactionItem";
import HoverableTag from "@/components/HoverableTag";
import { formatMoney, api_call } from "@/utils";

const store = useStore();

const errors = ref([]);
const username = store.getters.user.username;
const balance = ref(0);
const walletID = ref("");
const transactions = ref([]);
onMounted(async () => {
  errors.value = [];
  await api_call(`/api/v1/ewalletaccounts/me/`, {
    success: (res) => {
      balance.value = res.data.balance;
      walletID.value = res.data.id;
      getTransactions();
    },
    failed: (error) => {
      errors.value = error;
    },
  });
});
const getTransactions = async (clean = true) => {
  if (clean) transactions.value = [];

  errors.value = [];
  let url = `/api/v1/ewalletaccounts/${walletID.value}/transactions/`;
  // url = clean ? url : transactionsNext.value || url;
  await api_call(url, {
    success: (response) => {
      // transactions.value = transactions.value.concat(response.data.results);
      // transactionsCount.value = response.data.count;
      // transactionsNext.value = response.data.next;
      transactions.value = response.data;
    },
    failed: (error) => {
      errors.value = error;
    },
  });
};
</script>
