<template>
  <div class="box">
    <div class="level">
      <div class="level-left">
        <div class="level-item has-text-info has-text-weight-bold">
          {{ file.filename }}
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a
            class="button is-link"
            v-if="allowDownload"
            :href="file.file"
            :download="file.filename"
            target="_blank"
          >
            <strong>Download File</strong>
          </a>
          <button class="button is-link" v-else disabled>
            <strong>Download File</strong>
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="columns is-multiline">
      <div
        class="column is-half-tablet is-one-third-desktop"
        v-for="key in Object.keys(FILE_SETTINGS)"
        :key="key"
      >
        <p class="has-text-grey-dark has-text-weight-bold">
          {{ FILE_SETTINGS[key] }}:
        </p>
        <p v-if="key === 'binding'">{{ file.binding ? "Yes" : "No" }}</p>
        <p v-else>{{ file[key] }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs, defineProps } from "vue";

import { FILE_SETTINGS } from "@/utils";

const props = defineProps(["file", "allowDownload"]);
const { file, allowDownload } = toRefs(props);
</script>
