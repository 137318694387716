import { createRouter, createWebHistory } from "vue-router";

// Customer
import Dashboard from "@/views/client/Dashboard.vue";
import MyAccount from "@/views/client/MyAccount.vue";
import SignUp from "@/views/client/SignUp.vue";
import SignIn from "@/views/shared/SignIn.vue";
import ForgotPassword from "@/views/client/ForgotPassword.vue";
import ResetPassword from "@/views/shared/ResetPassword.vue";
import ActivateAccount from "@/views/shared/ActivateAccount.vue";
import Client from "@/views/client/Client.vue";
import AddClient from "@/views/client/AddClient.vue";
import EditClient from "@/views/client/EditClient.vue";
import AddCart from "@/views/client/AddCart.vue";
import Carts from "@/views/client/Carts.vue";
import EditCart from "@/views/client/EditCart.vue";
import Feedback from "@/views/client/FeedbackForm.vue";
import Balance from "@/views/client/Balance.vue";
import TopUpInstruction from "@/views/client/TopUpInstruction.vue";
import OrderDetails from "@/views/client/OrderDetails.vue";
import OrderHistory from "@/views/client/OrderHistory.vue";
import MyReferrals from "@/views/client/MyReferrals.vue";

// Admin
import AdminDashboard from "@/views/admin/Dashboard.vue";
import SalesDashboard from "@/views/admin/SalesDashboard.vue";
import AdminOrders from "@/views/admin/Orders.vue";
import AdminOrderDetails from "@/views/admin/OrderDetails.vue";
import AdminTransactions from "@/views/admin/Transactions.vue";

import Customers from "@/views/admin/Customers.vue";
import ViewCustomer from "@/views/admin/ViewCustomer.vue";

import Merchants from "@/views/admin/Merchants.vue";
import EditMerchant from "@/views/admin/EditMerchant.vue";
import AddMerchant from "@/views/admin/AddMerchant.vue";

import Delivery from "@/views/admin/Delivery.vue";
import EditDelivery from "@/views/admin/EditDelivery.vue";
import AddDelivery from "@/views/admin/AddDelivery.vue";

import Admins from "@/views/admin/Admins.vue";

// Delivery
import DeliveryDashboard from "@/views/delivery/Dashboard.vue";
import DeliveryOrders from "@/views/delivery/Orders.vue";
import DeliveryOrderDetails from "@/views/delivery/OrderDetails.vue";
import DeliverySelectedOrders from "@/views/delivery/SelectedOrder.vue";
import DeliveryOrderHistory from "@/views/delivery/OrderHistory.vue";
import DeliveryMonthlyInvoices from "@/views/delivery/MonthlyInvoices.vue";
import DeliveryUpdateStatus from "@/views/delivery/UpdateStatus.vue";
import DeliverySetting from "@/views/delivery/Setting.vue";

// Merchant
import MerchantDashboard from "@/views/merchant/Dashboard.vue";
import MerchantOrders from "@/views/merchant/Orders.vue";
import MerchantOrderDetails from "@/views/merchant/OrderDetails.vue";
import MerchantSelectedOrders from "@/views/merchant/SelectedOrder.vue";
import MerchantOrderHistory from "@/views/merchant/OrderHistory.vue";
import MerchantDeliveryStatus from "@/views/merchant/DeliveryStatus.vue";
import MerchantSetting from "@/views/merchant/Setting.vue";
import MerchantReceipt from "@/views/merchant/Receipt.vue";
import MerchantMonthlyInvoices from "@/views/merchant/MonthlyInvoices.vue";
import ReferralProgram from "@/views/merchant/ReferralProgram.vue";
import CustomerTransaction from "@/views/merchant/CustomerTransaction.vue";

import store from "../store";

const routes = [
  {
    path: "/",
    name: "SignIn",
    component: SignIn,
    meta: {
      test: "Test",
    },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password/:uid/:token",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/activate-account/:uid/:token",
    name: "ActivateAccount",
    component: ActivateAccount,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/my-account",
    name: "MyAccount",
    component: MyAccount,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/cart/add",
    name: "AddCart",
    component: AddCart,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/carts",
    name: "Carts",
    component: Carts,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/carts/:id/edit",
    name: "EditCart",
    component: EditCart,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/clients/:id",
    name: "Client",
    component: Client,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/clients/add",
    name: "AddClient",
    component: AddClient,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/clients/:id/edit",
    name: "EditClient",
    component: EditClient,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/:id/feedback",
    name: "Feedback",
    component: Feedback,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/balance",
    name: "Balance",
    component: Balance,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/balance/topup",
    name: "TopUp",
    component: TopUpInstruction,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/:id/orderDetails",
    name: "OrderDetails",
    component: OrderDetails,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/orderHistory",
    name: "OrderHistory",
    component: OrderHistory,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/dashboard/myReferrals",
    name: "MyReferrals",
    component: MyReferrals,
    meta: {
      requiredLogin: true,
    },
  },

  // Admin
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/salesDashboard",
    name: "SalesDashboard",
    component: SalesDashboard,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/orders",
    name: "AdminOrders",
    component: AdminOrders,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/transactions",
    name: "AdminTransactions",
    component: AdminTransactions,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/:id/orderDetails",
    name: "AdminOrderDetails",
    component: AdminOrderDetails,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/customers",
    name: "Customers",
    component: Customers,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/customer/:id",
    name: "ViewCustomer",
    component: ViewCustomer,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/merchants",
    name: "Merchants",
    component: Merchants,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/merchant/:id",
    name: "ViewMerchant",
    component: EditMerchant,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/merchant/:id/edit",
    name: "EditMerchant",
    component: EditMerchant,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/merchant/add",
    name: "AddMerchant",
    component: AddMerchant,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/riders",
    name: "Delivery",
    component: Delivery,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/rider/:id",
    name: "ViewDelivery",
    component: EditDelivery,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/rider/:id/edit",
    name: "EditDelivery",
    component: EditDelivery,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/rider/add",
    name: "AddDelivery",
    component: AddDelivery,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/admin/dashboard/admins",
    name: "Admins",
    component: Admins,
    meta: {
      requiredLogin: true,
    },
  },

  // Merchant
  {
    path: "/merchant/dashboard",
    name: "MerchantDashboard",
    component: MerchantDashboard,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/merchant/orders",
    name: "MerchantOrders",
    component: MerchantOrders,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/merchant/:id/orderDetails",
    name: "MerchantOrderDetails",
    component: MerchantOrderDetails,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/merchant/selected/orders",
    name: "MerchantSelectedOrders",
    component: MerchantSelectedOrders,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/merchant/order/history",
    name: "MerchantOrderHistory",
    component: MerchantOrderHistory,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/merchant/:id/deliveryStatus",
    name: "MerchantDeliveryStatus",
    component: MerchantDeliveryStatus,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/merchant/setting",
    name: "MerchantSetting",
    component: MerchantSetting,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/merchant/:id/receipt",
    name: "MerchantReceipt",
    component: MerchantReceipt,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/merchant/monthly-invoices",
    name: "MerchantMonthlyInvoices",
    component: MerchantMonthlyInvoices,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/merchant/referral-program",
    name: "ReferralProgram",
    component: ReferralProgram,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/merchant/customer-transaction",
    name: "CustomerTransaction",
    component: CustomerTransaction,
    meta: {
      requiredLogin: true,
    },
  },

  // Delivery
  {
    path: "/delivery/orders",
    name: "DeliveryOrders",
    component: DeliveryOrders,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/delivery/selected/orders",
    name: "DeliverySelectedOrders",
    component: DeliverySelectedOrders,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/delivery/order/history",
    name: "DeliveryOrderHistory",
    component: DeliveryOrderHistory,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/delivery/:id/orderDetails",
    name: "DeliveryOrderDetails",
    component: DeliveryOrderDetails,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/delivery/monthly-invoices",
    name: "DeliveryMonthlyInvoices",
    component: DeliveryMonthlyInvoices,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/delivery/:id/updateStatus",
    name: "DeliveryUpdateStatus",
    component: DeliveryUpdateStatus,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/delivery/dashboard",
    name: "DeliveryDashboard",
    component: DeliveryDashboard,
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: "/delivery/setting",
    name: "DeliverySetting",
    component: DeliverySetting,
    meta: {
      requiredLogin: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiredLogin) &&
    !store.getters.isAuthenticated
  ) {
    next("/");
  } else {
    next();
  }
});
export default router;
