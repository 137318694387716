<template>
  <section class="section pb-5">
    <div class="level">
      <div class="level-left">
        <back-button />
        <p class="title is-size-4 has-text-info">Customer's Transactions</p>
      </div>
    </div>
    <hr />
    <div class="container">
      <div class="field">
        <div class="control">
          <div class="field is-grouped">
            <div class="control is-expanded">
              <input
                class="input"
                type="text"
                placeholder="search by username, email, referral code or e-wallet id"
                v-model="query"
              />
            </div>
            <div class="control">
              <button class="button is-info" @click="searchCustomers(true)">
                <span>Search</span>
                <span class="icon">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
        <p class="help is-info">
          search by username, email, referral code or e-wallet id
        </p>
      </div>
      <errors-list :errors="errors" />
    </div>
  </section>
  <section class="section pt-0" v-if="searched">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h1 class="title is-size-4" v-if="customer">Selected Customer</h1>
          <h1 class="title is-size-4" v-else>Customers</h1>
        </div>
        <div class="column is-12">
          <div v-if="customers.length > 0">
            <div
              class="box is-clickable"
              v-for="cust in customers"
              :key="cust.id"
              @click="selectCustomer(cust)"
            >
              <div class="level mb-3">
                <div class="level-left">
                  <div class="level-item">
                    <p class="title has-text-info is-size-4">
                      {{ cust.username }}
                    </p>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <hoverable-tag
                      v-if="cust.ewallet_account"
                      :text="cust.ewallet_account.id"
                      icon="fa-lg fa-solid fa-circle-info"
                      color="is-grey"
                    >
                      Wallet ID
                    </hoverable-tag>
                  </div>
                </div>
              </div>
              <div class="level is-mobile">
                <div class="level-left">
                  <div class="level-item">
                    <div class="columns is-multiline">
                      <div class="column is-4 pb-0">
                        <p>Email:</p>
                      </div>
                      <div class="column is-8 pb-0">
                        <p>
                          <strong>{{ cust.email }}</strong>
                        </p>
                      </div>
                      <div class="column is-4 pt-0">
                        <p>Referral Code:</p>
                      </div>
                      <div class="column is-8 pt-0" v-if="cust.referral">
                        <p>
                          <strong>{{ cust.referral.referral_code }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item" v-if="cust.ewallet_account">
                    <h1 class="title has-text-link is-size-2">
                      {{ formatMoney(cust.ewallet_account.balance, "RM") }}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <LoadMoreButton
              v-if="!customer"
              :fetched-count="customers.length"
              :total-count="customersCount"
              :next="customersNext"
              @load-more="searchCustomers(false)"
            />
          </div>
          <div class="message is-warning" v-else>
            <div class="message-header"><p>No Customer Found</p></div>
            <div class="message-body">
              <p>
                Please enter a valid email or referral code to search for a
                customer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section pt-0" v-if="searched && customer">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-6 pb-0">
          <h1 class="title is-size-4">E-Wallet Transactions</h1>
        </div>
        <div class="column is-6 pb-0 has-text-right">
          <button class="button is-link mr-5" @click="toggleModal(true)">
            <span>Top Up</span>
            <span class="icon is-small">
              <i class="fa-solid fa-circle-dollar-to-slot"></i>
            </span>
          </button>
        </div>
        <!-- transactions list -->
        <div class="column is-12">
          <div class="message is-warning" v-if="transactions.length === 0">
            <div class="message-header">
              <p>No E-Wallet Transactions Found</p>
            </div>
            <div class="message-body">
              <p>Customer do not have any transaction yet</p>
            </div>
          </div>
          <div v-else>
            <div class="box" v-for="txn in transactions" :key="txn.id">
              <div class="level">
                <div class="level-left">
                  <div class="level-item">
                    <div>
                      <p class="heading">
                        {{ TRANSACTION_TYPE[txn.transactionType] }}
                      </p>
                      <p class="title is-size-4">
                        {{
                          txn.details || TRANSACTION_TYPE[txn.transactionType]
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <div>
                      <h3
                        class="title is-size-4 has-text-link mb-1"
                        v-if="txn.toAccount.id === customer.ewallet_account.id"
                      >
                        + {{ formatMoney(txn.amount, "RM") }}
                      </h3>
                      <h3
                        class="title is-size-4 has-text-grey mb-1"
                        v-else-if="
                          txn.fromAccount.id === customer.ewallet_account.id
                        "
                      >
                        - {{ formatMoney(txn.amount, "RM") }}
                      </h3>
                      <p class="heading mb-0">
                        {{ formatDateTime(txn.createdAt) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="level level-right" v-if="transactions.length >= 30">
              <div class="level-item has-text-right">
                <p class="has-text-weight-light">
                  only showing latest 30 transactions
                </p>
              </div>
            </div>
          </div>
          <!-- <LoadMoreButton
            :fetched-count="transactions.length"
            :total-count="transactionsCount"
            :next="transactionsNext"
            @load-more="getTransactions(false)"
          /> -->
        </div>
      </div>
    </div>
  </section>

  <add-transaction-modal
    @cancel="toggleModal(false)"
    @submit="topup"
    transaction-type="Top Up"
    :show="showModal"
    :awaitingConfirmation="awaitingConfirmation"
  />
</template>

<script setup>
import { ref } from "vue";

import {
  api_call,
  formatMoney,
  TRANSACTION_TYPE,
  formatDateTime,
} from "@/utils";

import BackButton from "@/components/BackButton";
import ErrorsList from "@/components/ErrorsList";
import LoadMoreButton from "@/components/LoadMoreButton";
import HoverableTag from "@/components/HoverableTag";
import AddTransactionModal from "@/components/merchant/AddTransactionModal";

const query = ref("");
const searched = ref(false);
const errors = ref([]);

const customers = ref([]);
const customersCount = ref(0);
const customersNext = ref(null);
const searchCustomers = async (clean = true) => {
  if (!query.value) return;
  if (clean) customers.value = [];
  searched.value = true;
  errors.value = [];
  transactions.value = [];
  customer.value = null;

  let url = `/api/v1/customers/?query=${query.value}`;
  url = clean ? url : customersNext.value || url;
  await api_call(url, {
    success: (response) => {
      if (response.data.count > 0) {
        customers.value = customers.value.concat(response.data.results);
        customersCount.value = response.data.count;
        customersNext.value = response.data.next;
      }
    },
    failed: (error) => {
      errors.value = error;
    },
  });
};

const customer = ref(null);
const selectCustomer = (cust) => {
  customer.value = cust;
  searched.value = true;
  customers.value = [cust];
  getTransactions();
};
const transactions = ref([]);
// const transactionsCount = ref(0);
// const transactionsNext = ref(null);
const getTransactions = async (clean = true) => {
  if (!customer.value) return;
  if (clean) transactions.value = [];

  errors.value = [];
  let url = `/api/v1/ewalletaccounts/${customer.value.ewallet_account.id}/transactions/`;
  // url = clean ? url : transactionsNext.value || url;
  await api_call(url, {
    success: (response) => {
      // transactions.value = transactions.value.concat(response.data.results);
      // transactionsCount.value = response.data.count;
      // transactionsNext.value = response.data.next;
      transactions.value = response.data;
    },
    failed: (error) => {
      errors.value = error;
    },
  });
};

const showModal = ref(false);
const awaitingConfirmation = ref(false);
const toggleModal = (show) => {
  showModal.value = show;
  awaitingConfirmation.value = false;
};
const topup = async (amount, confirmed) => {
  awaitingConfirmation.value = true;
  if (confirmed) {
    errors.value = [];

    const ewallet_account = customer.value.ewallet_account.id;
    await api_call(`/api/v1/transactions/topup/`, {
      method: "POST",
      data: { amount, ewallet_account },
      success: async () => {
        await getSingleCustomer(customer.value.id);
        getTransactions();
      },
      failed: (error) => {
        errors.value = error;
      },
    });
    toggleModal(false);
  }
};
const getSingleCustomer = async (id) => {
  await api_call(`/api/v1/customers/${id}/`, {
    success: (response) => {
      customers.value = [response.data];
      customer.value = response.data;
    },
    failed: (error) => {
      errors.value = error;
    },
  });
};
</script>

<style lang="scss" scoped>
@import "mybulma/sass/mystyles.scss";

.box.is-clickable:hover {
  background-color: $info-light;
}
</style>
