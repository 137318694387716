<template>
  <div class="notification is-light text-center toast">
    <button class="delete" @click="closeHandler"></button>
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
  props: ["type", "content"],
  emits: ["onClose"],
  methods: {
    closeHandler() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.toast {
  min-width: 250px;
  max-width: 400px;
  margin-bottom: 10px;
  z-index: 41;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>
