<template>
  <div class="admin-dashboard" id="dashboard">
    <div class="title-section has-text-centered">
      <div class="column is-full">
        <h1 class="title is-4">Welcome to Online Printing System</h1>
      </div>
    </div>
    <div class="container menu-section has-text-centered">
      <div class="columns">
        <DashboardTile
          class="column"
          :to="{ name: 'AdminOrders' }"
          icon="fa-solid fa-cart-arrow-down"
          text="Order List"
        />

        <DashboardTile
          class="column"
          :to="{ name: 'AdminTransactions' }"
          icon="fa-solid fa-circle-dollar-to-slot"
          text="Transactions Record"
        />

        <DashboardTile
          class="column"
          :to="{ name: 'SalesDashboard' }"
          icon="fa-solid fa-chart-line"
          text="Sales"
        />
      </div>

      <div class="columns">
        <DashboardTile
          class="column"
          :to="{ name: 'Customers' }"
          icon="fa-solid fa-users-line"
          text="Customer List"
        />

        <DashboardTile
          class="column"
          :to="{ name: 'Merchants' }"
          icon="fa-solid fa-store"
          text="Merchant List"
        />

        <DashboardTile
          class="column"
          :to="{ name: 'Delivery' }"
          icon="fa-solid fa-truck-fast"
          text="Rider List"
        />

        <DashboardTile
          class="column"
          :to="{ name: 'Admins' }"
          icon="fa-regular fa-id-card"
          text="Admin List"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardTile from "@/components/DashboardTile";
export default {
  components: { DashboardTile },
};
</script>
