<template>
  <Teleport to="#modal-placeholder">
    <div class="modal" :class="{ 'is-active': show }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <div class="my-4" v-for="(item, index) in files" :key="index">
            <p>
              <strong> {{ item.filename }} </strong>
            </p>
            <progress
              class="progress is-primary"
              :value="item.uploadProgress"
              max="100"
            >
              {{ item.uploadProgress }} %
            </progress>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="close"
      ></button>
    </div>
  </Teleport>
</template>

<script setup>
import { toRefs, defineProps, defineEmits } from "vue";

const props = defineProps({
  show: { type: Boolean, default: false },
  files: { type: [], default: [] },
});
const { show, files } = toRefs(props);
const emits = defineEmits(["close"]);
const close = () => {
  emits("close");
};
</script>
