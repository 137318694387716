<template>
  <div class="dropdown is-right" :class="isActive">
    <div class="dropdown-trigger">
      <span
        :class="'tag has-addons is-clickable ' + color"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
      >
        <span v-if="text">{{ text }}</span>
        <span class="icon">
          <i :class="icon"></i>
        </span>
      </span>
    </div>
    <div
      class="dropdown-menu"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <div class="dropdown-content">
        <div class="dropdown-item">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HoverableTag",
  props: {
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "is-success",
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    isActive: function () {
      return { "is-active": this.hover };
    },
  },
};
</script>
