import { api_call } from "@/utils";

var connections = [];

export default {
  namespaced: true,
  state() {
    return {
      notifications: [],
    };
  },
  mutations: {
    setNotifications(state, payload) {
      state.notifications = payload;
    },
    updateNotification(state, payload) {
      const index = state.notifications.findIndex(
        (notification) => notification.id === payload.id
      );
      state.notifications[index] = payload;
    },
    addNotification(state, payload) {
      state.notifications.unshift(payload);
    },
    addOrUpdateNotification(state, payload) {
      const index = state.notifications.findIndex(
        (notification) => notification.id === payload.id
      );
      if (index === -1) {
        state.notifications.unshift(payload);
      } else {
        state.notifications[index] = payload;
      }
      if (state.notifications.length > 5) {
        state.notifications.pop();
      }
    },
  },
  actions: {
    async getNotifications(context) {
      const response = await api_call("/api/v1/notifications/me/");
      context.commit("setNotifications", response.data);
    },
    async readNotification(context, notification) {
      const payload = {
        ...notification,
        read: true,
      };
      api_call(`/api/v1/notifications/${notification.id}/read/`, {
        method: "post",
        success() {
          context.commit("updateNotification", payload);
        },
      });
    },
    async connectSocket(context, payload) {
      if (payload) {
        await context.dispatch("refreshToken", null, { root: true });
        const connection = new WebSocket(
          `wss://${process.env.VUE_APP_BACKEND_URL}:8000/ws/notifications/${payload}/`
        );

        connection.onopen = function () {
          console.log("Websocket connected");
        };

        connection.onmessage = function (e) {
          var data = JSON.parse(e.data);
          // data.modifiedAt = moment
          //   .utc(data.modifiedAt)
          //   .format("dd/MM/yyyy HH:mm");
          context.dispatch(
            "flashMessage",
            {
              type: "is-info",
              content: "You have a new notification",
            },
            { root: true }
          );
          context.commit("addOrUpdateNotification", data);
        };

        connection.onerror = function () {
          console.log("Error in websocket");
        };

        connection.onclose = function () {
          console.log("Websocket closed");
        };

        connections.push(connection);
      }
    },
    async disconnectSocket() {
      connections.forEach((connection) => {
        connection.close();
      });
    },
  },
  getters: {
    notifications(state) {
      return state.notifications;
    },
    unreadCount(state) {
      return state.notifications.filter((item) => !item.read).length;
    },
  },
};
