<template>
  <div class="box">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <div>
            <p class="heading">
              {{ TRANSACTION_TYPE[transaction.transactionType] }}
            </p>
            <p class="title is-size-4">
              {{
                transaction.details ||
                TRANSACTION_TYPE[transaction.transactionType]
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div>
            <h3 class="title is-size-4 has-text-link mb-1" v-if="isPositive">
              + {{ formatMoney(transaction.amount, "RM") }}
            </h3>
            <h3 class="title is-size-4 has-text-grey mb-1" v-else>
              <span v-if="!isNeutral">-</span>
              {{ formatMoney(transaction.amount, "RM") }}
            </h3>
            <p class="heading mb-0">
              {{ formatDateTime(transaction.createdAt) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from "vue";

import { formatMoney, TRANSACTION_TYPE, formatDateTime } from "@/utils";

const props = defineProps({
  transaction: {
    type: Object,
    default: null,
  },
  isPositive: {
    type: Boolean,
    default: true,
  },
  isNeutral: {
    type: Boolean,
    default: true,
  },
});
const { transaction, isPositive } = toRefs(props);
</script>
