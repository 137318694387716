<template>
  <section class="section">
    <div class="columns">
      <div class="column is-offset-3 is-6">
        <div class="box">
          <div class="columns is-multiline is-desktop is-tablet is-mobile">
            <div class="column is-12">
              <p class="subtitle has-text-link is-size-3">
                Receipt # {{ order.id }}
              </p>
            </div>
            <div class="column is-5">
              <p class="is-size-5">Total Price:</p>
            </div>
            <div class="column is-7">
              <p class="is-size-5">RM {{ order.totalPrice }}</p>
            </div>
            <div class="column is-5">
              <p class="is-size-5">- Commision(10%):</p>
            </div>
            <div class="column is-7">
              <p class="is-size-5">RM {{ commission }}</p>
            </div>
            <div class="column is-5">
              <p class="is-size-5">Amount Received:</p>
            </div>
            <div class="column is-7">
              <p class="is-size-5">RM {{ amountReceived }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

import { api_call } from "@/utils";

const route = useRoute();

const order = ref("");
const commission = ref("");
const amountReceived = ref("");

onMounted(() => {
  let orderIndex = route.params.id;
  api_call("/api/v1/carts", {
    success(response) {
      response.data.forEach((item) => {
        if (item.id == orderIndex) {
          order.value = item;
          commission.value = (item.totalPrice * 0.1).toFixed(2);
          amountReceived.value = (item.totalPrice * 0.9).toFixed(2);
        }
      });
    },
  });
});
</script>
