<template>
  <div class="container">
    <section class="section">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <back-button />
            <p class="title has-text-link is-size-4">Monthly Invoices</p>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <label for="status" class="label mr-3">Year: </label>
            <div class="control">
              <div class="select is-small">
                <select v-model="year" @change="getInvoices">
                  <option v-for="y in YEARS" :key="y" :value="y">
                    {{ y }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="table-container">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th class="has-text-centered">Year</th>
            <th>Month</th>
            <th width="220px" class="has-text-right">Total Orders Delivered</th>
            <th width="200px" class="has-text-right">Total Orders Price</th>
            <th width="120px" class="has-text-centered">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="inv in invoices" :key="inv.month">
            <td class="has-text-centered">{{ inv.year }}</td>
            <td>
              {{ moment(inv.month, "M").format("MMMM") }}
            </td>
            <td class="has-text-right">{{ inv.count }}</td>
            <td class="has-text-right">
              {{ formatMoney(inv.sum) }}
            </td>
            <td class="has-text-centered">
              <button
                class="button is-link is-small"
                @click="downloadInvoice(inv.month)"
              >
                <span class="icon is-small">
                  <i class="fas fa-download"></i>
                </span>
                <span>Download</span>
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="has-text-right" colspan="2">Total:</th>
            <th class="has-text-right">{{ totalCount }}</th>
            <th class="has-text-right">{{ formatMoney(totalSum) }}</th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import moment from "moment";

import BackButton from "@/components/BackButton";
import { api_call, formatMoney } from "@/utils";
const fileDownload = require("js-file-download");

// get recent 5 years
const YEARS = [];
for (let i = 0; i < 5; i++) {
  YEARS.push(moment().subtract(i, "years").format("YYYY"));
}

const year = ref(2022);
const invoices = ref([]);
const getInvoices = () => {
  api_call(`/api/v1/carts/monthly_invoices/?year=${year.value}`, {
    success: (res) => {
      invoices.value = res.data;
    },
  });
};
onMounted(() => {
  getInvoices();
});

const totalCount = computed(() => {
  return invoices.value.reduce((acc, inv) => {
    return acc + inv.count;
  }, 0);
});
const totalSum = computed(() => {
  return invoices.value.reduce((acc, inv) => {
    return acc + inv.sum;
  }, 0);
});

const downloadInvoice = (month) => {
  api_call(
    `/api/v1/carts/delivery_invoice_download/?year=${year.value}&month=${month}`,
    {
      data: {
        responseType: "blob",
      },
      success(res) {
        const filename = res.headers["content-disposition"]
          .split("filename=")[1]
          .replaceAll('"', "");
        fileDownload(res.data, filename);
      },
    }
  );
};
</script>
