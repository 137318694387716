<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <BackButton />
        <p class="title is-size-4 has-text-info">Order History</p>
      </div>
    </div>
    <hr />

    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-full">
        <div class="is-flex is-flex-wrap-wrap">
          <div class="field mr-5">
            <label for="id" class="label">Order ID</label>
            <div class="control">
              <input
                type="text"
                name="id"
                v-model="filters.id"
                class="input is-small"
                placeholder="Order ID without #"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="status" class="label">Status</label>
            <div class="control">
              <div class="select is-small">
                <select name="status" v-model="filters.status">
                  <option></option>
                  <option
                    v-for="key in Object.keys(ORDER_STATUS)"
                    :key="key"
                    :value="key"
                  >
                    {{ ORDER_STATUS[key].text }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field mr-5">
            <label for="order_start" class="label">Order Date After</label>
            <div class="control">
              <input
                type="date"
                name="order_start"
                v-model="filters.order_start"
                class="input is-small"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="order_end" class="label">Order Date Before</label>
            <div class="control">
              <input
                type="date"
                name="order_end"
                v-model="filters.order_end"
                class="input is-small"
              />
            </div>
          </div>

          <!-- <div class="field mr-5">
            <label for="merchant" class="label">Merchant</label>
            <div class="control">
              <input
                type="text"
                name="merchant"
                v-model="filters.merchant"
                class="input is-small"
                placeholder="Merchant"
              />
            </div>
          </div> -->

          <div class="field mr-5">
            <label for="remark" class="label">Remark</label>
            <div class="control">
              <input
                type="text"
                name="remark"
                v-model="filters.remark"
                class="input is-small"
                placeholder="Remark"
              />
            </div>
          </div>

          <div class="field ml-auto mr-5 is-align-self-center">
            <div class="control">
              <button class="button is-link" @click="searchOrders(null, true)">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="column">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in errors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
      <div class="message is-info">
        <div class="message-body py-1">
          You may cancel your order within 10 minutes after you have placed the
          order, or contact support for orders after 10-minutes mark.
        </div>
      </div>
    </div>

    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('id')"
                label="Order ID"
                field="id"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Status</th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('createdAt')"
                label="Order Time"
                field="createdAt"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('deliveryTime')"
                label="Delivery TIme"
                field="deliveryTime"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <!-- <th>
              <TableHeaderOrder
                @onClick="orderBy('merchant__name')"
                label="Merchant"
                field="merchant__name"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('delivery__name')"
                label="Delivered by"
                field="delivery__name"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th> -->
            <th>
              <TableHeaderOrder
                @onClick="orderBy('remark')"
                label="Remark"
                field="remark"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('totalPrice')"
                label="Price"
                field="totalPrice"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders" :key="order.id">
            <th># {{ order.id }}</th>
            <td>
              <span class="tag" :class="colorClass(order.status)">
                {{ statusText(order.status) }}
              </span>
            </td>
            <td>{{ formatDateTime(order.createdAt) }}</td>
            <td>{{ formatDateTime(order.deliveryTime) }}</td>
            <!-- <td>
              <MerchantDetailPopup
                :merchant="order.merchant"
                v-if="order.merchant"
              />
            </td>
            <td>
              <RiderDetailPopup :rider="order.delivery" v-if="order.delivery" />
            </td> -->
            <td>{{ order.remark }}</td>
            <td>
              <span v-if="order.totalPrice">RM {{ order.totalPrice }}</span>
              <span v-else>TBD</span>
            </td>
            <td>
              <div class="buttons">
                <router-link
                  class="button is-small has-text-link"
                  :to="{ name: 'OrderDetails', params: { id: order.id } }"
                >
                  <span class="icon is-small">
                    <i class="far fa-folder-open"></i>
                  </span>
                  <span>View Details</span>
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <load-more-button
      :fetched-count="orders.length"
      :total-count="count"
      :next="next"
      @load-more="loadMore"
    />
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";

import TableHeaderOrder from "@/components/TableHeaderOrder";
import BackButton from "@/components/BackButton";
// import MerchantDetailPopup from "@/components/MerchantDetailPopup";
// import RiderDetailPopup from "@/components/RiderDetailPopup";
import LoadMoreButton from "@/components/LoadMoreButton";
import {
  getStatusText as statusText,
  getStatusColor as colorClass,
  api_call,
  formatDateTime,
  ORDER_STATUS,
} from "@/utils";

const orders = ref([]);
const count = ref(0);
const next = ref(null);
const previous = ref(null);
const filters = reactive({
  id: "",
  order_start: "",
  order_end: "",
  deliveryTime: "",
  status: "",
  merchant: "",
  remark: "",
  ordering: "-createdAt",
});
const errors = ref([]);
const ascending = ref(false);

const searchOrders = async (url = undefined, clean = true) => {
  errors.value = [];
  if (clean) {
    orders.value = [];
  }
  var query = "";
  var filter_list = [];
  for (var key in filters) {
    var value = filters[key];
    if (value) {
      if (key == "id") {
        let temp = parseInt(value.slice(value.indexOf("#") + 1));
        filter_list.push(`${key}=${temp}`);
      } else {
        filter_list.push(`${key}=${value}`);
      }
    }
  }

  if (filter_list.length > 0) {
    query = "?" + filter_list.join("&");
  }

  url = url && url.substr(url.indexOf("/api"));
  api_call(url || `/api/v1/carts/${query}`, {
    success(response) {
      orders.value = orders.value.concat(response.data.results);
      count.value = response.data.count;
      next.value = response.data.next;
      previous.value = response.data.previous;
    },
    failed(error) {
      errors.value = error;
    },
  });
};
onMounted(() => {
  searchOrders();
});

function loadMore() {
  searchOrders(next.value, false);
}
function orderBy(field) {
  ascending.value = !ascending.value;
  filters.ordering = ascending.value ? field : "-" + field;
  searchOrders();
}
</script>
