<template>
  <div class="page-login">
    <!-- <section class="section is-medium">
      <div class="has-text-centered">
        <figure class="image is-128x128 is-inline-block round-image">
          <img src="@/images/logo.png" alt="logo" />
        </figure>
        <p class="title has-text-info is-size-5 mt-5">
          E-Print Online Printing System
        </p>
        <p class="subtitle is-size-6">
          Slogan...Slogan...Slogan...Slogan...Slogan...
        </p>
      </div>
    </section> -->

    <section class="section is-medium">
      <div
        class="columns is-vcentered is-mobile is-desktop is-tablet is-multiline"
      >
        <div
          class="
            column
            is-full-desktop is-full-mobile is-full-tablet
            has-text-centered
          "
        >
          <p class="title cursive is-size-2 has-text-info">SIGN IN To Print</p>
        </div>

        <div class="column is-3-desktop"></div>
        <div class="column is-half-desktop is-full-mobile is-full-tablet">
          <div class="box p-5">
            <div class="columns is-mobile is-desktop is-tablet is-multiline">
              <div class="column is-full">
                <h1 class="title is-size-4 has-text-centered">
                  Sign in to get started.
                </h1>
              </div>

              <div class="column is-4-desktop is-full-mobile is-4-tablet">
                <div class="is-inline-flex sign-in">
                  <figure class="image is-32x32 is-inline-block mr-5">
                    <img src="@/images/profile_blue.png" alt="profile" />
                  </figure>
                  <p>Username:</p>
                </div>
              </div>
              <div class="column is-8-desktop is-full-mobile is-8-tablet">
                <div class="control">
                  <input
                    type="text"
                    name="username"
                    class="input"
                    placeholder="Username"
                    v-model="username"
                    size="50"
                  />
                </div>
              </div>

              <div class="column is-4-desktop is-full-mobile is-4-tablet">
                <div class="field is-horizontal">
                  <div class="is-inline-flex sign-in">
                    <span>
                      <figure class="image is-inline-block is-32x32 mr-5">
                        <img src="@/images/password_blue.png" alt="password" />
                      </figure>
                    </span>

                    <p>Password:</p>
                  </div>
                </div>
              </div>
              <div class="column is-8-desktop is-full-mobile is-8-tablet">
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <input
                      v-if="showPassword"
                      type="text"
                      class="input"
                      placeholder="Password"
                      v-model="password"
                      size="50"
                    />
                    <input
                      v-else
                      type="password"
                      class="input"
                      placeholder="Password"
                      v-model="password"
                      size="50"
                    />
                  </div>
                  <div class="controls">
                    <a class="icon is-large is-right" @click="toggleShow">
                      <i
                        class="fa"
                        :class="{
                          'fa-eye-slash': !showPassword,
                          'fa-eye': showPassword,
                        }"
                      ></i>
                    </a>
                  </div>
                </div>

                <div class="notification is-danger" v-if="errors.length">
                  <p v-for="error in errors" v-bind:key="error">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div class="column is-full">
                <div class="field has-text-centered">
                  <div class="control">
                    <button class="button is-info" @click="submitForm">
                      <strong>Sign In</strong>
                    </button>
                  </div>
                </div>
              </div>

              <div class="column is-full">
                <div class="field has-text-centered">
                  <div class="is-flex is-justify-content-center">
                    <img
                      :src="google_button_image"
                      alt="Sign in with Google"
                      class="image is-clickable"
                      @mouseenter="changeGoogleBtnImage('mouseenter')"
                      @mouseleave="changeGoogleBtnImage('mouseleave')"
                      @mousedown="changeGoogleBtnImage('mousedown')"
                      @mouseup="changeGoogleBtnImage('mouseup')"
                      @click="googleSignin"
                    />
                  </div>
                </div>
              </div>

              <div class="column is-full">
                <div class="has-text-centered">
                  <router-link to="/forgotpassword" class="has-text-black"
                    >Forgot Password
                  </router-link>
                  <strong> | </strong>
                  <router-link to="/sign-up" class="has-text-info">
                    Sign Up here</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { handle_response_error, api_call } from "@/utils.js";

export default {
  name: "SignIn",
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
      errors: [],
      google_button_image: require("@/images/google/btn_google_signin_light_normal_web.png"),
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  async created() {
    const { state, code } = this.$route.query;
    // const payload = this.$route.fullPath.substr(2);
    if (!!state && !!code) {
      this.$store.dispatch("addLoading");
      try {
        const response = await axios.post(
          `/api/v1/auth/o/google-oauth2/?state=${state}&code=${code}`,
          { state, code },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        this.$store.dispatch("setToken", response.data);
        // await axios.post(`/api/v1/users/initiate_role/`, { role: "client" });
        await this.$store.dispatch("loadUser");
        this.redirectUser();
      } catch (error) {
        if (error.response.data.code === "user_role_existed") {
          await this.$store.dispatch("loadUser");
          this.redirectUser();
        } else {
          this.errors = handle_response_error(error);
          this.$router.replace("/");
        }
      } finally {
        this.$store.dispatch("decreaseLoading");
      }
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    changeGoogleBtnImage(event) {
      let mode = "normal";
      if (event === "mousedown") {
        mode = "pressed";
      } else if (event === "mouseenter" || event === "mouseup") {
        mode = "focus";
      }
      this.google_button_image = require(`@/images/google/btn_google_signin_light_${mode}_web.png`);
    },
    async googleSignin() {
      this.$store.dispatch("invalidateToken");
      const params = {
        redirect_uri: window.location.origin,
      };
      api_call("/api/v1/auth/o/google-oauth2/", {
        data: { params },
        ignore_token: true,
        failed: (error) => {
          this.errors = error;
        },
        success: (response) => {
          window.location.href = response.data.authorization_url;
        },
      });
    },
    async submitForm() {
      try {
        this.$store.dispatch("invalidateToken");

        const formData = {
          username: this.username,
          password: this.password,
        };
        this.$store.dispatch("addLoading");
        await this.$store.dispatch("login", formData);
        await this.$store.dispatch("loadUser");
        this.redirectUser();
      } catch (error) {
        this.errors = handle_response_error(error);
      } finally {
        this.$store.dispatch("decreaseLoading");
      }
    },
    redirectUser() {
      const role = this.$store.getters.role;

      if (role === "client") {
        this.$router.push({ name: "Dashboard" });
      } else {
        this.$router.push({
          name: `${role[0].toUpperCase() + role.substr(1)}Dashboard`,
        });
      }
    },
  },
};
</script>
