<template>
  <div
    class="
      is-flex
      is-flex-direction-column
      is-align-items-center
      is-justify-content-flex-start
      has-text-centered
    "
    :class="{ 'has-text-link': active }"
    style="width: 150px"
  >
    <div class="is-size-2 block mb-4" style="height: 85px">
      <i class="fa-xl" :class="icon"></i>
    </div>
    <p v-if="active">{{ activeText }}</p>
    <p v-else-if="showText">{{ inactiveText }}</p>
  </div>
</template>

<script>
export default {
  name: "OrderStatusItem",
  props: ["active", "activeText", "inactiveText", "icon", "showText"],
};
</script>
