export default {
  namespaced: true,
  state() {
    return {
      files: [],
      client: 0,
      remark: "",
    };
  },
  mutations: {
    setFiles(state, payload) {
      state.files.push(payload);
    },
    removeFiles(state) {
      state.files = [];
    },
    removeFileByIndex(state, index) {
      state.files.splice(index, 1);
    },
    setFileByIndex(state, payload) {
      state.files[payload.index] = payload.file;
    },
    setClient(state, payload) {
      state.client = payload;
    },
    setRemark(state, payload) {
      state.remark = payload;
    },
  },
  actions: {
    removeFiles(context) {
      context.commit("removeFiles");
    },
    setFiles(context, payload) {
      context.commit("setFiles", payload);
    },
    removeFile(context, index) {
      context.commit("removeFileByIndex", index);
    },
    editFile(context, payload) {
      context.commit("setFileByIndex", payload);
    },
    setClient(context, payload) {
      context.commit("setClient", payload);
    },
    setRemark(context, payload) {
      context.commit("setRemark", payload);
    },
  },
  getters: {
    cartItemCount(state) {
      return state.files.length;
    },
    files(state) {
      return state.files;
    },
    fileByIndex: (state) => (index) => {
      return state.files[index];
    },
    client(state) {
      return state.client;
    },
    remark(state) {
      return state.remark;
    },
  },
};
