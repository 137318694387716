<template>
  <section class="section">
    <div class="is-flex block">
      <BackButton />
      <p class="title is-size-3 has-text-info">Edit Profile</p>
    </div>

    <div class="column">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in responseErrors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label
          >Full Name: <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="text"
          name="name"
          :class="{
            'is-danger': errors.name.length > 0,
          }"
          v-model="rider.name"
          placeholder="eg. James Tan"
        />
        <p
          v-for="(err, index) in errors.name"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label
          >I/C Number: <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="tel"
          name="ic"
          :class="{
            'is-danger': errors.ic.length > 0,
          }"
          v-model="rider.ic"
          disabled
          placeholder="eg. xxxxxx-xx-xxxx"
        />
        <p
          v-for="(err, index) in errors.ic"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>Email Address:</label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          :class="errors.email.length > 0 ? 'is-danger' : ''"
          type="email"
          name="email"
          v-model="rider.email"
          disabled
        />
        <p
          v-for="(err, index) in errors.email"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label
          >Mobile Number:
          <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="tel"
          name="contact_num"
          :class="{
            'is-danger': errors.contact_num.length > 0,
          }"
          v-model="rider.contact_num"
          placeholder="eg. 017-298 3847"
        />
        <p
          v-for="(err, index) in errors.contact_num"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label
          >Home Address: <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <textarea
          class="textarea"
          name="address"
          :class="{
            'is-danger': errors.address.length > 0,
          }"
          v-model="rider.address"
        ></textarea>
        <p
          v-for="(err, index) in errors.address"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
    </div>

    <div class="buttons is-centered">
      <div class="button is-info has-text-weight-bold" @click="editProfile()">
        Edit Profile
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";

import BackButton from "@/components/BackButton";
import { api_call, validate_mobile } from "@/utils";

const store = useStore();

const rider = reactive({
  name: "",
  ic: "",
  contact_num: "",
  email: "",
  address: "",
});
const errors = reactive({
  name: [],
  ic: [],
  contact_num: [],
  email: [],
  address: [],
});
const responseErrors = ref([]);

onMounted(() => {
  api_call("/api/v1/delivery/me", {
    success(res) {
      Object.assign(rider, res.data);
    },
    failed(err) {
      responseErrors.value = err;
    },
  });
});

const editProfile = () => {
  if (valid()) {
    api_call(`/api/v1/delivery/${rider.id}/`, {
      method: "patch",
      data: rider,
      success() {
        store.dispatch("flashMessage", {
          type: "is-success",
          content: "Profile updated successfully.",
        });
      },
      failed(err) {
        responseErrors.value = err;
      },
    });
  }
};
const valid = () => {
  Object.assign(errors, {
    name: [],
    ic: [],
    contact_num: [],
    email: [],
    address: [],
  });
  let valid = true;
  for (var key in rider) {
    if (!rider[key]) {
      valid = false;
      errors[key].push(`this field cannot be empty`);
    }
  }
  if (!validate_mobile(rider.contact_num)) {
    errors.contact_num.push("mobile number format invalid");
    valid = false;
  }

  return valid;
};
</script>
