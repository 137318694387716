<template>
  <div class="modal" :class="{ 'is-active': show }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <p class="image">
        <slot></slot>
      </p>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="close"
    ></button>
  </div>
</template>

<script setup>
import { toRefs, defineProps, defineEmits } from "vue";

const props = defineProps({
  show: { type: Boolean, default: false },
});
const { show } = toRefs(props);
const emits = defineEmits(["close"]);
const close = () => {
  emits("close");
};
</script>
