<template>
  <div
    class="message mb-1 is-danger"
    v-for="(error, index) in errors"
    :key="index"
  >
    <div class="message-body">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["errors"],
};
</script>
