<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <BackButton />
        <p class="title is-size-4 has-text-info">Customer List</p>
      </div>
    </div>
    <hr />

    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-full">
        <div class="is-flex is-flex-wrap-wrap">
          <div class="field mr-5">
            <label for="username" class="label">Username</label>
            <div class="control">
              <input
                type="text"
                name="username"
                v-model="filters.username"
                class="input is-small"
                placeholder="Username"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="email" class="label">Email Address</label>
            <div class="control">
              <input
                type="text"
                name="email"
                v-model="filters.email"
                class="input is-small"
                placeholder="Email Address"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="name" class="label">Full Name</label>
            <div class="control">
              <input
                type="text"
                name="name"
                v-model="filters.name"
                class="input is-small"
                placeholder="Full Name"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="contact_num" class="label">Contact Number</label>
            <div class="control">
              <input
                type="text"
                name="contact_num"
                v-model="filters.contact_num"
                class="input is-small"
                placeholder="Contact Number"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="city" class="label">City</label>
            <div class="control">
              <input
                type="text"
                name="city"
                v-model="filters.city"
                class="input is-small"
                placeholder="City"
              />
            </div>
          </div>

          <div class="field ml-auto mr-5 is-align-self-center">
            <div class="control">
              <button
                class="button is-link"
                @click="searchCustomer(null, true)"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="column">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in errors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('id')"
                label="ID"
                field="id"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('username')"
                label="Username"
                field="username"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('email')"
                label="Email"
                field="email"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Full Name</th>
            <th>Contact Number</th>
            <th>Address</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="user in customers" :key="user.id">
            <tr class="valign-middle">
              <th :rowspan="getRowSpan(user)"># {{ user.id }}</th>
              <td :rowspan="getRowSpan(user)">{{ user.username }}</td>
              <td :rowspan="getRowSpan(user)">{{ user.email }}</td>
              <template v-if="user.clients.length > 0">
                <td>{{ user.clients[0].name }}</td>
                <td>{{ user.clients[0].contact_num }}</td>
                <td v-html="formatAddress(user.clients[0])"></td>
              </template>
              <template v-else>
                <td></td>
                <td></td>
                <td></td>
              </template>
              <td :rowspan="getRowSpan(user)">
                <span class="tag is-success" v-if="user.is_active">
                  ACTIVE
                </span>
                <span class="tag is-danger" v-else> INACTIVE </span>
              </td>
              <td :rowspan="getRowSpan(user)">
                <div class="buttons">
                  <button
                    class="button is-small is-danger is-outlined"
                    v-if="user.is_active"
                    @click="changeStatus(user.id, false)"
                  >
                    Deactivate
                  </button>
                  <button
                    class="button is-small is-link is-outlined"
                    v-else
                    @click="changeStatus(user.id, true)"
                  >
                    Activate
                  </button>
                  <router-link
                    class="button is-small has-text-link"
                    :to="{ name: 'ViewCustomer', params: { id: user.id } }"
                  >
                    <span class="icon is-small">
                      <i class="far fa-folder-open"></i>
                    </span>
                    <span>View</span>
                  </router-link>
                </div>
              </td>
            </tr>
            <tr
              class="valign-middle"
              v-for="client in user.clients.slice(1)"
              :key="client.id"
            >
              <td>{{ client.name }}</td>
              <td>{{ client.contact_num }}</td>
              <td v-html="formatAddress(client)"></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <load-more-button
      :fetched-count="customers.length"
      :total-count="count"
      :next="next"
      @load-more="loadMore"
    />
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";

import TableHeaderOrder from "@/components/TableHeaderOrder";
import BackButton from "@/components/BackButton";
import LoadMoreButton from "@/components/LoadMoreButton";
import { api_call, formatAddress } from "@/utils";

const store = useStore();

const customers = ref([]);
const count = ref(0);
const next = ref(null);
const previous = ref(null);
const filters = reactive({
  username: "",
  email: "",
  name: "",
  contact_num: "",
  city: "",
  ordering: "id",
});
const errors = ref([]);
const ascending = ref(true);

const searchCustomer = (url = undefined, clean = true) => {
  errors.value = [];
  if (clean) {
    customers.value = [];
  }
  var query = "";
  var filter_list = [];
  for (var key in filters) {
    var value = filters[key];
    if (value) {
      filter_list.push(`${key}=${value}`);
    }
  }

  if (filter_list.length > 0) {
    query = "?" + filter_list.join("&");
  }

  url = url && url.substr(url.indexOf("/api"));

  api_call(url || `/api/v1/customers/${query}`, {
    failed: (error) => {
      errors.value = error;
    },
    success: (response) => {
      customers.value = customers.value.concat(response.data.results);
      count.value = response.data.count;
      next.value = response.data.next;
      previous.value = response.data.previous;
    },
  });
};

onMounted(() => {
  searchCustomer();
});

const loadMore = () => {
  searchCustomer(next.value, false);
};
const orderBy = (field) => {
  ascending.value = !ascending.value;
  filters.ordering = ascending.value ? field : "-" + field;
  searchCustomer();
};

const getRowSpan = (user) => {
  return user.clients.length || 1;
};

const changeStatus = async (id, status) => {
  const action = status ? "activate" : "deactivate";
  await api_call(`/api/v1/users/${id}/${action}/`, {
    method: "post",
    success() {
      store.dispatch("flashMessage", {
        type: "is-success",
        content: "Status changed successfully",
      });
    },
  });
  searchCustomer();
};
</script>

<style lang="sass" scoped>
tr.valign-middle>td, tr.valign-middle>th
  vertical-align: middle
</style>
