<template>
  <div
    class="
      column
      is-12 is-flex is-justify-content-space-between is-align-items-flex-end
    "
  >
    <p class="is-flex-grow-1 has-text-right has-text-weight-light mr-5">
      Showing {{ fetchedCount }} of {{ totalCount }} results
    </p>
    <div>
      <button
        class="button is-link is-small"
        @click="$emit('loadMore')"
        v-if="next"
      >
        Load More
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fetchedCount: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    next: {
      type: String,
      default: null,
    },
  },
  emits: ["loadMore"],
};
</script>
