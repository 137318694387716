<template>
  <div class="page-my-account">
    <section class="hero is-small is-info">
      <div class="hero-body has-text-centered">
        <div class="column is-12 has-text-centered">
          <img
            class="profile-picture"
            src="../../images/profile_white.png"
            alt="profile-picture"
          />
          <div class="column header-text is-4 is-offset-4">
            <p class="subtitle has-text-centered has-text-white">
              My Referral Code
            </p>
            <p class="title has-text-centered has-text-white">
              {{ referralProfile.referral_code }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-max-desktop">
        <div class="columns is-multiline">
          <div class="column is-12">
            <h1 class="title is-size-4">My Referrer</h1>
          </div>
          <div class="column is-12" v-if="referralProfile.referrer">
            <div
              class="
                box
                is-flex is-justify-content-space-between is-flex-wrap-wrap
              "
            >
              <p>{{ hidMiddlePartOfEmail(referralProfile.referrer.email) }}</p>
              <p>
                code:
                <strong>{{ referralProfile.referrer.referral_code }}</strong>
              </p>
            </div>
          </div>
          <!-- referrer code input, horizontal form -->
          <div class="column is-12" v-else>
            <div class="box">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Referrer Code:</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        placeholder="Enter your referrer code"
                        v-model="referrerCode"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <button class="button is-info" @click="setReferrer">
                        Set Referrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-max-desktop">
        <div class="columns is-multiline">
          <div class="column is-12">
            <h1 class="title is-size-4">Referrals</h1>
          </div>
          <div class="column is-12">
            <article class="message is-link">
              <div class="message-body">
                Each time you refer a friend to our website, you will receive a
                free credit for a maximum of 30 papers.
              </div>
            </article>
          </div>
          <!-- referrals list -->
          <div class="column is-12">
            <div class="message is-warning" v-if="referrals.length === 0">
              <div class="message-header"><p>No Referrals Found</p></div>
              <div class="message-body">
                <p>Please invite more friends to get rewarded</p>
              </div>
            </div>
            <div v-else>
              <div
                class="
                  box
                  is-flex is-justify-content-space-between is-flex-wrap-wrap
                "
                v-for="ref in referrals"
                :key="ref.referral_code"
              >
                <p>{{ hidMiddlePartOfEmail(ref.email) }}</p>
                <div>
                  <p class="tag" v-if="ref.claimed">
                    <!-- Reward Claimed: {{ formatMoney(ref.amount, "RM") }} -->
                    Reward Claimed:
                    {{ ref.amount && parseInt(ref.amount) }} papers
                  </p>
                  <!-- <hoverable-tag
                    text="UNCLAIMED"
                    icon="fa-lg fa-solid fa-circle-info"
                    v-else
                  >
                    Please look for the nearest merchant to claim your reward
                  </hoverable-tag> -->
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              column
              is-12
              is-flex
              is-justify-content-space-between
              is-align-items-flex-end
            "
          >
            <p class="is-flex-grow-1 has-text-right has-text-weight-light mr-5">
              Showing {{ referrals.length }} of {{ count }} results
            </p>
            <div>
              <button
                class="button is-link is-small"
                @click="getReferrals(false)"
                v-if="next"
              >
                Load More
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import { api_call, hidMiddlePartOfEmail } from "@/utils";

// import HoverableTag from "@/components/HoverableTag";

const store = useStore();

// Get Referral Code and referrals
const referralProfile = ref({});
const referrals = ref([]);
onMounted(async () => {
  api_call("/api/v1/referrals/me/", {
    flashMessage: true,
    success: ({ data }) => {
      referralProfile.value = data;
    },
  });
  getReferrals(true);
});
const count = ref(0);
const next = ref(null);
const getReferrals = async (clean = false) => {
  if (clean) referrals.value = [];

  let url = `/api/v1/referrals?referrer=${store.getters.userId}`;
  url = clean ? url : next.value || url;
  await api_call(url, {
    success: (response) => {
      referrals.value = referrals.value.concat(response.data.results);
      count.value = response.data.count;
      next.value = response.data.next;
    },
  });
};

// Set Referrer
const referrerCode = ref("");
const setReferrer = async () => {
  if (!referrerCode.value) {
    return;
  }
  api_call("/api/v1/referrals/set_referrer/", {
    method: "POST",
    flashMessage: true,
    data: {
      user: referralProfile.value.user,
      referral_code: referrerCode.value,
    },
    success: (res) => {
      referralProfile.value = res.data;
    },
  });
};
</script>
