<template>
  <router-link :to="to">
    <div
      class="column box p-5"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
      style="height: 280px"
    >
      <div
        class="
          is-size-2 is-flex is-align-items-center is-justify-content-center
          block
        "
        :class="{ 'has-text-link': !hover }"
        style="height: 128px"
      >
        <i class="fa-xl" :class="icon"></i>
      </div>
      <div class="block">
        <hr />
        <p>{{ text }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "DashboardTile",
  props: ["to", "icon", "text"],
  data() {
    return {
      hover: false,
    };
  },
};
</script>
