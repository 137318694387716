<template>
  <div>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <p class="is-size-4 has-text-link">Order ID # {{ cart.id }}</p>
          <span class="mx-2 tag is-danger" v-if="cart.status === 'CA'"
            >CANCELLED</span
          >
        </div>
      </div>
      <div class="level-right" v-if="isCompleted">
        <div class="level-item">
          <button class="button is-light" @click="getPdf">
            <span>
              <figure class="image is-32x32">
                <img src="@/images/download.png" alt="download" />
              </figure>
            </span>
            <p class="has-text-link ml-5">Download Receipt</p>
          </button>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <form-field
          v-if="isDelivery && merchant"
          label="Merchant Name:"
          :value="merchant.name"
        />
        <form-field
          v-if="isDelivery && merchant"
          label="Merchant Contact No.:"
          :value="merchant.contact_num"
        />
        <form-field
          v-if="isDelivery && merchant"
          label="Merchant Address:"
          :value="formatInlineAddress(merchant)"
        />
        <form-field label="Merchant:" v-if="isAdmin && merchant">
          <template #field>
            <div class="field">
              <div class="control is-expanded">
                <merchant-detail-popup :merchant="merchant" :relative="true" />
              </div>
            </div>
          </template>
        </form-field>
        <form-field
          v-if="!isMerchant && client"
          label="Recipient Name:"
          :value="client.name"
        />
        <form-field
          v-if="!isMerchant && client"
          label="Recipient Phone:"
          :value="client.country_code + client.contact_num"
        />
        <form-field
          v-if="!isMerchant && client"
          label="Recipient Address:"
          :value="formatInlineAddress(client)"
        />
        <form-field
          label="Order Time:"
          :value="formatDateTime(cart.createdAt)"
        />
        <form-field
          label="Delivery Time:"
          :value="formatDateTime(cart.deliveryTime)"
        />
        <form-field label="Delivery by Courier:">
          <template #field>
            <div class="field">
              <p class="py-1">
                <span class="has-text-primary" v-if="cart.deliveryByCourier">
                  <font-awesome-icon
                    icon="fa-solid fa-square-check"
                    size="xl"
                  />
                </span>
                <span class="has-text-danger" v-else>
                  <font-awesome-icon
                    icon="fa-solid fa-square-xmark"
                    size="xl"
                  />
                </span>
              </p>
            </div>
          </template>
        </form-field>
        <form-field label="Urgent Order:">
          <template #field>
            <div class="field">
              <p class="py-1">
                <span class="has-text-primary" v-if="cart.urgentOrder">
                  <font-awesome-icon
                    icon="fa-solid fa-square-check"
                    size="xl"
                  />
                </span>
                <span class="has-text-danger" v-else>
                  <font-awesome-icon
                    icon="fa-solid fa-square-xmark"
                    size="xl"
                  />
                </span>
              </p>
            </div>
          </template>
        </form-field>
        <form-field
          label="Total Papers:"
          :value="cart.totalPapers"
          v-if="!isDelivery"
        />
        <form-field
          label="No. of Files:"
          v-if="cart.files"
          :value="cart.files.length"
        />
        <form-field
          v-if="isPrepared"
          label="Net Price:"
          :value="formatMoney(cart.netPrice)"
        />
        <form-field
          v-if="isPrepared"
          label="Delivery Fee:"
          :value="formatMoney(cart.deliveryFee)"
        />
        <form-field
          v-if="isPrepared"
          label="Total Amount:"
          :value="formatMoney(cart.totalPrice)"
        />
        <form-field label="Applied Referral Reward:" v-if="cart.referralReward">
          <template #field>
            <div class="field">
              <p class="control is-expanded my-auto">
                {{ hidMiddlePartOfEmail(cart.referralReward.email) }}
                <span class="tag is-success mx-3"
                  >{{ cart.referralReward.amount }} papers</span
                >
              </p>
            </div>
          </template>
        </form-field>
        <form-field
          v-if="isPrepared"
          label="Pending Payment Amount:"
          :value="formatMoney(pendingPayment)"
        />
        <form-field
          v-if="cart.paymentMethod"
          label="Payment Method:"
          :value="getPaymentMethod(cart.paymentMethod)"
        />
        <form-field label="Rider:" v-if="isAdmin && delivery">
          <template #field>
            <div class="field">
              <div class="control is-expanded">
                <rider-detail-popup :rider="delivery" :relative="true" />
              </div>
            </div>
          </template>
        </form-field>
        <form-field
          v-if="isCompleted"
          label="Received By:"
          :value="cart.received_by"
        />
        <form-field
          v-if="isCompleted"
          label="Received Time:"
          :value="formatDateTime(cart.deliveredAt)"
        />
        <form-field label="Photo Proof:" v-if="isCompleted">
          <template #field>
            <div class="field">
              <div class="control is-expanded">
                <a :href="cart.photoProof" target="_blank">{{
                  getFilename(cart.photoProof)
                }}</a>
              </div>
            </div>
          </template>
        </form-field>
        <form-field
          label="Refunded Amount:"
          v-if="cart.refundedAmount > 0"
          :value="formatMoney(cart.refundedAmount)"
        />
      </div>

      <div class="column">
        <p class="has-text-link is-size-4">Customer Remark:</p>
        <div class="control">
          <textarea class="textarea" readonly :value="cart.remark"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, computed } from "vue";
import { useStore } from "vuex";

import MerchantDetailPopup from "@/components/MerchantDetailPopup";
import RiderDetailPopup from "@/components/RiderDetailPopup";
import FormField from "@/components/FormField";
import {
  formatMoney,
  formatDateTime,
  formatInlineAddress,
  ROLE,
  api_call,
  getPendingPayment,
  getPaymentMethod,
  getStatusOrder,
  hidMiddlePartOfEmail,
} from "@/utils";

const fileDownload = require("js-file-download");

const store = useStore();

const props = defineProps(["cart", "client"]);
const { cart } = toRefs(props);
const client = computed(() => cart.value.client);
const merchant = computed(() => cart.value.merchant);
const delivery = computed(() => cart.value.delivery);

const pendingPayment = computed(() => {
  return getPendingPayment(cart.value);
});

const getFilename = (path) => {
  if (!path) return "";
  return path.split("/").pop();
};

const isPrepared = computed(() => {
  return getStatusOrder(cart.value.status) >= getStatusOrder("RP");
});

const isCompleted = computed(() => {
  return cart.value.status === "CO";
});

const isAdmin = computed(() => {
  return store.getters.role === ROLE[1];
});

const isMerchant = computed(() => {
  return store.getters.role === ROLE[2];
});

const isDelivery = computed(() => {
  return store.getters.role === ROLE[3];
});

const getPdf = () => {
  api_call(`/api/v1/carts/${cart.value.id}/generate_pdf/`, {
    data: {
      responseType: "blob",
    },
    success: (res) => {
      fileDownload(res.data, `invoice_${cart.value.id}.pdf`);
    },
  });
};
</script>
