<template>
  <section class="section"></section>
</template>

<script setup>
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();

const route = useRoute();
const { uid, token } = route.params;

if (!uid || !token) {
  store.dispatch("pushMessage", {
    type: "is-danger",
    content: "Invalid Activation Link or Activation Link has expired.",
  });
  // router.push("/");
} else {
  axios
    .post("/api/v1/users/activation/", {
      uid,
      token,
    })
    .then(() => {
      store.dispatch("pushMessage", {
        type: "is-success",
        content: "Your Account is <b>activated</b>. please proceed to login.",
      });
      // console.log(res);
      router.push("/");
    })
    .catch(() => {
      store.dispatch("pushMessage", {
        type: "is-danger",
        content: "Invalid Activation Link or Activation Link has expired.",
      });
      router.push("/");
    });
}
</script>
