import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faSquareCheck,
  faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
library.add(faSquareCheck, faSquareXmark);

// axios.defaults.baseURL = "http://192.168.68.108:8000";
// axios.defaults.baseURL = `http://${process.env.VUE_APP_DEVSERVER_PROXY}:8000`;
axios.defaults.withCredentials = true;

let app = createApp(App);
app
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router, axios)
  .use(VueApexCharts)
  .mount("#app");
