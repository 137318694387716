<template>
  <section class="section">
    <div class="is-flex block">
      <BackButton />
      <p class="title is-size-3 has-text-info">Add - Rider</p>
    </div>

    <div class="column">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in responseErrors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label
          >Full Name: <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="text"
          name="name"
          :class="{
            'is-danger': errors.name.length > 0,
          }"
          v-model="rider.name"
          placeholder="eg. James Tan"
        />
        <p
          v-for="(err, index) in errors.name"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label
          >I/C Number: <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="tel"
          name="ic"
          :class="{
            'is-danger': errors.ic.length > 0,
          }"
          v-model="rider.ic"
          placeholder="eg. xxxxxx-xx-xxxx"
        />
        <p
          v-for="(err, index) in errors.ic"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Email Address: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          :class="{
            'is-danger': errors.email.length > 0,
          }"
          type="email"
          name="email"
          v-model="rider.email"
        />
        <p
          v-for="(err, index) in errors.email"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label
          >Mobile Number:
          <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="tel"
          name="contact_num"
          :class="{
            'is-danger': errors.contact_num.length > 0,
          }"
          v-model="rider.contact_num"
          placeholder="eg. 017-298 3847"
        />
        <p
          v-for="(err, index) in errors.contact_num"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label
          >Home Address: <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <textarea
          class="textarea"
          name="address"
          :class="{
            'is-danger': errors.address.length > 0,
          }"
          v-model="rider.address"
        ></textarea>
        <p
          v-for="(err, index) in errors.address"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
    </div>

    <div class="buttons is-centered">
      <div class="button is-info has-text-weight-bold" @click="addUser()">
        Register Rider
      </div>
    </div>

    <div id="successModal" class="modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-success">
            Rider Created Successfully
          </p>
        </header>

        <div class="modal-card-body">
          <p class="has-text-dark">
            Your temporary password is:
            <strong>{{ password }}</strong>
          </p>
          <div class="message is-warning mt-5">
            <div class="message-body">
              Note: Temporary password will only be shown once, please note it
              down somewhere safe
            </div>
          </div>
        </div>

        <footer class="modal-card-foot is-justify-content-flex-end">
          <button class="button card-footer-item" @click="viewRider">
            Done
          </button>
        </footer>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";

import {
  make_random_password,
  api_call,
  validate_email,
  validate_mobile,
  get_role_id,
} from "@/utils";
import BackButton from "@/components/BackButton.vue";

const router = useRouter();

const rider = reactive({
  user: "",
  name: "",
  ic: "",
  contact_num: "",
  email: "",
  address: "",
});
const errors = reactive({
  name: [],
  ic: [],
  contact_num: [],
  email: [],
  address: [],
});
const responseErrors = ref([]);
const password = ref(make_random_password());
const new_id = ref(0);

const addUser = async () => {
  if (valid()) {
    const formData = {
      username: rider.email,
      email: rider.email,
      password: password.value,
      role: get_role_id("delivery"),
    };
    try {
      const response = await api_call("/api/v1/users/", {
        method: "post",
        data: formData,
      });
      // await api_call(`/api/v1/users/set_role/`, {
      //   method: "post",
      //   data: {
      //     id: response.data.id,
      //     role: "delivery",
      //   },
      // });
      rider.user = parseInt(response.data.id);
      const rider_response = await api_call("/api/v1/delivery/", {
        method: "post",
        data: rider,
      });
      new_id.value = parseInt(rider_response.data.id);
      document.getElementById("successModal").classList.add("is-active");
    } catch (errors) {
      responseErrors.value = errors;
    }
  }
};

function valid() {
  Object.assign(errors, {
    name: [],
    ic: [],
    contact_num: [],
    email: [],
    address: [],
  });
  let valid = true;
  for (var key in errors) {
    if (!rider[key]) {
      valid = false;
      errors[key].push(`this field cannot be empty`);
    }
  }
  // if (!validate_ic(rider.ic)) {
  //   errors.ic.push("I/C Number format should be 123456-12-1234");
  //   valid = false;
  // }
  if (!validate_mobile(rider.contact_num)) {
    errors.contact_num.push("mobile number format invalid");
    valid = false;
  }
  if (!validate_email(rider.email)) {
    errors.email.push("email format invalid");
    valid = false;
  }

  return valid;
}
function viewRider() {
  router.replace({
    name: "ViewDelivery",
    params: { id: new_id.value },
  });
}
</script>
