<template>
  <nav class="navbar">
    <div class="navbar-brand">
      <div class="navbar-item">
        <router-link :to="homeLink" class="navbar-item">
          <figure class="image mr-5">
            <img src="@/images/logo.svg" alt="logo" />
          </figure>
        </router-link>
      </div>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarMobile"
        @click="showMobileMenu = !showMobileMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="navbarMobile"
      class="navbar-menu"
      v-bind:class="{ 'is-active': showMobileMenu }"
    >
      <template v-if="isAuthenticated">
        <div class="navbar-end">
          <div class="navbar-item" v-if="isClient">
            <router-link to="/dashboard/carts" class="button is-link">
              <div class="is-inline-flex cart px-5">
                <p>{{ cartItemCount }} items</p>
                <img src="@/images/cart.png" alt="cart-icon" />
              </div>
            </router-link>
          </div>

          <div class="is-inline-flex" v-if="isClient">
            <div class="navbar-item">
              <router-link :to="{ name: 'MyAccount' }" class="button is-link">
                <span class="icon"
                  ><img src="@/images/profile_white.png" alt="profile-icon"
                /></span>
              </router-link>
            </div>
          </div>

          <div
            class="navbar-item is-clickable is-hoverable"
            @mouseenter="show = true"
            @mouseleave="show = false"
          >
            <div>
              <span class="has-text-white" @click="show = !show">
                <span class="icon is-medium fa-xl">
                  <span class="fa-layers fa-fw">
                    <i class="fa-solid fa-bell"></i>
                    <span
                      class="fa-layers-counter fa-2x"
                      :class="{ active: notiCount > 0 }"
                    >
                      {{ notiCount }}
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <Transition
              name="drawer"
              @mouseenter="show = true"
              @mouseleave="show = false"
            >
              <div class="dropdown-menu" v-if="show">
                <div class="dropdown-content">
                  <div class="dropdown-item">
                    <p>
                      <strong>Notifications ({{ notification.length }})</strong>
                    </p>
                  </div>
                  <div v-for="item in notification" :key="item.id">
                    <hr class="dropdown-divider my-0" />
                    <a
                      href="#"
                      class="dropdown-item is-flex is-flex-direction-column"
                      :class="{ 'has-text-weight-bold': !item.read }"
                      @click="read_notification(item)"
                    >
                      <p class="is-size-6">{{ item.notification }}</p>
                      <span class="is-size-7 has-text-right">
                        - {{ formatDateTime(item.modifiedAt) }}
                      </span>
                    </a>
                  </div>
                  <hr class="dropdown-divider mt-0" />
                  <div class="is-flex is-size-7 is-justify-content-flex-end">
                    <div class="mr-2">only showing recent 5</div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
          <div class="navbar-item">
            <button @click="signout()" class="button is-link">
              <span class="icon"
                ><img src="@/images/logout.png" alt="logout-icon" />
              </span>
            </button>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <router-link to="/sign-up" class="button is-info">
                <strong>Sign Up</strong>
              </router-link>
              <router-link to="/" class="button is-light">
                <strong>Sign In</strong>
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </div>
  </nav>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { formatDateTime } from "@/utils";

const store = useStore();
const router = useRouter();

const isClient = computed(() => store.getters.isClient);
const user = computed(() => store.getters.userId);
const isAuthenticated = computed(() => store.getters.isAuthenticated);
const cartItemCount = computed(() => store.getters["cart/cartItemCount"]);
const notification = computed(
  () => store.getters["notification/notifications"]
);
const notiCount = computed(() => store.getters["notification/unreadCount"]);
const homeLink = computed(() => {
  if (isClient.value) {
    return { name: "Dashboard" };
  } else if (isAuthenticated.value) {
    const role = store.getters.role;
    const capitalized = role[0].toUpperCase() + role.substr(1);
    return { name: `${capitalized}Dashboard` };
  } else {
    return "/";
  }
});

watch(user, (newUser, oldUser) => {
  if (!oldUser && !!newUser) {
    store.dispatch("notification/getNotifications");
    const role = store.getters.role;
    store.dispatch("notification/disconnectSocket");
    store.dispatch("notification/connectSocket", newUser);
    store.dispatch("notification/connectSocket", role);
  }
});

const showMobileMenu = ref(false);
const show = ref(false);

const signout = () => {
  store.dispatch("invalidateToken");
  router.replace("/");
};
const read_notification = (notification) => {
  store.dispatch("notification/readNotification", notification);
};
</script>

<style scoped>
.fa-layers-counter {
  background: grey;
}
.fa-layers-counter.active {
  background: Tomato;
}

.dropdown-menu {
  display: block;
  left: auto;
  right: 0;
}

.drawer-enter-from,
.drawer-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.drawer-enter-active,
.drawer-leave-active {
  transition: all 0.2s ease;
  transform-origin: top;
  overflow: hidden;
}

.drawer-enter-to,
.drawer-leave-from {
  opacity: 1;
  transform: scaleY(1);
}
</style>
