<script setup>
import { defineProps, toRefs } from "vue";

const props = defineProps({
  label: { type: String, default: "" },
});
const { label } = toRefs(props);
</script>

<template>
  <div class="column is-half-mobile is-2-desktop is-3-tablet">
    <label class="label">{{ label }}:</label>
  </div>
  <div class="column is-half-mobile is-4-desktop is-3-tablet">
    <slot></slot>
  </div>
</template>
