<template>
  <section class="section is-small">
    <!-- <star-rating @update:rating ="setRating"></star-rating> -->
    <h3 class="subtitle is-size-5">
      We hope you to rate our service. Thank you for your support.
    </h3>
    <br />
    <div class="columns is-centered">
      <div class="column is-half">
        <div class="box">
          <h2 class="has-text-info has-text-centered is-size-3 mb-5">
            Feedback Form
          </h2>

          <div class="columns">
            <div class="column is-half">
              <div class="field">
                <label
                  >First Name <span class="has-text-danger is-size-5">*</span>
                </label>
                <div class="control">
                  <input
                    type="text"
                    name="Fname"
                    class="input"
                    v-model="firstName"
                    :class="{
                      'is-danger': errors.firstName.length > 0,
                    }"
                  />
                  <p
                    v-for="(err, index) in errors.firstName"
                    :key="index"
                    class="help is-danger"
                  >
                    {{ err }}
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div class="field">
                <label
                  >Last Name <span class="has-text-danger is-size-5">*</span>
                </label>
                <div class="control">
                  <input
                    type="text"
                    name="Lname"
                    class="input"
                    v-model="lastName"
                    :class="{
                      'is-danger': errors.lastName.length > 0,
                    }"
                  />
                  <p
                    v-for="(err, index) in errors.lastName"
                    :key="index"
                    class="help is-danger"
                  >
                    {{ err }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half">
              <div class="field">
                <label
                  >Email Address
                  <span class="has-text-danger is-size-5">*</span></label
                >
                <div class="control">
                  <input
                    type="email"
                    name="email"
                    class="input"
                    v-model="email"
                    :class="{
                      'is-danger': errors.email.length > 0,
                    }"
                  />
                  <p
                    v-for="(err, index) in errors.email"
                    :key="index"
                    class="help is-danger"
                  >
                    {{ err }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <div class="field">
                <label
                  >Comment
                  <span class="has-text-danger is-size-5">*</span></label
                >
                <div class="control">
                  <textarea
                    class="textarea"
                    placeholder="Write your comments here."
                    v-model="comment"
                    :class="{
                      'is-danger': errors.comment.length > 0,
                    }"
                  ></textarea>
                  <p
                    v-for="(err, index) in errors.comment"
                    :key="index"
                    class="help is-danger"
                  >
                    {{ err }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons is-centered">
            <button class="button is-info" @click="submitForm">
              <strong> Submit</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { validate_email, handle_response_error } from "@/utils";
// import StarRating from "vue-star-rating";

export default {
  name: "FeedbackForm",
  // components: {
  //   StarRating,
  // },
  data() {
    return {
      clientID: "",
      firstName: "",
      lastName: "",
      email: "",
      comment: "",
      // rating: 0,
      errors: {
        firstName: [],
        lastName: [],
        email: [],
        comment: [],
      },
    };
  },
  methods: {
    // setRating(rating) {
    //   this.rating = rating;
    // },
    async submitForm() {
      if (this.valid()) {
        this.$store.dispatch("addLoading");
        const orderID = this.$route.params.id;
        let feedbackData = new FormData();

        feedbackData.append("order", orderID);
        feedbackData.append("firstName", this.firstName);
        feedbackData.append("lastName", this.lastName);
        feedbackData.append("email", this.email);
        feedbackData.append("comment", this.comment);
        try {
          this.$store.dispatch("addLoading");
          await this.$store.dispatch("refreshToken");
          await axios.post("/api/v1/feedbacks/", feedbackData);
          this.$router.push("/dashboard/");
        } catch (error) {
          handle_response_error(error, true);
        } finally {
          this.$store.dispatch("decreaseLoading");
        }
      }
    },
    valid() {
      this.errors = {
        firstName: [],
        lastName: [],
        email: [],
        comment: [],
      };
      let valid = true;
      for (var key in this.errors) {
        if (!this[key]) {
          valid = false;
          this.errors[key].push(`this field cannot be empty`);
        }
      }
      if (!validate_email(this.email)) {
        this.errors.email.push("email address format invalid");
        valid = false;
      }

      return valid;
    },
  },
};
</script>
