<template>
  <section class="section">
    <div class="is-flex block">
      <BackButton />
      <p class="title is-size-4 has-text-info">
        <span class="is-capitalized">{{ action }}</span> - Merchant
      </p>
    </div>

    <div class="column">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in responseErrors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Company Name: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="text"
          :class="{
            'is-static': action == 'view',
            'is-danger': errors.name.length > 0,
          }"
          :disabled="action == 'view'"
          v-model="merchant.name"
          placeholder="eg. Best Printing Sdn Bhd"
        />
        <p
          v-for="(err, index) in errors.name"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Company Registration No:
          <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="text"
          :class="{
            'is-static': action == 'view',
            'is-danger': errors.registrationNo.length > 0,
          }"
          :disabled="action == 'view'"
          v-model="merchant.registrationNo"
          placeholder="eg. 201901000005 (1312525-A) "
        />
        <p
          v-for="(err, index) in errors.registrationNo"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Contact Number: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="tel"
          :class="{
            'is-static': action == 'view',
            'is-danger': errors.contact_num.length > 0,
          }"
          :disabled="action == 'view'"
          v-model="merchant.contact_num"
          placeholder="eg. 03-3321 9900"
        />
        <p
          v-for="(err, index) in errors.contact_num"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Email Address: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="email"
          :class="{
            'is-static': action == 'view',
          }"
          v-model="merchant.email"
          disabled
        />
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Address Line 1:
          <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="address1"
          class="input"
          :class="{
            'is-static': action == 'view',
            'is-danger': errors.address1.length > 0,
          }"
          :disabled="action == 'view'"
          v-model="merchant.address1"
        />
        <p
          v-for="(err, index) in errors.address1"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>Address Line 2: </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="address2"
          class="input"
          :class="{
            'is-static': action == 'view',
          }"
          :disabled="action == 'view'"
          v-model="merchant.address2"
        />
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Zipcode: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="zipcode"
          class="input"
          :class="{
            'is-static': action == 'view',
            'is-danger': errors.zipcode.length > 0,
          }"
          :disabled="action == 'view'"
          v-model="merchant.zipcode"
        />
        <p
          v-for="(err, index) in errors.zipcode"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label> City: <span class="has-text-danger is-size-5">*</span> </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="city"
          class="input"
          :class="{
            'is-static': action == 'view',
            'is-danger': errors.city.length > 0,
          }"
          :disabled="action == 'view'"
          v-model="merchant.city"
        />
        <p
          v-for="(err, index) in errors.city"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label> State: <span class="has-text-danger is-size-5">*</span> </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="state"
          class="input"
          :class="{
            'is-static': action == 'view',
            'is-danger': errors.state.length > 0,
          }"
          :disabled="action == 'view'"
          v-model="merchant.state"
        />
        <p
          v-for="(err, index) in errors.state"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Country: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="country"
          class="input"
          :class="{
            'is-static': action == 'view',
            'is-danger': errors.country.length > 0,
          }"
          :disabled="action == 'view'"
          v-model="merchant.country"
        />
        <p
          v-for="(err, index) in errors.country"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
    </div>

    <div class="buttons is-centered">
      <div
        class="button is-info has-text-weight-bold"
        @click="editUser()"
        v-if="action == 'edit'"
      >
        Save
      </div>
      <router-link
        class="button is-info has-text-weight-bold"
        :to="{ name: 'EditMerchant', params: { id: merchant.id } }"
        v-else
      >
        Edit Merchant
      </router-link>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import BackButton from "@/components/BackButton";
import { api_call, validate_zipcode } from "@/utils";

const route = useRoute();
const router = useRouter();

// const action = ref("view");
const merchant = reactive({
  name: "",
  registrationNo: "",
  contact_num: "",
  email: "",
  address1: "",
  address2: "",
  zipcode: "",
  city: "",
  state: "",
  country: "",
  id: 0,
  user: 0,
});
const errors = reactive({
  name: [],
  registrationNo: [],
  contact_num: [],
  email: [],
  address1: [],
  zipcode: [],
  city: [],
  state: [],
  country: [],
});
const responseErrors = ref([]);

const action = computed(() => {
  const value = route.name == "ViewMerchant" ? "view" : "edit";
  return value;
});

onMounted(() => {
  const selectedIndex = route.params.id;
  api_call(`/api/v1/merchants/${selectedIndex}`, {
    success(res) {
      Object.assign(merchant, { ...res.data, user: res.data.user.id });
    },
    failed(error) {
      responseErrors.value = error;
    },
  });
});

const editUser = () => {
  if (valid()) {
    try {
      api_call(`/api/v1/merchants/${merchant.id}/`, {
        method: "patch",
        data: merchant,
        success() {
          router.push({
            name: "ViewMerchant",
            params: {
              id: merchant.id,
            },
          });
        },
      });
    } catch (error) {
      responseErrors.value = error;
    }
  }
};
const valid = () => {
  Object.assign(errors, {
    name: [],
    registrationNo: [],
    contact_num: [],
    email: [],
    address1: [],
    zipcode: [],
    city: [],
    state: [],
    country: [],
  });
  let valid = true;
  for (var key in errors) {
    if (!merchant[key]) {
      valid = false;
      errors[key].push(`this field cannot be empty`);
    }
  }
  // if (!validate_mobile(merchant.contact_num)) {
  //   errors.contact_num.push("mobile number format invalid");
  //   valid = false;
  // }
  if (!validate_zipcode(merchant.zipcode)) {
    errors.zipcode.push("zipcode format invalid");
    valid = false;
  }

  return valid;
};
</script>
