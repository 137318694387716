<template>
  <a class="is-flex" @click="emit('onClick')">
    {{ label }}
    <span class="ml-1 has-text-grey-light" v-if="selected && ascending">
      <i class="fa-solid fa-arrow-up-short-wide"></i>
    </span>
    <span class="ml-1 has-text-grey-light" v-else-if="selected && !ascending">
      <i class="fa-solid fa-arrow-down-short-wide"></i>
    </span>
  </a>
</template>

<script setup>
import { defineProps, defineEmits, computed, toRefs } from "vue";

const props = defineProps({
  label: String,
  field: String,
  currOrdering: String,
  ascending: Boolean,
});
const { label, field, currOrdering, ascending } = toRefs(props);
const emit = defineEmits(["onClick"]);

const selected = computed(() => {
  return (
    currOrdering.value === field.value ||
    currOrdering.value === `-${field.value}`
  );
});
// const iconClass = computed(() => {
//   console.log(ascending.value);
//   console.log({
//     "fa-arrow-up-short-wide": ascending.value,
//     "fa-arrow-down-short-wide": !ascending.value,
//   });
//   return {
//     "fa-arrow-up-short-wide": ascending.value,
//     "fa-arrow-down-short-wide": !ascending.value,
//   };
// });
</script>
