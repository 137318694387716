<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <BackButton />
        <p class="title is-size-4 has-text-info">Admin List</p>
      </div>
      <div class="level-right">
        <button class="button is-info" @click="addAdmin">Add Admin</button>
      </div>
    </div>
    <hr />

    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-full">
        <div class="is-flex is-flex-wrap-wrap">
          <div class="field mr-5">
            <label for="username" class="label">Username</label>
            <div class="control">
              <input
                type="text"
                name="username"
                v-model="filters.username"
                class="input is-small"
                placeholder="Username"
              />
            </div>
          </div>

          <div class="field mr-5">
            <label for="ic" class="label">Email</label>
            <div class="control">
              <input
                type="text"
                name="email"
                v-model="filters.email"
                class="input is-small"
                placeholder="Email"
              />
            </div>
          </div>

          <div class="field ml-auto mr-5 is-align-self-center">
            <div class="control">
              <button class="button is-link" @click="searchAdmin(null, true)">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="column">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in responseErrors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('id')"
                label="ID"
                field="id"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('username')"
                label="Username"
                field="username"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('email')"
                label="Email"
                field="email"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('last_login')"
                label="Last Login"
                field="last_login"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in admins" :key="user.id">
            <th># {{ user.id }}</th>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <!-- <td>{{ user.role }}</td> -->
            <td>{{ formatDateTime(user.last_login) }}</td>
            <td>
              <span class="tag is-success" v-if="user.is_active"> ACTIVE </span>
              <span class="tag is-danger" v-else> INACTIVE </span>
            </td>
            <td>
              <div class="buttons">
                <!-- <router-link
                  class="button is-small has-text-link"
                  :to="{ name: 'ViewDelivery', params: { id: user.id } }"
                >
                  <span class="icon is-small">
                    <i class="far fa-folder-open"></i>
                  </span>
                  <span>View</span>
                </router-link> -->
                <button
                  class="button is-small is-danger is-outlined"
                  v-if="user.is_active"
                  @click="changeStatus(user.id, false)"
                >
                  Deactivate
                </button>
                <button
                  class="button is-small is-link is-outlined"
                  v-else
                  @click="changeStatus(user.id, true)"
                >
                  Activate
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <load-more-button
      :fetched-count="admins.length"
      :total-count="count"
      :next="next"
      @load-more="loadMore"
    />

    <AddAdmin
      :errors="registrationErrors"
      :responseErrors="responseErrors"
      :show="showRegistarionModal"
      :registerAdmin="registerAdmin"
      :close="
        () => {
          showRegistarionModal = false;
        }
      "
    />

    <div id="successModal" class="modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-success">
            Admin Created Successfully
          </p>
        </header>

        <div class="modal-card-body">
          <p class="has-text-dark">
            Your temporary password is:
            <strong>{{ password }}</strong>
          </p>
          <div class="message is-warning mt-5">
            <div class="message-body">
              Note: Temporary password will only be shown once, please note it
              down somewhere safe
            </div>
          </div>
        </div>

        <footer class="modal-card-foot is-justify-content-flex-end">
          <button class="button is-success" @click="closeSuccessModal">
            Done
          </button>
        </footer>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";

import TableHeaderOrder from "@/components/TableHeaderOrder";
import BackButton from "@/components/BackButton";
import LoadMoreButton from "@/components/LoadMoreButton";
import AddAdmin from "./AddAdmin.vue";
import {
  make_random_password,
  api_call,
  validate_email,
  formatDateTime,
  get_role_id,
} from "@/utils";

const store = useStore();

const admins = ref([]);
const count = ref(0);
const next = ref(null);
const previous = ref(null);
const filters = reactive({
  username: "",
  email: "",
  last_login: "",
  ordering: "id",
});
const ascending = ref(true);
const responseErrors = ref([]);

const searchAdmin = async (url = undefined, clean = true) => {
  responseErrors.value = [];
  if (clean) {
    admins.value = [];
  }
  var query = "";
  var filter_list = [];
  for (var key in filters) {
    var value = filters[key];
    if (value) {
      filter_list.push(`${key}=${value}`);
    }
  }

  filter_list.push("role=" + get_role_id("admin"));

  if (filter_list.length > 0) {
    query = "?" + filter_list.join("&");
  }

  url = url && url.substr(url.indexOf("/api"));

  try {
    const response = await api_call(url || `/api/v1/users/${query}`);
    admins.value = admins.value.concat(response.data.results);
    count.value = response.data.count;
    next.value = response.data.next;
    previous.value = response.data.previous;
  } catch (error) {
    responseErrors.value = error;
  }
};
const loadMore = () => {
  searchAdmin(next.value, false);
};

onMounted(() => {
  searchAdmin();
});

const orderBy = (field) => {
  ascending.value = !ascending.value;
  filters.ordering = ascending.value ? field : "-" + field;
  searchAdmin();
};

// Add Admin
const registrationErrors = reactive({
  username: [],
  email: [],
});
const showRegistarionModal = ref(false);
const password = ref(make_random_password());

const addAdmin = () => {
  showRegistarionModal.value = true;
};
const registerAdmin = async (admin) => {
  if (valid(admin)) {
    const formData = {
      username: admin.username,
      email: admin.email,
      password: password.value,
      role: get_role_id("admin"),
    };
    try {
      await api_call("/api/v1/users/", {
        method: "post",
        data: formData,
      });
      // console.log(response);
      // await api_call(`/api/v1/users/set_role/`, {
      //   method: "post",
      //   data: {
      //     id: response.data.id,
      //     role: "admin",
      //   },
      // });
      showRegistarionModal.value = false;
      document.getElementById("successModal").classList.add("is-active");
      searchAdmin();
      return true;
    } catch (error) {
      responseErrors.value = error;
      return true;
    }
  } else {
    return false;
  }
};
const valid = (admin) => {
  Object.assign(registrationErrors, {
    username: [],
    email: [],
  });
  let valid = true;
  for (var key in registrationErrors) {
    if (!admin[key]) {
      valid = false;
      registrationErrors[key].push(`this field cannot be empty`);
    }
  }
  if (!validate_email(admin.email)) {
    registrationErrors.email.push("email format invalid");
    valid = false;
  }

  return valid;
};
const closeSuccessModal = () => {
  document.getElementById("successModal").classList.remove("is-active");
};

const changeStatus = (id, status) => {
  const action = status ? "activate" : "deactivate";
  api_call(`/api/v1/users/${id}/${action}/`, {
    method: "post",
    success() {
      store.dispatch("flashMessage", {
        type: "is-success",
        content: "Status changed successfully",
      });
      searchAdmin();
    },
    failed(error) {
      responseErrors.value = error;
      searchAdmin();
    },
  });
};
</script>
