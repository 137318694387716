<template>
  <section class="section">
    <div class="is-flex block">
      <BackButton />
      <p class="title is-size-4 has-text-info">Edit Profile</p>
    </div>

    <div class="column" v-if="responseErrors.length > 0">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in responseErrors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Company Name: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="text"
          :class="{
            'is-danger': errors.name.length > 0,
          }"
          disabled
          v-model="merchant.name"
          placeholder="eg. Best Printing Sdn Bhd"
        />
        <p
          v-for="(err, index) in errors.name"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Company Registration No:
          <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="text"
          :class="{
            'is-danger': errors.registrationNo.length > 0,
          }"
          disabled
          v-model="merchant.registrationNo"
          placeholder="eg. 201901000005 (1312525-A) "
        />
        <p
          v-for="(err, index) in errors.registrationNo"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Contact Number: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="tel"
          :class="{
            'is-danger': errors.contact_num.length > 0,
          }"
          v-model="merchant.contact_num"
          placeholder="eg. 03-3321 9900"
        />
        <p
          v-for="(err, index) in errors.contact_num"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Email Address: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="email"
          :class="{}"
          v-model="merchant.email"
          disabled
        />
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Address Line 1:
          <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="address1"
          class="input"
          :class="{
            'is-danger': errors.address1.length > 0,
          }"
          v-model="merchant.address1"
        />
        <p
          v-for="(err, index) in errors.address1"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>Address Line 2: </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="address2"
          class="input"
          :class="{}"
          v-model="merchant.address2"
        />
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Zipcode: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="zipcode"
          class="input"
          :class="{
            'is-danger': errors.zipcode.length > 0,
          }"
          v-model="merchant.zipcode"
        />
        <p
          v-for="(err, index) in errors.zipcode"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label> City: <span class="has-text-danger is-size-5">*</span> </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="city"
          class="input"
          :class="{
            'is-danger': errors.city.length > 0,
          }"
          v-model="merchant.city"
        />
        <p
          v-for="(err, index) in errors.city"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label> State: <span class="has-text-danger is-size-5">*</span> </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="state"
          class="input"
          :class="{
            'is-danger': errors.state.length > 0,
          }"
          v-model="merchant.state"
        />
        <p
          v-for="(err, index) in errors.state"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Country: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="country"
          class="input"
          :class="{
            'is-danger': errors.country.length > 0,
          }"
          v-model="merchant.country"
        />
        <p
          v-for="(err, index) in errors.country"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
    </div>

    <div class="buttons is-centered">
      <div class="button is-info has-text-weight-bold" @click="editProfile()">
        Edit Profile
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";

import BackButton from "@/components/BackButton";
import { api_call, validate_zipcode, validate_contact_with_cc } from "@/utils";

const store = useStore();

const merchant = reactive({
  name: "",
  registrationNo: "",
  contact_num: "",
  email: "",
  address1: "",
  address2: "",
  zipcode: "",
  city: "",
  state: "",
  country: "",
});
const errors = reactive({
  name: [],
  registrationNo: [],
  contact_num: [],
  email: [],
  address1: [],
  zipcode: [],
  city: [],
  state: [],
  country: [],
});
const responseErrors = ref([]);

onMounted(() => {
  api_call("/api/v1/merchants/me/", {
    success(res) {
      Object.assign(merchant, res.data);
    },
    failed(err) {
      responseErrors.value = err;
    },
  });
});

const editProfile = () => {
  if (valid()) {
    api_call(`/api/v1/merchants/${merchant.id}/`, {
      method: "patch",
      data: merchant,
      success() {
        store.dispatch("flashMessage", {
          type: "is-success",
          content: "Profile updated successfully.",
        });
      },
      failed(err) {
        responseErrors.value = err;
      },
    });
  }
};
const valid = () => {
  Object.assign(errors, {
    name: [],
    registrationNo: [],
    contact_num: [],
    email: [],
    address1: [],
    zipcode: [],
    city: [],
    state: [],
    country: [],
  });
  let valid = true;
  for (var key in errors) {
    if (!merchant[key]) {
      valid = false;
      errors[key].push(`this field cannot be empty`);
    }
  }
  if (!validate_contact_with_cc(merchant.contact_num)) {
    errors.contact_num.push("contact number format invalid");
    valid = false;
  }
  if (!validate_zipcode(merchant.zipcode)) {
    errors.zipcode.push("zipcode format invalid");
    valid = false;
  }

  return valid;
};
</script>
