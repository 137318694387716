<template>
  <section class="section">
    <div class="is-flex block">
      <BackButton />
      <p class="title is-size-3 has-text-info">View - Customer</p>
    </div>
    <div class="column">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in responseErrors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>

    <p class="title is-size-4">User Details</p>
    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label> Username: </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <strong>{{ customer.username }}</strong>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label> Email: </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <strong>{{ customer.email }}</strong>
      </div>
    </div>
  </section>

  <section class="section">
    <p class="title is-size-4">Addresses</p>
    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('id')"
                label="ID"
                field="id"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('name')"
                label="Full Name"
                field="name"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('contact_num')"
                label="Contact Number"
                field="contact_num"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('email')"
                label="Email"
                field="email"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('address1')"
                label="Address 1"
                field="address1"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('address2')"
                label="Address 2"
                field="address2"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('zipcode')"
                label="Zipcode"
                field="zipcode"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('city')"
                label="City"
                field="city"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('state')"
                label="State"
                field="state"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('country')"
                label="Country"
                field="country"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="address in addresses" :key="address.id">
            <th># {{ address.id }}</th>
            <td>{{ address.name }}</td>
            <td>{{ address.contact_num }}</td>
            <td>{{ address.email }}</td>
            <td>{{ address.address1 }}</td>
            <td>{{ address.address2 }}</td>
            <td>{{ address.zipcode }}</td>
            <td>{{ address.city }}</td>
            <td>{{ address.state }}</td>
            <td>{{ address.country }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <load-more-button
      :fetched-count="addresses.length"
      :total-count="count"
      :next="next"
      @load-more="loadMore"
    />
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";

import TableHeaderOrder from "@/components/TableHeaderOrder";
import BackButton from "@/components/BackButton";
import LoadMoreButton from "@/components/LoadMoreButton";
import { api_call } from "@/utils";

const route = useRoute();

const customer = ref({});
const count = ref(0);
const next = ref(null);
const previous = ref(null);
const filters = reactive({
  username: "",
  email: "",
  name: "",
  contact_num: "",
  city: "",
  ordering: "id",
});
const ascending = ref(true);
const addresses = ref([]);
const responseErrors = ref([]);

onMounted(() => {
  const selectedIndex = route.params.id;
  responseErrors.value = [];

  api_call(`/api/v1/customers/${selectedIndex}`, {
    success(res) {
      customer.value = res.data;
      searchAddress();
    },
  });
});

const searchAddress = (url = undefined, clean = true) => {
  responseErrors.value = [];
  if (clean) {
    addresses.value = [];
  }
  var query = "";
  var filter_list = [];
  for (var key in filters) {
    var value = filters[key];
    if (value) {
      filter_list.push(`${key}=${value}`);
    }
  }

  filter_list.push("owner__id=" + customer.value.id);

  if (filter_list.length > 0) {
    query = "?" + filter_list.join("&");
  }

  url = url && url.substr(url.indexOf("/api"));

  api_call(url || `/api/v1/clients/${query}`, {
    success(res) {
      addresses.value = addresses.value.concat(res.data.results);
      count.value = res.data.count;
      next.value = res.data.next;
      previous.value = res.data.previous;
    },
    failed(error) {
      responseErrors.value = error;
    },
  });
};

const loadMore = () => {
  searchAddress(next.value, false);
};
const orderBy = (field) => {
  ascending.value = !ascending.value;
  filters.ordering = ascending.value ? field : "-" + field;
  searchAddress();
};
</script>
