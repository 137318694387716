<template>
  <section class="section pb-5">
    <div class="level">
      <div class="level-left">
        <back-button />
        <p class="title is-size-4 has-text-info">Referral Program</p>
      </div>
    </div>
    <hr />
    <div class="container">
      <div class="field">
        <div class="control">
          <div class="field is-grouped">
            <div class="control is-expanded">
              <input
                class="input"
                type="text"
                placeholder="search by username, email, referral code or e-wallet id"
                v-model="query"
              />
            </div>
            <div class="control">
              <button class="button is-info" @click="searchCustomers(true)">
                <span>Search</span>
                <span class="icon">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
        <p class="help is-info">
          search by username, email, referral code or e-wallet id
        </p>
      </div>
      <errors-list :errors="errors" />
    </div>
  </section>
  <section class="section pt-0" v-if="searched">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h1 class="title is-size-4" v-if="customer">Selected Customer</h1>
          <h1 class="title is-size-4" v-else>Customers</h1>
        </div>
        <div class="column is-12">
          <div v-if="customers.length > 0">
            <div
              class="box is-clickable"
              v-for="cust in customers"
              :key="cust.id"
              @click="selectCustomer(cust)"
            >
              <div class="level mb-3">
                <div class="level-left">
                  <div class="level-item">
                    <p class="title has-text-info is-size-4">
                      {{ cust.username }}
                    </p>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <hoverable-tag
                      v-if="cust.ewallet_account"
                      :text="cust.ewallet_account.id"
                      icon="fa-lg fa-solid fa-circle-info"
                      color="is-grey"
                    >
                      Wallet ID
                    </hoverable-tag>
                  </div>
                </div>
              </div>
              <div class="level is-mobile">
                <div class="level-left">
                  <div class="level-item">
                    <div class="columns is-multiline">
                      <div class="column is-4 pb-0">
                        <p>Email:</p>
                      </div>
                      <div class="column is-8 pb-0">
                        <p>
                          <strong>{{ cust.email }}</strong>
                        </p>
                      </div>
                      <div class="column is-4 pt-0">
                        <p>Referral Code:</p>
                      </div>
                      <div class="column is-8 pt-0" v-if="cust.referral">
                        <p>
                          <strong>{{ cust.referral.referral_code }}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item" v-if="cust.ewallet_account">
                    <h1 class="title has-text-link is-size-2">
                      {{ formatMoney(cust.ewallet_account.balance, "RM") }}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <LoadMoreButton
              v-if="!customer"
              :fetched-count="customers.length"
              :total-count="customersCount"
              :next="customersNext"
              @load-more="searchCustomers(false)"
            />
          </div>
          <div class="message is-warning" v-else>
            <div class="message-header"><p>No Customer Found</p></div>
            <div class="message-body">
              <p>
                Please enter a valid email or referral code to search for a
                customer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section pt-0" v-if="searched && customer">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h1 class="title is-size-4">Referrals</h1>
        </div>
        <!-- referrals list -->
        <div class="column is-12">
          <div class="message is-warning" v-if="referrals.length === 0">
            <div class="message-header"><p>No Referrals Found</p></div>
            <div class="message-body">
              <p>Please invite more friends to get rewarded</p>
            </div>
          </div>
          <div v-else>
            <div
              class="
                box
                is-flex
                is-justify-content-space-between
                is-flex-wrap-wrap
                is-align-items-center
              "
              v-for="ref in referrals"
              :key="ref.referral_code"
            >
              <p>{{ ref.email }}</p>
              <div>
                <p class="tag" v-if="ref.claimed">
                  Reward Claimed: {{ formatMoney(ref.amount, "RM") }}
                </p>
                <button
                  class="button is-link"
                  @click="toggleModal(true, ref.user)"
                  v-else
                >
                  Relase Reward
                </button>
              </div>
            </div>
          </div>
          <LoadMoreButton
            :fetched-count="referrals.length"
            :total-count="referralsCount"
            :next="referralsNext"
            @load-more="getReferrals(false)"
          />
        </div>
      </div>
    </div>
  </section>

  <referral-reward-modal
    @cancel="toggleModal(false)"
    @release="releaseReward"
    :show="showModal"
    :awaitingConfirmation="awaitingConfirmation"
  />
</template>

<script setup>
import { ref } from "vue";

import { api_call, formatMoney } from "@/utils";

import BackButton from "@/components/BackButton";
import ErrorsList from "@/components/ErrorsList";
import ReferralRewardModal from "@/components/merchant/ReferralRewardModal";
import HoverableTag from "@/components/HoverableTag";
import LoadMoreButton from "@/components/LoadMoreButton";

const query = ref("");
const searched = ref(false);
const errors = ref([]);

const customers = ref([]);
const customersCount = ref(0);
const customersNext = ref(null);
const searchCustomers = async (clean = true) => {
  if (!query.value) return;
  if (clean) customers.value = [];
  searched.value = true;
  errors.value = [];
  referrals.value = [];
  customer.value = null;

  let url = `/api/v1/customers/?query=${query.value}`;
  url = clean ? url : customersNext.value || url;
  await api_call(url, {
    success: (response) => {
      if (response.data.count > 0) {
        customers.value = customers.value.concat(response.data.results);
        customersCount.value = response.data.count;
        customersNext.value = response.data.next;
      }
    },
    failed: (error) => {
      errors.value = error;
    },
  });
};

const customer = ref(null);
const selectCustomer = (cust) => {
  customer.value = cust;
  searched.value = true;
  customers.value = [cust];
  getReferrals();
};
const referrals = ref([]);
const referralsCount = ref(0);
const referralsNext = ref(null);
const getReferrals = async (clean = true) => {
  if (!customer.value) return;
  if (clean) referrals.value = [];

  errors.value = [];
  let url = `/api/v1/referrals?referrer=${customer.value.id}`;
  url = clean ? url : referralsNext.value || url;
  await api_call(url, {
    success: (response) => {
      referrals.value = referrals.value.concat(response.data.results);
      referralsCount.value = response.data.count;
      referralsNext.value = response.data.next;
    },
    failed: (error) => {
      errors.value = error;
    },
  });
};

const showModal = ref(false);
const awaitingConfirmation = ref(false);
const selectedReferral = ref(null);
const toggleModal = (show, userId = null) => {
  showModal.value = show;
  if (userId) {
    selectedReferral.value = userId;
  }
  awaitingConfirmation.value = false;
};
const releaseReward = async (amount, confirmed) => {
  awaitingConfirmation.value = true;
  if (confirmed) {
    errors.value = [];
    await api_call(
      `/api/v1/referrals/${selectedReferral.value}/release_reward/`,
      {
        method: "POST",
        data: { amount },
        success: async () => {
          await getSingleCustomer(customer.value.id);
          getReferrals();
        },
        failed: (error) => {
          errors.value = error;
        },
      }
    );
    toggleModal(false);
  }
};
const getSingleCustomer = async (id) => {
  await api_call(`/api/v1/customers/${id}/`, {
    success: (response) => {
      customers.value = [response.data];
      customer.value = response.data;
    },
    failed: (error) => {
      errors.value = error;
    },
  });
};
</script>

<style lang="scss" scoped>
@import "mybulma/sass/mystyles.scss";

.box.is-clickable:hover {
  background-color: $info-light;
}
</style>
