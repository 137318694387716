<template>
  <section class="section">
    <div class="is-flex block">
      <BackButton />
      <p class="title is-size-4 has-text-info">Add - Merchant</p>
    </div>

    <div class="column">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in responseErrors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>

    <div class="columns is-multiline is-desktop is-tablet is-mobile">
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Company Name: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="text"
          :class="{
            'is-danger': errors.name.length > 0,
          }"
          v-model="merchant.name"
          placeholder="eg. Best Printing Sdn Bhd"
        />
        <p
          v-for="(err, index) in errors.name"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Company Registration No:
          <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="text"
          :class="{
            'is-danger': errors.registrationNo.length > 0,
          }"
          v-model="merchant.registrationNo"
          placeholder="eg. 201901000005 (1312525-A) "
        />
        <p
          v-for="(err, index) in errors.registrationNo"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Contact Number: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="tel"
          :class="{
            'is-danger': errors.contact_num.length > 0,
          }"
          v-model="merchant.contact_num"
          placeholder="eg. 03-3321990"
        />
        <p
          v-for="(err, index) in errors.contact_num"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Email Address: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          class="input"
          type="email"
          :class="{
            'is-danger': errors.email.length > 0,
          }"
          v-model="merchant.email"
        />
        <p
          v-for="(err, index) in errors.email"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Address Line 1:
          <span class="has-text-danger is-size-5">*</span></label
        >
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="address1"
          class="input"
          :class="{
            'is-danger': errors.address1.length > 0,
          }"
          v-model="merchant.address1"
        />
        <p
          v-for="(err, index) in errors.address1"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>Address Line 2: </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="address2"
          class="input"
          v-model="merchant.address2"
        />
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Zipcode: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="zipcode"
          class="input"
          :class="{
            'is-danger': errors.zipcode.length > 0,
          }"
          v-model="merchant.zipcode"
        />
        <p
          v-for="(err, index) in errors.zipcode"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label> City: <span class="has-text-danger is-size-5">*</span> </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="city"
          class="input"
          :class="{
            'is-danger': errors.city.length > 0,
          }"
          v-model="merchant.city"
        />
        <p
          v-for="(err, index) in errors.city"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label> State: <span class="has-text-danger is-size-5">*</span> </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="state"
          class="input"
          :class="{
            'is-danger': errors.state.length > 0,
          }"
          v-model="merchant.state"
        />
        <p
          v-for="(err, index) in errors.state"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>

      <div class="column is-2-desktop is-3-tablet is-3-mobile">
        <label>
          Country: <span class="has-text-danger is-size-5">*</span>
        </label>
      </div>
      <div class="column is-4-desktop is-9-tablet is-9-mobile">
        <input
          type="text"
          name="country"
          class="input"
          :class="{
            'is-danger': errors.country.length > 0,
          }"
          v-model="merchant.country"
        />
        <p
          v-for="(err, index) in errors.country"
          :key="index"
          class="help is-danger"
        >
          {{ err }}
        </p>
      </div>
    </div>

    <div class="buttons is-centered">
      <div class="button is-info has-text-weight-bold" @click="addUser()">
        Register Merchant
      </div>
    </div>

    <div id="successModal" class="modal">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title has-text-success">
              Merchant Created Successfully
            </p>
          </header>

          <div class="card-content">
            <div class="content">
              <p class="has-text-dark">
                Your temporary password is:
                <strong>{{ password }}</strong>
              </p>
              <div class="message is-warning">
                <div class="message-body">
                  Note: Temporary password will only be shown once, please note
                  it down somewhere safe
                </div>
              </div>
            </div>

            <footer class="card-footer">
              <router-link
                :to="{ name: 'ViewMerchant', params: { id: new_id } }"
                class="card-footer-item"
                >Done</router-link
              >
            </footer>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, ref } from "vue";

import {
  make_random_password,
  api_call,
  validate_zipcode,
  validate_email,
  get_role_id,
} from "@/utils";
import BackButton from "@/components/BackButton.vue";

const merchant = reactive({
  user: "",
  name: "",
  registrationNo: "",
  contact_num: "",
  email: "",
  address1: "",
  address2: "",
  zipcode: "",
  city: "",
  state: "",
  country: "",
});
const errors = reactive({
  name: [],
  registrationNo: [],
  contact_num: [],
  email: [],
  address1: [],
  zipcode: [],
  city: [],
  state: [],
  country: [],
});
const responseErrors = ref([]);
const password = ref(make_random_password());
const new_id = ref(0);

const addUser = async () => {
  if (valid()) {
    const formData = {
      username: merchant.email,
      email: merchant.email,
      password: password.value,
      role: get_role_id("merchant"),
    };

    try {
      const response = await api_call("/api/v1/users/", {
        method: "post",
        data: formData,
      });
      // await api_call(`/api/v1/users/set_role/`, {
      //   method: "post",
      //   data: {
      //     id: response.data.id,
      //     role: "merchant",
      //   },
      // });
      merchant.user = parseInt(response.data.id);
      const merchant_response = await api_call("/api/v1/merchants/", {
        method: "post",
        data: merchant,
      });
      new_id.value = parseInt(merchant_response.data.id);
      document.getElementById("successModal").classList.add("is-active");
    } catch (errors) {
      responseErrors.value = errors;
    }
  }
};

function valid() {
  Object.assign(errors, {
    name: [],
    registrationNo: [],
    contact_num: [],
    email: [],
    address1: [],
    zipcode: [],
    city: [],
    state: [],
    country: [],
  });
  let valid = true;
  for (var key in errors) {
    if (!merchant[key]) {
      valid = false;
      errors[key].push(`this field cannot be empty`);
    }
  }
  if (!validate_zipcode(merchant.zipcode)) {
    errors.zipcode.push("zipcode format invalid");
    valid = false;
  }
  if (!validate_email(merchant.email)) {
    errors.email.push("email format invalid");
    valid = false;
  }

  return valid;
}
</script>
