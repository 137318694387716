<template>
  <table class="table is-narrow is-fullwidth is-hoverable is-bordered">
    <thead>
      <tr>
        <th rowspan="2" style="vertical-align: middle">Pricing</th>
        <th colspan="2">Single Sided</th>
        <th colspan="2">Double Sided</th>
      </tr>
      <tr>
        <th>Paper</th>
        <th>Price</th>
        <th>Paper</th>
        <th>Price</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <!-- <th rowspan="2" style="vertical-align: middle">Black &amp; White</th> -->
        <th style="vertical-align: middle">Black &amp; White</th>
        <td>80 gsm</td>
        <td>RM 0.06</td>
        <td>80 gsm</td>
        <td>RM 0.08</td>
      </tr>
      <!-- <tr>
        <td>80 gsm</td>
        <td>Paper Cost RM 0.03 + Print Cost RM 0.03</td>
        <td>80 gsm</td>
        <td>Paper Cost RM 0.03 + Print Cost RM 0.06</td>
      </tr>-->
      <tr>
        <!-- <th rowspan="2" style="vertical-align: middle">Coloured</th> -->
        <th style="vertical-align: middle">Coloured</th>
        <td>80 gsm</td>
        <td>RM 0.50</td>
        <td>80 gsm</td>
        <td>RM 0.80</td>
      </tr>
      <!-- <tr>
        <td>80 gsm</td>
        <td>Paper Cost RM 0.03 + Print Cost RM 0.35</td>
        <td>80 gsm</td>
        <td>Paper Cost RM 0.03 + Print Cost RM 0.70</td>
      </tr>-->
    </tbody>
  </table>
</template>

<script>
export default {};
</script>
