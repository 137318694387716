<template>
  <div class="page-signup">
    <section class="section is-medium">
      <div
        class="columns is-desktop is-mobile is-multiline box"
        v-if="!invalidLink"
      >
        <div class="column is-3-desktop is-1-mobile"></div>
        <div class="column is-6-desktop is-10-mobile">
          <div class="field">
            <label class="label">Password:</label>
            <div class="field">
              <div class="control">
                <password-field
                  :show-password="showPassword"
                  @toggle-show="toggleShow(1)"
                  v-model="new_password"
                />
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Retype Password:</label>
            <div class="field">
              <div class="control">
                <password-field
                  :show-password="showPassword2"
                  @toggle-show="toggleShow(2)"
                  v-model="re_new_password"
                />
              </div>
            </div>
          </div>

          <div class="field mt-5">
            <div class="control has-text-centered">
              <button class="button is-info" @click="submitForm">Submit</button>
            </div>
          </div>
        </div>
      </div>

      <div class="notification is-danger" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">
          {{ error }}
        </p>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import axios from "axios";

import PasswordField from "@/components/PasswordField";
import { validate_password_complexity } from "@/utils";

const errors = ref([]);
const invalidLink = ref(false);
onMounted(() => {
  const prt = localStorage.getItem("prt");
  let timestamp = 0;
  if (prt) {
    timestamp = parseInt(atob(prt));
  }
  if (timestamp + 60000 * 15 < Date.now()) {
    errors.value = ["Password reset link has expired."];
    invalidLink.value = true;
  }
  localStorage.removeItem("prt");
});

const store = useStore();
const router = useRouter();

const route = useRoute();
const { uid, token } = route.params;

const new_password = ref("");
const re_new_password = ref("");

const showPassword = ref(false);
const showPassword2 = ref(false);
const toggleShow = (id) => {
  if (id == 1) {
    showPassword.value = !showPassword.value;
  } else {
    showPassword2.value = !showPassword2.value;
  }
};

const submitForm = async () => {
  if (formValid()) {
    try {
      store.dispatch("addLoading");
      await axios.post("/api/v1/users/reset_password_confirm/", {
        uid,
        token,
        new_password: new_password.value,
      });
      store.dispatch("pushMessage", {
        type: "is-success",
        content: "Your password has been changed. Please proceed to login.",
      });
      router.replace("/");
    } catch (error) {
      errors.value = ["This reset link is invalid or has expired."];
    } finally {
      store.dispatch("decreaseLoading");
    }
  }
};

function formValid() {
  errors.value = [];
  let valid = true;
  if (!new_password.value) {
    errors.value.push("Password must not be empty");
    valid = false;
  } else if (!validate_password_complexity(new_password.value)) {
    errors.value = errors.value.concat([
      "password is too common",
      "Your password should contain:",
      "minimum eight characters",
      "at least one uppercase letter",
      "one lowercase letter",
      "one number",
      "and one special character",
    ]);
    valid = false;
  } else if (new_password.value !== re_new_password.value) {
    errors.value.push("Confirm Password does not match");
    valid = false;
  }
  return valid;
}
</script>
