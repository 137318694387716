<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <BackButton />
        <p class="title is-size-4 has-text-info">Selected Orders</p>
      </div>
    </div>
    <hr class="mb-0" />

    <div
      class="
        column
        is-full is-flex is-justify-content-flex-end is-align-items-center
      "
    >
      <label for="status" class="label mr-3">Status: </label>
      <div class="control">
        <div class="select is-small">
          <select
            name="status"
            v-model="filters.status"
            @change="searchOrders()"
          >
            <option value="ALL">All</option>
            <option value="PR">{{ getStatusText("PR") }}</option>
            <option value="RP">{{ getStatusText("RP") }}</option>
            <option value="DE">{{ getStatusText("DE") }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="column" v-if="errors.length > 0">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in errors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('id')"
                label="Order ID"
                field="id"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Status</th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('merchant__name')"
                label="Merchant"
                field="merchant__name"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('merchant__address1')"
                label="Pickup location"
                field="merchant__address1"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('deliveryTime')"
                label="Delivery TIme"
                field="deliveryTime"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('client__name')"
                label="Client"
                field="client__name"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('client__address1')"
                label="Delivery To"
                field="client__address1"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders" :key="order.id">
            <th># {{ order.id }}</th>
            <td>
              <span class="tag" :class="getStatusColor(order.status)">
                {{ getStatusText(order.status) }}
              </span>
            </td>
            <td>
              <MerchantDetailPopup
                :merchant="order.merchant"
                v-if="order.merchant"
              />
            </td>
            <td v-html="formatAddress(order.merchant)"></td>
            <td>{{ formatDateTime(order.deliveryTime) }}</td>
            <td>
              <ClientDetailPopup :client="order.client" v-if="order.client" />
            </td>
            <td v-html="formatAddress(order.client)"></td>
            <td>
              <div class="buttons">
                <router-link
                  class="button is-small has-text-link"
                  :to="{
                    name: 'DeliveryOrderDetails',
                    params: { id: order.id },
                  }"
                >
                  <span class="icon is-small">
                    <i class="far fa-folder-open"></i>
                  </span>
                  <span>View Details</span>
                </router-link>
                <button
                  class="button is-small is-link"
                  @click="startDeliver(order.id)"
                  v-if="order.status === 'RP'"
                >
                  <span class="icon is-small">
                    <i class="fa-solid fa-motorcycle"></i>
                  </span>
                  <span>Start Deliver</span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <load-more-button
      :fetched-count="orders.length"
      :total-count="count"
      :next="next"
      @load-more="loadMore"
    />
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";

import TableHeaderOrder from "@/components/TableHeaderOrder";
import BackButton from "@/components/BackButton";
import MerchantDetailPopup from "@/components/MerchantDetailPopup";
import ClientDetailPopup from "@/components/ClientDetailPopup";
import LoadMoreButton from "@/components/LoadMoreButton";
import {
  getStatusText,
  getStatusColor,
  formatAddress,
  formatDateTime,
  api_call,
} from "@/utils";

const router = useRouter();

const orders = ref([]);
const count = ref(0);
const next = ref(null);
const previous = ref(null);
const filters = reactive({
  status: "ALL",
  delivery: "",
  ordering: "-createdAt",
});
const errors = ref([]);
const ascending = ref(false);

const searchOrders = (url = undefined, clean = true) => {
  errors.value = [];
  if (clean) {
    orders.value = [];
  }
  var query = "";
  var filter_list = [];
  for (var key in filters) {
    var value = filters[key];
    if (value) {
      if (key == "status") {
        if (value == "ALL") {
          value = "PR,RP,DE";
        }
        filter_list.push(`${key}=${value}`);
      } else {
        filter_list.push(`${key}=${value}`);
      }
    }
  }

  if (filter_list.length > 0) {
    query = "?" + filter_list.join("&");
  }

  url = url && url.substr(url.indexOf("/api"));

  api_call(url || `/api/v1/carts/${query}`, {
    success(res) {
      orders.value = orders.value.concat(res.data.results);
      count.value = res.data.count;
      next.value = res.data.next;
      previous.value = res.data.previous;
    },
    failed(err) {
      errors.value = err;
    },
  });
};
const loadMore = () => {
  searchOrders(next.value, false);
};
const orderBy = (field) => {
  ascending.value = !ascending.value;
  filters.ordering = ascending.value ? field : "-" + field;
  searchOrders();
};

onMounted(() => {
  api_call("/api/v1/delivery/me", {
    success(res) {
      filters.delivery = res.data.id;
      searchOrders();
    },
    failed(err) {
      errors.value = err;
    },
  });
});

const startDeliver = (id) => {
  api_call(`/api/v1/carts/${id}/start_deliver/`, {
    method: "post",
    success: () => {
      router.push({ name: "DeliveryOrderDetails", params: { id: id } });
    },
  });
};
</script>
