<template>
  <div class="page-client">
    <div class="section">
      <div class="columns is-multiline">
        <div class="column is-12">
          <BackButton />
        </div>
        <div class="column is-half is-offset-one-quarter">
          <div class="box">
            <h1 class="title has-text-centered has-text-info">
              Contact Details
            </h1>
            <p><strong>Name: </strong> {{ client.name }}</p>
            <p v-if="client.contact_num">
              <strong>Contact Number:</strong> {{ client.country_code
              }}{{ client.contact_num }}
            </p>
            <p
              v-if="
                client.address1 ||
                client.address2 ||
                client.zipcode ||
                client.city ||
                client.state ||
                client.country
              "
            >
              <strong>Address: </strong>
              {{ addTrailingComma(client.address1) }}
              {{ addTrailingComma(client.address2) }}
              {{ client.zipcode }}
              {{ addTrailingComma(client.city) }}
              {{ addTrailingComma(client.state) }}
              {{ client.country }}
            </p>

            <div class="columns is-multiline">
              <div class="column is-3">
                <router-link
                  :to="{ name: 'MyAccount' }"
                  class="button is-info mt-4 is-fullwidth"
                  >Back</router-link
                >
              </div>
              <div class="column is-3">
                <router-link
                  :to="{ name: 'EditClient', params: { Id: client.id } }"
                  class="button is-info is-offset-4 mt-4 is-fullwidth"
                >
                  Edit Client
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

import BackButton from "@/components/BackButton";
import { addTrailingComma, api_call } from "@/utils";

const route = useRoute();

const client = ref({});

onMounted(async () => {
  const res = await api_call(`/api/v1/clients/${route.params.id}`);
  client.value = res.data;
});
</script>
