<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <BackButton />
        <p class="title is-size-4 has-text-info">Available Orders</p>
      </div>
    </div>
    <hr />

    <div class="column" v-if="errors.length > 0">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in errors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('id')"
                label="Order ID"
                field="id"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Status</th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('createdAt')"
                label="Order Time"
                field="createdAt"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('deliveryTime')"
                label="Delivery TIme"
                field="deliveryTime"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>No. of Files</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders" :key="order.id">
            <th># {{ order.id }}</th>
            <td>
              <span class="tag" :class="getStatusColor(order.status)">
                {{ getStatusText(order.status) }}
              </span>
            </td>
            <td>{{ formatDateTime(order.createdAt) }}</td>
            <td>{{ formatDateTime(order.deliveryTime) }}</td>
            <td>
              {{ order.files.length }}
            </td>
            <td>
              <div class="buttons">
                <router-link
                  class="button is-small has-text-link"
                  :to="{
                    name: 'MerchantOrderDetails',
                    params: { id: order.id },
                  }"
                >
                  <span class="icon is-small">
                    <i class="far fa-folder-open"></i>
                  </span>
                  <span>View Details</span>
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <load-more-button
      :fetched-count="orders.length"
      :total-count="count"
      :next="next"
      @load-more="loadMore"
    />
  </section>
</template>

<script setup>
import TableHeaderOrder from "@/components/TableHeaderOrder";
import BackButton from "@/components/BackButton";
import LoadMoreButton from "@/components/LoadMoreButton";
import {
  getStatusText,
  getStatusColor,
  api_call,
  formatDateTime,
} from "@/utils";

import { ref, reactive, onMounted } from "vue";

const orders = ref([]);
const count = ref(0);
const next = ref(null);
const previous = ref(null);
const filters = reactive({
  id: "",
  order_start: "",
  order_end: "",
  deliveryTime: "",
  status: "PE",
  merchant: "",
  remark: "",
  ordering: "-createdAt",
});
const errors = ref([]);
const ascending = ref(false);

const searchOrders = async (url = undefined, clean = true) => {
  errors.value = [];
  if (clean) {
    orders.value = [];
  }
  let query = "";
  let filter_list = [];
  for (let key in filters) {
    let value = filters[key];
    if (value) {
      if (key == "id") {
        let temp = parseInt(value.slice(value.indexOf("#") + 1));
        filter_list.push(`${key}=${temp}`);
      } else {
        filter_list.push(`${key}=${value}`);
      }
    }
  }

  if (filter_list.length > 0) {
    query = "?" + filter_list.join("&");
  }

  url = url && url.substr(url.indexOf("/api"));
  const response = await api_call(url || `/api/v1/carts/${query}`, {
    failed: (error) => {
      errors.value = error;
    },
  });
  orders.value = orders.value.concat(response.data.results);
  count.value = response.data.count;
  next.value = response.data.next;
  previous.value = response.data.previous;
};

const loadMore = () => {
  searchOrders(next.value, false);
};

const orderBy = (field) => {
  ascending.value = !ascending.value;
  filters.ordering = ascending.value ? field : "-" + field;
  searchOrders();
};

onMounted(() => {
  searchOrders();
});
</script>
