<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <BackButton />
        <p class="title is-size-4 has-text-info">Order History</p>
      </div>
      <!-- <div class="level-right">
        <button
          class="button has-text-info level-right"
          @click="downloadInvoice"
        >
          Download Monthly Invoice
        </button>
      </div> -->
    </div>
    <hr class="mb-0" />

    <div
      class="
        column
        is-full is-flex is-justify-content-flex-end is-align-items-center
      "
    >
      <label for="status" class="label mr-3">Status: </label>
      <div class="control">
        <div class="select is-small">
          <select
            name="status"
            v-model="filters.status"
            @change="searchOrders()"
          >
            <option value="ALL">All</option>
            <option value="PR">{{ getStatusText("PR") }}</option>
            <option value="RP">{{ getStatusText("RP") }}</option>
            <option value="DE">{{ getStatusText("DE") }}</option>
            <option value="CO">{{ getStatusText("CO") }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="column" v-if="errors.length > 0">
      <div
        class="message mb-1 is-danger"
        v-for="(error, index) in errors"
        :key="index"
      >
        <div class="message-body">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="table-container">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('id')"
                label="Order ID"
                field="id"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Status</th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('createdAt')"
                label="Order Time"
                field="createdAt"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('deliveryTime')"
                label="Delivery TIme"
                field="deliveryTime"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>
              <TableHeaderOrder
                @onClick="orderBy('totalPrice')"
                label="Price"
                field="totalPrice"
                :currOrdering="filters.ordering"
                :ascending="ascending"
              />
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders" :key="order.id">
            <th># {{ order.id }}</th>
            <td>
              <span class="tag" :class="getStatusColor(order.status)">
                {{ getStatusText(order.status) }}
              </span>
            </td>
            <td>{{ formatDateTime(order.createdAt) }}</td>
            <td>{{ formatDateTime(order.deliveryTime) }}</td>
            <td style="white-space: nowrap">
              <span v-if="order.totalPrice">RM {{ order.totalPrice }}</span>
              <span v-else>TBD</span>
            </td>
            <td>
              <div class="buttons">
                <router-link
                  class="button is-small has-text-link"
                  :to="{
                    name: 'MerchantOrderDetails',
                    params: { id: order.id },
                  }"
                >
                  <span class="icon is-small">
                    <i class="far fa-folder-open"></i>
                  </span>
                  <span>View Details</span>
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <load-more-button
      :fetched-count="orders.length"
      :total-count="count"
      :next="next"
      @load-more="loadMore"
    />
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";

import TableHeaderOrder from "@/components/TableHeaderOrder";
import BackButton from "@/components/BackButton";
import LoadMoreButton from "@/components/LoadMoreButton";
import {
  getStatusText,
  getStatusColor,
  api_call,
  formatDateTime,
} from "@/utils";
// const fileDownload = require("js-file-download");

const orders = ref([]);
const count = ref(0);
const next = ref(null);
const previous = ref(null);
const filters = reactive({
  id: "",
  order_start: "",
  order_end: "",
  deliveryTime: "",
  status: "ALL",
  merchant: "",
  remark: "",
  ordering: "-createdAt",
});
const errors = ref([]);
const ascending = ref(false);
const merchantID = ref(0);

onMounted(() => {
  api_call("/api/v1/merchants/me", {
    success(res) {
      merchantID.value = res.data.id;
      filters.merchant = res.data.id;
      searchOrders();
    },
    failed(err) {
      errors.value = err;
    },
  });
});

const searchOrders = (url = undefined, clean = true) => {
  errors.value = [];
  if (clean) {
    orders.value = [];
  }
  var query = "";
  var filter_list = [];
  for (var key in filters) {
    var value = filters[key];
    if (value) {
      if (key == "id") {
        let temp = parseInt(value.slice(value.indexOf("#") + 1));
        filter_list.push(`${key}=${temp}`);
      } else if (key == "status") {
        if (value == "ALL") {
          value = "PR,RP,DE,CO,CA";
        }
        filter_list.push(`${key}=${value}`);
      } else {
        filter_list.push(`${key}=${value}`);
      }
    }
  }

  if (filter_list.length > 0) {
    query = "?" + filter_list.join("&");
  }

  url = url && url.substr(url.indexOf("/api"));

  api_call(url || `/api/v1/carts/${query}`, {
    success(res) {
      orders.value = orders.value.concat(res.data.results);
      count.value = res.data.count;
      next.value = res.data.next;
      previous.value = res.data.previous;
    },
    failed(err) {
      errors.value = err;
    },
  });
};
const loadMore = () => {
  searchOrders(next.value, false);
};
const orderBy = (field) => {
  ascending.value = !ascending.value;
  filters.ordering = ascending.value ? field : "-" + field;
  searchOrders();
};

// const downloadInvoice = () => {
//   api_call(`/api/v1/merchant/${merchantID.value}/monthly/commission/`, {
//     headers: {
//       responseType: "blob",
//     },
//     success(res) {
//       fileDownload(res.data, `monthlyCommission_${merchantID.value}.pdf`);
//     },
//     failed(err) {
//       errors.value = err;
//     },
//   });
// };
</script>
