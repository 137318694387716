<template>
  <div class="dropdown" :class="{ 'is-active': show }">
    <div class="dropdown-trigger">
      <p
        class="has-text-link is-clickable"
        @click="trigger"
        aria-haspopup="true"
        aria-controls="dropdown-menu2"
        style="white-space: nowrap"
      >
        <span>{{ client.name }}</span>
        <span class="icon is-small ml-2">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="dropdown-menu" id="dropdown-menu2" role="menu">
      <div class="dropdown-content" style="position: fixed">
        <div class="dropdown-item">
          <p class="is-size-6">
            <strong>{{ client.name }}</strong>
          </p>
        </div>
        <div class="dropdown-item">
          <p>
            <strong>Contact Number: </strong>
            {{ client.country_code }}{{ client.contact_num }}
          </p>
          <p><strong>Email: </strong>{{ client.email }}</p>
        </div>
        <hr class="dropdown-divider" />
        <div class="dropdown-item">
          <p>
            <mark :style="highlightBg">
              <strong>Address:</strong><br />
              {{ addTrailingComma(client.address1) }} <br />
              {{ addTrailingComma(client.address2) }}
              <br v-if="client.address2" />
              {{ client.zipcode }}
              {{ addTrailingComma(client.city) }} <br />
              {{ addTrailingComma(client.state) }}
              {{ client.country }}
            </mark>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addTrailingComma } from "@/utils";

export default {
  name: "ClientDetailPopup",
  props: ["client"],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    highlightBg() {
      const address =
        this.client.address1 +
        this.client.address2 +
        this.client.zipcode +
        this.client.city +
        this.client.state +
        this.client.country;

      // console.log(address.toLowerCase().indexOf("kampar"));
      if (address.toLowerCase().indexOf("kampar") === -1) {
        return {
          backgroundColor: "rgba(247, 104, 166, 0.3)",
        };
      }

      return {
        backgroundColor: "transparent",
      };
    },
  },
  methods: {
    trigger() {
      this.show = !this.show;
    },
    addTrailingComma(str) {
      return addTrailingComma(str);
    },
  },
};
</script>
