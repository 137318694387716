<template>
  <div class="page-signup">
    <section class="section is-medium">
      <div class="columns is-desktop is-mobile is-multiline">
        <div class="column is-3-desktop is-1-mobile"></div>
        <div class="column is-6-desktop is-10-mobile">
          <div class="has-text-centered">
            <h1 class="title is-size-2 cursive has-text-info"></h1>
            <h3 class="subtitle is-size-6 my-5">
              Forgot Password?
              <router-link to="/">Sign In </router-link>now
            </h3>
          </div>

          <div class="box">
            <div class="field">
              <label>Email Address </label>
              <div class="control">
                <input
                  type="email"
                  name="email"
                  class="input"
                  placeholder="Email"
                  v-model="client.email"
                />
              </div>
            </div>

            <div class="notification is-danger" v-if="errors.length">
              <p v-for="error in errors" v-bind:key="error">
                {{ error }}
              </p>
            </div>

            <div class="field">
              <div class="control has-text-centered">
                <button class="button is-info" @click="submitForm">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";

import { handle_response_error } from "@/utils";

const store = useStore();
const router = useRouter();

store.dispatch("invalidateToken");

const errors = ref([]);
const client = ref({
  email: "",
});

const submitForm = async () => {
  try {
    store.dispatch("addLoading");
    await axios.post("/api/v1/users/reset_password/", client.value);

    // encode current timestamp in base64 format and store in localStorage
    const timestamp = new Date().getTime().toString();
    const password_reset_timestamp = btoa(timestamp);
    localStorage.setItem("prt", password_reset_timestamp);

    store.dispatch("pushMessage", {
      type: "is-success",
      content: "Check your email for a link to reset your password.",
    });
    router.replace("/");
  } catch (error) {
    errors.value = handle_response_error(error);
  } finally {
    store.dispatch("decreaseLoading");
  }
};
</script>
