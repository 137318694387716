<template>
  <div class="box" :key="reward.referral_code">
    <div class="level">
      <div class="level-left">
        <div class="level-item">{{ reward.referral_code }}</div>
        <div class="level-item">
          <div class="tag is-primary">
            {{ parseFloat(reward.amount).toFixed(0) }} papers
          </div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button class="button is-small is-success" disabled v-if="selected">
            Applied
          </button>
          <button
            class="button is-info is-small"
            @click="$emit('onApply')"
            v-else
          >
            Apply
          </button>
        </div>
        <div class="level-item" v-if="selected">
          <span class="delete" @click="$emit('onCancel')"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["reward", "selected"],
  emits: ["onApply", "onCancel"],
};
</script>
