<template>
  <div class="modal" :class="{ 'is-active': show }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Admin</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>

      <div class="modal-card-body">
        <div class="column" v-if="responseErrors.length > 0">
          <div
            class="message mb-1 is-danger"
            v-for="(error, index) in responseErrors"
            :key="index"
          >
            <div class="message-body">
              {{ error }}
            </div>
          </div>
        </div>

        <div class="columns is-multiline is-desktop is-tablet is-mobile">
          <div class="column is-3-desktop is-3-tablet is-3-mobile">
            <label>
              Username:
              <span class="has-text-danger is-size-5">*</span></label
            >
          </div>
          <div class="column is-9-desktop is-9-tablet is-9-mobile">
            <input
              class="input"
              type="text"
              name="username"
              :class="{
                'is-danger': errors.username.length > 0,
              }"
              v-model="admin.username"
            />
            <p
              v-for="(err, index) in errors.username"
              :key="index"
              class="help is-danger"
            >
              {{ err }}
            </p>
          </div>

          <div class="column is-3-desktop is-3-tablet is-3-mobile">
            <label>
              Email Address:
              <span class="has-text-danger is-size-5">*</span>
            </label>
          </div>
          <div class="column is-9-desktop is-9-tablet is-9-mobile">
            <input
              class="input"
              :class="{
                'is-danger': errors.email.length > 0,
              }"
              type="email"
              name="email"
              v-model="admin.email"
            />
            <p
              v-for="(err, index) in errors.email"
              :key="index"
              class="help is-danger"
            >
              {{ err }}
            </p>
          </div>
        </div>
      </div>

      <footer class="modal-card-foot is-justify-content-flex-end">
        <button
          class="button is-info has-text-weight-bold"
          :class="{ 'is-loading': loading > 0 }"
          @click="addUser()"
        >
          Register Admin
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddAdmin",
  data() {
    return {
      admin: {
        username: "",
        email: "",
      },
    };
  },
  props: [
    "errors",
    "responseErrors",
    "show",
    "registerAdmin",
    "loading",
    "close",
  ],
  methods: {
    addUser() {
      if (this.registerAdmin(this.admin)) {
        this.admin = {
          username: "",
          email: "",
        };
      }
    },
  },
};
</script>
