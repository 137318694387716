<template>
  <section class="section is-small">
    <div class="columns is-mobile is-desktop is-tablet">
      <div class="column is-full">
        <div class="mb-5">
          <p class="is-size-3 has-text-info mx-5">File Name</p>
          <p>{{ file.filename }}</p>
        </div>

        <div class="box">
          <edit-file-form @change="onHandleFormChange" :file="file" />
        </div>

        <div class="columns is-centered is-mobile">
          <button class="button is-info" @click="editItem">
            <strong> Add to Print List</strong>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import EditFileForm from "@/components/client/EditFileForm";

const store = useStore();
const router = useRouter();
const route = useRoute();

const file = computed(() => {
  return store.getters["cart/fileByIndex"](route.params.id) || {};
});

const formData = ref({});
function onHandleFormChange(data) {
  formData.value = data;
}
function editItem() {
  const selectedIndex = route.params.id;

  var editFile = {
    file: file.value.file,
    filename: file.value.filename,
    ...formData.value,
    index: selectedIndex,
  };

  store.dispatch("cart/editFile", {
    index: selectedIndex,
    file: editFile,
  });
  router.push("/dashboard/carts");
}
</script>
