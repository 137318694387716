<template>
  <button @click="this.$router.go(-1)" class="button mr-3 is-small">
    <span class="icon">
      <i class="fa-solid fa-caret-left fa-2xl"></i>
    </span>
  </button>
</template>

<script>
export default {
  name: "BackButton",
};
</script>
