<template>
  <div class="container">
    <section class="section">
      <BackButton />
      <p class="title has-text-info has-text-centered is-size-3">
        Order Details
      </p>
      <order-status-summary :status="cart.status" class="mx-auto" />
    </section>

    <section class="section">
      <order-summary :cart="cart" />
    </section>

    <section class="section">
      <div class="level">
        <p class="title has-text-link is-size-4">File:</p>
        <span class="level-right">
          <download-all-button :items="items"></download-all-button>
        </span>
      </div>
      <div class="columns">
        <div class="column is-half">
          <p class="has-text-weight-bold">File Name</p>
        </div>
        <div class="column is-half">
          <p class="has-text-centered has-text-weight-bold">Copies</p>
        </div>
      </div>
      <div v-for="file in items" :key="file.id">
        <div class="printList-box">
          <div class="columns is-multiline is-dekstop is-tablet is-mobile">
            <div class="column is-half">
              <a :href="file.file" :download="file.filename" target="_blank">
                {{ file.filename }}
              </a>
            </div>
            <div class="column is-half">
              <p class="has-text-centered">{{ file.copies }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="section">
      <p class="has-text-link is-size-4">Customer Remark:</p>
      <div class="control">
        <textarea class="textarea" readonly :value="cart.remark"></textarea>
      </div>
    </section> -->

    <section class="section has-text-centered">
      <div class="buttons is-justify-content-center mb-0">
        <button class="button is-danger" @click="cancelOrder" v-if="canCancel">
          <strong>Cancel</strong>
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";

import OrderStatusSummary from "@/components/OrderStatusSummary.vue";
import BackButton from "@/components/BackButton.vue";
import OrderSummary from "@/components/OrderSummary";
import DownloadAllButton from "@/components/DownloadAllButton";
import { api_call, getStatusOrder } from "@/utils";

const route = useRoute();

const cart = ref({});
const items = ref([]);
const getCartDetail = (id) => {
  api_call(`/api/v1/carts/${id}/`, {
    success: (res) => {
      cart.value = res.data;
      items.value = res.data.files;
    },
  });
};
onMounted(() => {
  getCartDetail(route.params.id);
});
watch(
  () => route.params.id,
  (newId) => {
    newId && getCartDetail(newId);
  }
);

const canCancel = computed(() => {
  const status = cart.value.status;
  return status !== "CA" && getStatusOrder(status) < getStatusOrder("CO");
});
const cancelOrder = () => {
  api_call(`/api/v1/carts/${cart.value.id}/cancel_order/`, {
    method: "post",
    success: (res) => {
      cart.value = res.data;
    },
  });
};
</script>
