<template>
  <div class="dropdown" :class="{ 'is-active': show }">
    <div class="dropdown-trigger">
      <p
        class="has-text-link is-clickable"
        @click="trigger"
        aria-haspopup="true"
        aria-controls="dropdown-menu2"
        style="white-space: nowrap"
      >
        <span>{{ merchant.name }}</span>
        <span class="icon is-small ml-2">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="dropdown-menu" id="dropdown-menu2" role="menu">
      <div class="dropdown-content" :class="{ 'position-fixed': !relative }">
        <div class="dropdown-item">
          <p class="is-size-6">
            <strong>{{ merchant.name }}</strong>
          </p>
        </div>
        <div class="dropdown-item">
          <p><strong>Contact Number: </strong>{{ merchant.contact_num }}</p>
          <p><strong>Email: </strong>{{ merchant.email }}</p>
        </div>
        <hr class="dropdown-divider" />
        <div class="dropdown-item">
          <p>
            <strong>Address:</strong><br />
            {{ addTrailingComma(merchant.address1) }} <br />
            {{ addTrailingComma(merchant.address2) }}
            <br v-if="merchant.address2" />
            {{ merchant.zipcode }}
            {{ addTrailingComma(merchant.city) }} <br />
            {{ addTrailingComma(merchant.state) }}
            {{ merchant.country }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addTrailingComma } from "@/utils";

export default {
  name: "MerchantDetailPopup",
  props: ["merchant", "relative"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    trigger() {
      this.show = !this.show;
    },
    addTrailingComma(str) {
      return addTrailingComma(str);
    },
  },
};
</script>
