<template>
  <section class="section is-small">
    <div class="columns is-mobile is-desktop is-tablet">
      <div class="column is-full">
        <div class="mb-5">
          <div class="is-flex is-align-items-baseline">
            <BackButton />
            <p class="is-size-3 has-text-info mx-5">File Name</p>
          </div>
          <span
            class="tag"
            v-for="(file, index) in files"
            :value="file"
            :key="file.filename"
          >
            {{ file.filename }}
            <button class="delete is-small" @click="removeFile(index)"></button>
            <!-- remove file -->
          </span>
        </div>

        <div class="mb-5">
          <errors-list :errors="errors" />
        </div>
        <div class="box">
          <div class="columns is-mobile is-desktop is-multiline">
            <!-- upload file or folder -->
            <div class="column is-full-mobile is-2-desktop is-3-tablet">
              <p class="is-size-4">Upload File</p>
            </div>
            <div class="column is-full-mobile is-4-desktop is-4-tablet">
              <div class="file">
                <label class="file-label">
                  <input
                    class="file-input"
                    type="file"
                    multiple
                    @change="fileChange"
                  />
                  <span class="file-cta">
                    <span class="file-icon">
                      <i class="fa fa-folder"></i>
                    </span>
                    <span class="file-label"> Choose the file(s) </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <edit-file-form @change="onHandleFormChange" />
        </div>

        <div class="columns is-centered">
          <div class="column is-3">
            <button class="button is-info is-fullwidth" @click="addItem">
              <strong> Add to Print List</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import BackButton from "@/components/BackButton";
import ErrorsList from "@/components/ErrorsList";
import EditFileForm from "@/components/client/EditFileForm";

const store = useStore();
const router = useRouter();

const formData = ref({});

const files = ref([]);
const errors = ref([]);

function onHandleFormChange(data) {
  formData.value = data;
}

function fileChange(event) {
  errors.value = [];
  if (files.value.length + event.target.files.length > 10) {
    errors.value.push("You can only upload 10 files at once.");
    return;
  }
  for (var i = 0; i < event.target.files.length; i++) {
    const item = event.target.files[i];
    // get file extension
    const ext = item.name.split(".").pop();
    const invalid = [
      "exe",
      "bat",
      "cmd",
      "com",
      "js",
      "php",
      "py",
      "sh",
      "reg",
      "msi",
    ];
    if (invalid.includes(ext)) {
      errors.value.push(`${item.name} is not allowed.`);
      continue;
    }
    if (item.size === 0) {
      errors.value.push(`${item.name} is empty.`);
      continue;
    }
    if (item.size > 100 * 1024 * 1024) {
      errors.value.push(
        `${item.name} is too big. Please select a file under 100MB.`
      );
      continue;
    }
    const file = {
      file: item,
      filename: item.name,
    };

    files.value.push(file);
  }
}
function removeFile(index) {
  files.value.splice(index, 1);
}
function addItem() {
  errors.value = [];
  if (files.value.length != 0) {
    files.value.forEach((file_element, index) => {
      var file = {
        file: file_element.file,
        filename: file_element.filename,
        ...formData.value,
        index,
      };

      store.dispatch("cart/setFiles", file);
      router.push("/dashboard/carts");
    });
  } else {
    errors.value.push("Please upload at least one file.");
  }
}
</script>
