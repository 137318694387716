<template>
  <div class="dropdown" :class="{ 'is-active': show }">
    <div class="dropdown-trigger">
      <p
        class="has-text-link is-clickable"
        @click="trigger"
        aria-haspopup="true"
        aria-controls="dropdown-menu2"
        style="white-space: nowrap"
      >
        <span>{{ rider.name }}</span>
        <span class="icon is-small ml-2">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </p>
    </div>
    <div class="dropdown-menu" id="dropdown-menu2" role="menu">
      <div class="dropdown-content" :class="{ 'position-fixed': !relative }">
        <div class="dropdown-item">
          <p class="is-size-6">
            <strong>{{ rider.name }}</strong>
          </p>
        </div>
        <hr class="dropdown-divider" />
        <div class="dropdown-item">
          <p><strong>Contact Number: </strong>{{ rider.contact_num }}</p>
          <p><strong>Email: </strong>{{ rider.email }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RiderDetailPopup",
  props: ["rider", "relative"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    trigger() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped>
.position-fixed {
  position: fixed;
}
</style>
