/* eslint-disable */
<template>
  <section class="section">
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="is-flex is-justify-content-space-between">
          <div class="is-flex is-align-items-baseline">
            <back-button />
            <p class="title">Monthly Sales ({{ monthlySales.year }})</p>
          </div>
          <!-- <div class="field is-horizontal">
            <div class="field-label">
              <label for="year" class="label">Year: </label>
            </div>
            <div class="field is-narrow">
              <div class="control">
                <div class="select">
                  <select name="year" v-model="monthlySales.year">
                    <option v-for="(item, index) in years" :key="index">
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <apexchart
          height="500"
          type="line"
          :options="monthlySales.options"
          :series="monthlySales.series"
        ></apexchart>
      </div>
      <div class="column is-half-tablet">
        <p class="title">Top Merchants of the month</p>
        <apexchart
          height="500"
          type="bar"
          :options="topMerchants.options"
          :series="topMerchants.series"
        ></apexchart>
      </div>
      <div class="column is-half-tablet">
        <p class="title">Top Riders of the month</p>
        <apexchart
          height="500"
          type="bar"
          :options="topRiders.options"
          :series="topRiders.series"
        ></apexchart>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";

import BackButton from "@/components/BackButton";
import { api_call, formatMoney } from "@/utils";

const years = ref([]);

const monthlySales = reactive({
  year: 0,
  series: [],
  options: {
    chart: {
      id: "monthly-sales",
    },
    xaxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
    yaxis: {
      labels: {
        formatter: (val) => formatMoney(val),
      },
    },
    noData: {
      text: "No Any Completed Orders",
    },
  },
});
const topMerchants = reactive({
  series: [],
  options: {
    dataLabels: {
      enabled: true,
      formatter: (val) => formatMoney(val),
    },
    yaxis: {
      seriesName: "Total Sales",
      labels: {
        formatter: (val) => formatMoney(val),
      },
    },
    noData: {
      text: "No Merchants",
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
  },
});
const topRiders = reactive({
  series: [],
  options: {
    noData: {
      text: "No Riders",
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
  },
});

onMounted(() => {
  var this_year = new Date().getFullYear();
  monthlySales.year = this_year;
  years.value = [0, 1, 2, 3, 4].map((i) => this_year - i);
  get_monthly_sales(this_year);
  get_top_merchants();
  get_top_riders();
});

const get_monthly_sales = (year) => {
  api_call(`/api/v1/carts/monthly_sales?year=${year}`, {
    success(res) {
      monthlySales.series = [
        {
          name: "Total Sales",
          data: res.data,
        },
      ];
    },
  });
};

const get_top_merchants = () => {
  api_call(`/api/v1/carts/top_merchants`, {
    success(res) {
      topMerchants.series = [
        {
          name: "Total Sales",
          data: res.data,
        },
      ];
    },
  });
};

const get_top_riders = () => {
  api_call(`/api/v1/carts/top_riders`, {
    success(res) {
      topRiders.series = [
        {
          name: "Total Order Delivered",
          data: res.data,
        },
      ];
    },
  });
};
</script>
