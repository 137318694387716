<script setup>
import { defineProps } from "vue";

const props = defineProps(["items"]);

function onHandleDownloadAllFiles() {
  const pendingFiles = props.items.slice();

  const downloadJob = setInterval(
    (pendingFiles) => {
      const f = pendingFiles.pop();

      fetch(f.file)
        .then((res) => res.blob())
        .then((file) => {
          let tempUrl = URL.createObjectURL(file);
          const aTag = document.createElement("a");
          aTag.style.display = "none";
          aTag.href = tempUrl;
          aTag.download = f.filename;

          aTag.click();
          URL.revokeObjectURL(tempUrl);
          aTag.remove();
          if (pendingFiles.length == 0) {
            clearInterval(downloadJob);
          }
        })
        .catch(() => {
          alert("Failed to download " + f.filename);
        });
    },
    300,
    pendingFiles
  );
}
</script>
<template>
  <button class="button mx-4 is-link" @click.prevent="onHandleDownloadAllFiles">
    <strong>Download All Files</strong>
  </button>
</template>
