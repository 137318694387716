<template>
  <div class="page-signup">
    <section class="section is-medium">
      <div class="columns is-desktop is-mobile is-multiline">
        <div class="column is-3-desktop is-1-mobile"></div>
        <div class="column is-6-desktop is-10-mobile">
          <div class="has-text-centered">
            <h1 class="title is-size-2 cursive has-text-info">
              Ready to Sign Up
            </h1>
            <h3 class="subtitle is-size-6 my-5">
              Already got an account?
              <router-link to="/">Sign In </router-link>now
            </h3>
          </div>

          <div class="box">
            <div class="field">
              <label>Username </label>
              <div class="control">
                <input
                  type="text"
                  name="username"
                  class="input"
                  placeholder="Username"
                  v-model="client.username"
                />
              </div>
            </div>

            <div class="field">
              <label>Email Address </label>
              <div class="control">
                <input
                  type="email"
                  name="email"
                  class="input"
                  placeholder="Email"
                  v-model="client.email"
                />
              </div>
            </div>

            <div class="field">
              <label>Password </label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input
                    :type="passwordFieldType"
                    class="input"
                    placeholder="Password"
                    v-model="password"
                  />
                </div>
                <div class="controls">
                  <a class="icon is-large is-right" @click="toggleShow">
                    <i
                      class="fa"
                      :class="{
                        'fa-eye-slash': !showPassword,
                        'fa-eye': showPassword,
                      }"
                    ></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="field">
              <label>Confirm Password </label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input
                    :type="rePasswordFieldType"
                    class="input"
                    placeholder="Password"
                    v-model="rePassword"
                  />
                </div>
                <div class="controls">
                  <a class="icon is-large is-right" @click="toggleShow2">
                    <i
                      class="fa"
                      :class="{
                        'fa-eye-slash': !showRePassword,
                        'fa-eye': showRePassword,
                      }"
                    ></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="notification is-danger" v-if="errors.length">
              <p v-for="error in errors" v-bind:key="error">
                {{ error }}
              </p>
            </div>

            <div class="field">
              <div class="control has-text-centered">
                <button class="button is-info" @click="submitForm">
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import {
  handle_response_error,
  validate_password_complexity,
  validate_email,
} from "@/utils.js";

export default {
  name: "SignUp",
  data() {
    return {
      password: "",
      showPassword: false,
      rePassword: "",
      showRePassword: false,
      errors: [],
      client: {
        username: "",
        email: "",
      },
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    passwordFieldType() {
      return this.showPassword ? "text" : "password";
    },
    rePasswordFieldType() {
      return this.showRePassword ? "text" : "password";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow2() {
      this.showRePassword = !this.showRePassword;
    },
    async submitForm() {
      if (!this.formValid()) {
        return;
      }
      try {
        const formData = {
          username: this.client.username,
          password: this.password,
          email: this.client.email,
          role: 4,
        };
        this.$store.dispatch("addLoading");
        await axios.post("/api/v1/users/", formData);
        // await this.$store.dispatch("login", {
        //   username: this.client.username,
        //   password: this.password,
        // });
        // await axios.post(`/api/v1/users/initiate_role/`, { role: "client" });
        this.$store.dispatch("flashMessage", {
          type: "is-success",
          content: "Signup <b>successfully</b>. Please proceed to signin.",
        });
        this.$store.dispatch("invalidateToken");
        this.$router.push("/");
      } catch (error) {
        this.errors = handle_response_error(error);
      } finally {
        this.$store.dispatch("decreaseLoading");
      }
    },
    formValid() {
      this.errors = [];
      let valid = true;
      if (!this.client.username) {
        this.errors.push("Username must not be empty");
        valid = false;
      }
      if (!validate_email(this.client.email)) {
        this.errors.push("Email format invalid");
        valid = false;
      }
      if (!this.password) {
        this.errors.push("Password must not be empty");
        valid = false;
      } else if (!validate_password_complexity(this.password)) {
        this.errors = this.errors.concat([
          "This password is too common",
          "Your password should contain:",
          "minimum eight characters",
          "at least one uppercase letter",
          "one lowercase letter",
          "one number",
          "and one special character",
        ]);
        valid = false;
      } else if (this.password !== this.rePassword) {
        this.errors.push("Confirm Password does not match");
        valid = false;
      }
      return valid;
    },
  },
};
</script>
